import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../firebase';

/**
 * Fetches detailed user information for a list of project users.
 * @param {Array} projectUsers - Array of user objects from the project (each having uid and permission).
 * @returns {Promise<Array>} - Resolves to an array of user details.
 */
export const fetchProjectUserDetails = async (projectUsers) => {
    try {
      const userDetailsPromises = projectUsers.map(async (user) => {
        const userDoc = await getDoc(doc(firestore, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          return {
            uid: user.uid,
            name: `${userData.firstName} ${userData.lastName}`,
            email: userData.email,
            permission: user.permission,
          };
        } else {
          return {
            uid: user.uid,
            name: 'Unknown User',
            email: 'Unknown',
            permission: user.permission,
          };
        }
      });
  
      const userDetails = await Promise.all(userDetailsPromises);
      return userDetails;
    } catch (error) {
      console.error('Error fetching project user details:', error);
      throw new Error('Failed to fetch user details.');
    }
  };