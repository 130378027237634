import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { Container, Row, Col, Card, Spinner, Image, Button } from 'react-bootstrap';
import './CompanyDetail.css';

const CompanyDetail = () => {
  const { id } = useParams();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const docRef = doc(firestore, 'companies', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setCompany({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching company:', error);
      }
      setLoading(false);
    };
    fetchCompany();
  }, [id]);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" />
      </Container>
    );
  }

  if (!company) {
    return <Container>No company found</Container>;
  }

  return (
    <section style={{ backgroundColor: '#eee' }}>
      <Container className="py-5">
        <Row>
          <Col>
            <nav aria-label="breadcrumb" className="bg-body-tertiary rounded-3 p-3 mb-4">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/find-services">Services</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Company Detail</li>
              </ol>
            </nav>
          </Col>
        </Row>

        <Row>
          <Col lg={4} className="text-center">
            <Card className="mb-4">
              <Card.Body>
                <Image 
                  src={company.companyLogoUrl} 
                  alt={`Logo for ${company.companyName}`} 
                  fluid 
                  className="company-logo" 
                  style={{ width: '150px' }}
                />
                <Card.Title className="my-3">{company.companyName}</Card.Title>
                <Card.Text className="text-muted mb-1">
                  <strong>Trade:</strong> {company.companyTradeType} <br />
                  <strong>ABN:</strong> {company.companyABN} <br />
                  {company.QBCCLicense && (
                    <>
                      <strong>QBCC License:</strong> {company.QBCCLicense} <br />
                    </>
                  )}
                  <strong>Contact Number:</strong> {company.companyNumber} <br />
                  {company.contactDetails?.email && (
                    <>
                      <strong>Email:</strong> {company.contactDetails.email} <br />
                    </>
                  )}
                  {company.websiteLink && (
                    <>
                      <strong>Website:</strong> <a href={company.websiteLink} target="_blank" rel="noopener noreferrer">{company.websiteLink}</a>
                    </>
                  )}
                </Card.Text>
                <div className="d-flex justify-content-center">
                  <Button variant="primary" className="me-2">Follow</Button>
                  <Button variant="outline-primary">Message</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={8}>
            <Card className="mb-4">
              <Card.Body>
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0"><strong>Company Name</strong></p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">{company.companyName}</p>
                  </div>
                </div>
                <hr />
                {company.contactDetails?.email && (
                  <>
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0"><strong>Email</strong></p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">{company.contactDetails.email}</p>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
                {company.companyNumber && (
                  <>
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0"><strong>Phone</strong></p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">{company.companyNumber}</p>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
              </Card.Body>
            </Card>

            {/* Progress Section */}
            <Row>
              <Col md={12}>
                <Card className="mb-4">
                  <Card.Body>
                    {company.companyBio && (
                      <>
                        <strong>Bio:</strong> {company.companyBio} <br />
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CompanyDetail;
