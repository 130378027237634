// src/components/ExpensesManager/AddExpenseModal.js

import React from 'react';
import { Modal } from 'react-bootstrap';
import ExpenseForm from './ExpenseForm'; // Ensure correct path and default export

const AddExpenseModal = ({ show, onHide, onAddExpense, tasks, phases }) => {
  const handleSubmit = (expenseData, attachment) => {
    onAddExpense(expenseData, attachment);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Add Expense</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ExpenseForm
          tasks={tasks}
          phases={phases}
          onSubmit={handleSubmit}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AddExpenseModal;
