// src/components/Alerts.js
import React from 'react';
import { Alert } from 'react-bootstrap';

const Alerts = ({ error, success }) => {
  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
    </>
  );
};

export default Alerts;
