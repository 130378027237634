import React, { useState } from 'react';
import { Form, Button, Alert, Container } from 'react-bootstrap';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { auth } from '../../firebase';

const QuoteSubmission = ({ jobId }) => {
  const [quote, setQuote] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const db = getFirestore();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (!user) {
      alert('Please log in to submit a quote.');
      return;
    }

    try {
      await addDoc(collection(db, 'quotes'), {
        jobId,
        userId: user.uid,
        quote,
        description,
        createdAt: serverTimestamp()
      });
      setQuote('');
      setDescription('');
      setSuccess(true);
      setError(null);
    } catch (err) {
      setError(err.message);
      setSuccess(false);
    }
  };

  return (
    <Container className='margin-bottom-15'>
      <hr></hr>
      <h3>Submit a Quote</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formQuote">
          <Form.Label>Quote Amount</Form.Label>
          <Form.Control
            type="text"
            value={quote}
            onChange={(e) => setQuote(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">Quote submitted successfully!</Alert>}
    </Container>
  );
};

export default QuoteSubmission;
