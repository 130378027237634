import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/auth/AuthContext';
import { Container, Row, Col, Form, Button, Image, Spinner, Alert, Card } from 'react-bootstrap';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth, updateProfile } from 'firebase/auth';
import { PencilSquare } from 'react-bootstrap-icons';
import BetaBanner from '../../components/BetaBanner';
import './UserProfile.css';

const UserProfile = () => {
  const { currentUser, subscriptions } = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [feedback, setFeedback] = useState({ message: '', type: '' });

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (currentUser) {
        const docRef = doc(firestore, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserDetails(data);
          setFirstName(data.firstName);
          setLastName(data.lastName);
        }
      }
    };
    fetchUserDetails();
  }, [currentUser]);

  const handleNameChange = (e, setter) => setter(e.target.value);

  const handleImageChange = e => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const saveProfile = async () => {
    setUploading(true);
    try {
      const userRef = doc(firestore, 'users', currentUser.uid);
      await updateDoc(userRef, { firstName, lastName });

      if (image) {
        const storageRef = ref(getStorage(), `profile_images/${currentUser.uid}`);
        await uploadBytes(storageRef, image);
        const url = await getDownloadURL(storageRef);
        await updateProfile(getAuth().currentUser, { photoURL: url });
      }

      setFeedback({ message: 'Profile updated successfully!', type: 'success' });
    } catch (error) {
      console.error('Error updating profile:', error);
      setFeedback({ message: 'Error updating profile', type: 'danger' });
    } finally {
      setUploading(false);
    }
  };

  return (
    <Container className="profile-container mt-5">
      <Row>
      <h1 className="profile-title">My Profile</h1>
        <Col md={8} className="mx-auto text-center">
          <Card className="profile-card p-4">
            <Row>
              <Col md={4}>
                <Image 
                  src={currentUser.photoURL || `${process.env.PUBLIC_URL}/assets/blank-profile-picture.png`} 
                  className="mb-3 profile-image"
                />
              </Col>
              <Col md={8}>
                <ProfileForm
                  firstName={firstName}
                  lastName={lastName}
                  onFirstNameChange={(e) => handleNameChange(e, setFirstName)}
                  onLastNameChange={(e) => handleNameChange(e, setLastName)}
                  onImageChange={handleImageChange}
                  onSaveProfile={saveProfile}
                  uploading={uploading}
                />
              </Col>
            </Row>
            {feedback.message && <Alert variant={feedback.type}>{feedback.message}</Alert>}
          </Card>
        </Col>
        <Col md={4} className="mx-auto">
          <Card className="profile-card p-4">
            <UserInfo userDetails={userDetails} />
            <SubscriptionStatus subscriptions={subscriptions} />
          </Card>
        </Col>
      </Row>
      <BetaBanner page="/user-profile" />
    </Container>
  );
};

const ProfileForm = ({ firstName, lastName, onFirstNameChange, onLastNameChange, onImageChange, onSaveProfile, uploading }) => {
  const [editMode, setEditMode] = useState({ firstName: false, lastName: false });

  const handleEditMode = (field) => {
    setEditMode({ ...editMode, [field]: !editMode[field] });
  };

  return (
    <Form>
      <Form.Group className="mb-3 d-flex align-items-center">
        <Form.Label className="flex-grow-1">
          First Name
          {editMode.firstName ? (
            <Form.Control type="text" value={firstName} onChange={onFirstNameChange} autoFocus />
          ) : (
            <span className="ms-2">{firstName}</span>
          )}
        </Form.Label>
        <PencilSquare
          onClick={() => handleEditMode('firstName')}
          className="ms-2"
          style={{ cursor: 'pointer' }}
        />
      </Form.Group>
      <Form.Group className="mb-3 d-flex align-items-center">
        <Form.Label className="flex-grow-1">
          Last Name
          {editMode.lastName ? (
            <Form.Control type="text" value={lastName} onChange={onLastNameChange} autoFocus />
          ) : (
            <span className="ms-2">{lastName}</span>
          )}
        </Form.Label>
        <PencilSquare
          onClick={() => handleEditMode('lastName')}
          className="ms-2"
          style={{ cursor: 'pointer' }}
        />
      </Form.Group>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Update Profile Image</Form.Label>
        <Form.Control type="file" onChange={onImageChange} />
      </Form.Group>
      <Button 
        onClick={onSaveProfile} 
        disabled={uploading} 
        variant="primary"
      >
        {uploading ? <Spinner as="span" animation="border" size="sm" /> : 'Save Profile'}
      </Button>
    </Form>
  );
};

const UserInfo = ({ userDetails }) => (
  userDetails && (
    <div>
      <p><strong>Name:</strong> {userDetails.firstName} {userDetails.lastName}</p>
      <p><strong>Email:</strong> {userDetails.email}</p>
    </div>
  )
);

const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString();
};

const SubscriptionStatus = ({ subscriptions }) => {
  if (!subscriptions || subscriptions.length === 0) {
    return <Alert variant="warning">No active subscriptions.</Alert>;
  }

  return (
    <div>
      {subscriptions.map((sub, index) => (
        <Alert key={index} variant="success">
          Subscription Plan: {sub.product}
        </Alert>
      ))}
    </div>
  );
};

export default UserProfile;
