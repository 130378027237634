// src/components/UserList.js
import React from 'react';
import { ListGroup, Button, Form } from 'react-bootstrap';

const UserList = ({
  users,
  isAdmin,
  currentUserId,
  onPermissionChange,
  onRemoveUser,
}) => {
  return (
    <ListGroup>
      {users.map((user) => (
        <ListGroup.Item
          key={user.uid}
          className="d-flex justify-content-between align-items-center"
        >
          <div>
            <strong>{user.name}</strong> - {user.email} ({user.permission})
          </div>
          {isAdmin && user.uid !== currentUserId && (
            <div>
              <Form.Select
                className="d-inline-block w-auto me-3"
                value={user.permission}
                onChange={(e) => onPermissionChange(user.uid, e.target.value)}
              >
                <option value="Admin">Admin</option>
                <option value="Edit">Edit</option>
                <option value="Read">Read</option>
              </Form.Select>
              <Button
                variant="danger"
                onClick={() => onRemoveUser(user.uid)}
              >
                Remove
              </Button>
            </div>
          )}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default UserList;
