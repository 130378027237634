import React, { useState, useEffect, useContext } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../firebase';
import { AuthContext } from '../../../context/auth/AuthContext';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const ManageEmployees = ({ selectedCompany }) => {
  const { currentUser } = useContext(AuthContext);
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEmployeeDetails = async (employee) => {
      const { uid, role } = employee;
      try {
        const userDocRef = doc(firestore, 'users', uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          return {
            uid,
            name: `${userData.firstName || 'Unknown'} ${userData.lastName || 'Unknown'}`,
            email: userData.email || 'No email',
            role: role || 'Standard',  // Use the role from the company document
          };
        } else {
          console.error(`No user found with UID ${uid}`);
          return { uid, name: 'Unknown', email: 'No email', role: role || 'Standard' };
        }
      } catch (error) {
        console.error(`Error fetching details for UID ${uid}:`, error);
        return { uid, name: 'Unknown', email: 'No email', role: role || 'Standard' };
      }
    };

    const fetchEmployees = async () => {
      if (!selectedCompany) return;

      try {
        const companyDocRef = doc(firestore, 'companies', selectedCompany.id);
        const companyDoc = await getDoc(companyDocRef);

        if (companyDoc.exists()) {
          const data = companyDoc.data();
          if (data.employees) {
            const employeeDetailsPromises = Object.values(data.employees).map((employee) =>
              fetchEmployeeDetails(employee)
            );
            const employeeDetails = await Promise.all(employeeDetailsPromises);
            setEmployees(employeeDetails);
          } else {
            setEmployees([]);
          }
        } else {
          setError('No such company document.');
        }
      } catch (error) {
        setError('Failed to load employees.');
      }
    };

    fetchEmployees();
  }, [selectedCompany]);

  return (
    <div>
      <h2>Manage Employees</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee) => (
            <tr key={employee.uid}>
              <td>{employee.name}</td>
              <td>{employee.email}</td>
              <td>{employee.role}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ManageEmployees;
