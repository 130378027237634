// src/config/expenseConfig.js

export const accountCodeOptions = [
    { value: '1000', label: 'Construction' },
    { value: '2000', label: 'Legal' },
    { value: '3000', label: 'Marketing' },
    // Add more as needed
  ];
  
  export const taxCodeOptions = [
    { value: 'GST', label: 'GST' },
    { value: 'Non-GST', label: 'Non-GST' },
  ];
  
  export const paymentMethodOptions = [
    { value: 'Cash', label: 'Cash' },
    { value: 'Credit Card', label: 'Credit Card' },
    { value: 'Bank Transfer', label: 'Bank Transfer' },
    // Add more as needed
  ];
  