import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../App.css';

const CreateJobBanner = () => {
  return (
    <div className="banner-section">
      <Container>
        <Row className="align-items-center text-center">
          <Col>
            <h2 className="banner-title">
              Start Your First Project with LUNAR8 Today!
            </h2>
            <p className="banner-subtitle">
              Experience the efficiency of our platform. It's quick, easy, and free to get started.
            </p>
            <Link to="/projects-dashboard">
              <Button variant="primary" size="lg" className="banner-button">
                Create a Project
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateJobBanner;
