import React, { useContext, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth/AuthContext';
import { logPageView, logEvent } from '../../analytics';
import TopCategories from './components/TopCategories';
import Banner from '../../components/CTAContent/CreateJobBanner';
import CookieConsent from '../../components/CookieConsent/CookieConsent';
import './LandingPage.css';

const CustomerLanding = () => {
  const { currentUser } = useContext(AuthContext);
  const isAuthenticated = !!currentUser;

  useEffect(() => {
    logPageView();
  }, []);

  const handleGetStartedClick = () => {
    logEvent('Button', 'Click', 'Get Started Today');
  };

  return (
    <div className="text-center">
      <CookieConsent />
      <div className="banner-container">
        <img src={`${process.env.PUBLIC_URL}/assets/banner.png`} alt="Banner" className="banner-image height-300" />
        <div className="overlay-full"></div>
        <Container fluid="md" className="pt-4 banner-content">
          <Col>
            <h1>
              Streamline Your Projects with <span style={{ color: '#FF3A50' }}>LUNAR8</span>
            </h1>
            <p>
              Save time, reduce costs, and access top-tier trades with our all-in-one project management platform. Post jobs, get multiple quotes, and manage your entire project effortlessly from start to finish.
            </p>
            {!isAuthenticated && (
              <Button variant="primary" as={Link} to="/signup" className='get-started-btn' onClick={handleGetStartedClick}>
                Get Started Now
              </Button>
            )}
          </Col>
        </Container>
      </div>

      {/* New 'How It Works' Section */}
      <Container className="how-it-works-section feature-section py-5">
        <Row className="mt-3">
          <Col>
            <h2 className='primary-color'>How It Works</h2>
          </Col>
        </Row>
        <Row className="text-left">
          <Col md={4} className="step">
            <h4 className="step-title">1. Sign Up</h4>
            <p>Create your account and access a full suite of tools to manage and execute your projects.</p>
          </Col>
          <Col md={4} className="step">
            <h4 className="step-title">2. Create Your Project</h4>
            <p>Set up your project by defining tasks, timelines, and resources. Need help along the way? You can post jobs to bring in expert trades when needed.</p>
          </Col>
          <Col md={4} className="step">
            <h4 className="step-title">3. See It Through to Completion</h4>
            <p>Monitor progress, collaborate with your team, and bring your project to successful completion.</p>
          </Col>
        </Row>
      </Container>


      {/* Contact Section */}
      <Row className='mt-3'>
        <Banner />
      </Row>

      <Container>
        {/* Why Choose Us Section */}
        <Row className='mt-4'>
          <Col className='pb-2'>
            <h2 className='primary-color'>Why Choose LUNAR8?</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4}>
            <Card className="card-custom">
              <Card.Body>
                <Card.Title className='primary-color'><h4>Save Time & Money</h4></Card.Title>
                <Card.Text className='why-us-card'>
                  Get multiple quotes from vetted professionals, ensuring you always get the best deal for your project.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="card-custom">
              <Card.Body>
                <Card.Title className='primary-color'><h4>Qualified Trades</h4></Card.Title>
                <Card.Text className='why-us-card'>
                  We work only with licensed, reputable tradespeople to help ensure quality and reliability.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="card-custom">
              <Card.Body>
                <Card.Title className='primary-color'><h4>Ease of Use</h4></Card.Title>
                <Card.Text className='why-us-card'>
                  Our platform’s intuitive interface makes it easy to submit job requests and manage quotes efficiently.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container className="feature-section mt-3 py-5">
        {/* Features Section */}
        <Row className="mb-2">
          <Col>
            <h2 className='primary-color'>Features That Set Us Apart</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4}>
            <Card className="feature-card">
              <Card.Body>
                <Card.Title className='primary-color'><h4>Advanced Analytics</h4></Card.Title>
                <Card.Text>
                  Leverage powerful analytics to track your project’s performance and make data-driven decisions.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="feature-card">
              <Card.Body>
                <Card.Title className='primary-color'><h4>Seamless Collaboration</h4></Card.Title>
                <Card.Text>
                  Facilitate communication and collaboration among team members with our integrated tools.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="feature-card">
              <Card.Body>
                <Card.Title className='primary-color'><h4>Cloud Integration</h4></Card.Title>
                <Card.Text>
                  Access your projects from anywhere with our secure, cloud-based platform.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Final CTA Section */}
      <Row className='mt-4'>
        <div className="banner-container">
          <img src={`${process.env.PUBLIC_URL}/assets/first-class-living.jpg`} alt="Banner" className="container-image height-400" />
          <div className="overlay-full"></div>
          <Container className="banner-content">
            <Col className="text-right px-3">
              <h1>
                Unlock Your Project Potential with <span style={{ color: '#FF3A50' }}>LUNAR8</span>
              </h1>
              <Row>
                <Col md={6}></Col>
                <Col md={6}>
                  Join our platform today and experience a smarter way to manage your projects. Whether you're a developer or a business owner, LUNAR8 connects you with the best trades.
                </Col>
              </Row>
              {!isAuthenticated && (
                <Button variant="primary" as={Link} to="/signup" className='get-started-btn mt-2'>
                  Sign Up Now
                </Button>
              )}
            </Col>
          </Container>
        </div>
      </Row>

      {/* Customer Testimonials */}
      <Container>
        <Row className='mt-4'>
          <Col>
            <h2 className='primary-color'>What Our Customers Say</h2>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col md={4}>
            <Card className="testimonial-card">
              <Card.Body>
                <Card.Text>
                  “LUNAR8 made it incredibly easy to find reliable trades for our office renovation. We saved time and money!”
                  <br /><br />
                  <strong>- Alex, Property Manager</strong>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="testimonial-card">
              <Card.Body>
                <Card.Text>
                  “The platform’s project management tools helped us stay on track and within budget. Highly recommend!”
                  <br /><br />
                  <strong>- Sarah, Business Owner</strong>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <h2 className='primary-color mt-4'>Get In Touch</h2>
          <Col>
            <iframe 
              src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1oId4x0q_TaDy86GQlLiwbCCnLLn6VnhK6Z-VBi-DfeNgYqIVoTJwoPhSw9l-wJbS3PdonLEtR?gv=true" 
              style={{ border: 0, width: '100%', height: '650px' }} 
              frameBorder="0"
              title="Google Calendar Appointment Scheduling"
              allowFullScreen
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CustomerLanding;
