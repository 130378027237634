import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc, serverTimestamp, collection, query, getDocs, where } from 'firebase/firestore';
import { Spinner, Alert, Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { auth } from '../../firebase';
import QuoteSubmission from './QuoteSubmission';
import JobQuotes from './JobQuotes';
import JobQuestions from './JobQuestions';

const JobDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedJob, setEditedJob] = useState({});
  const [quotes, setQuotes] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const jobDoc = await getDoc(doc(db, 'jobs', id));
        if (jobDoc.exists()) {
          setJob(jobDoc.data());
          setEditedJob(jobDoc.data()); // Load job details for editing
        } else {
          setError('Job not found');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchJob();
  }, [id, db]);

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const quotesQuery = query(collection(db, 'quotes'), where('jobId', '==', id));
        const quotesSnapshot = await getDocs(quotesQuery);
        const quotesData = quotesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setQuotes(quotesData);
      } catch (err) {
        setError(err.message);
      }
    };
    fetchQuotes();
  }, [id, db]);

  const handleEditJob = () => {
    setIsEditing(true);
  };

  const handleSaveJob = async () => {
    try {
      await updateDoc(doc(db, 'jobs', id), {
        ...editedJob,
        updatedAt: serverTimestamp(),
      });
      setJob(editedJob);
      setIsEditing(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedJob(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  const jobAccepted = quotes.some(quote => quote.status === 'accepted');
  const isJobCreator = auth.currentUser && auth.currentUser.uid === job.userId;

  return (
    <Container className="py-5">
      <Button variant="link" onClick={handleBack} className="mb-4">
        &larr; Back
      </Button>

      <Card className="shadow-sm mb-4">
        <Card.Body>
          <Row>
            <Col md={8}>
              <h2 className="display-4 mb-3">{job.title}</h2>
              {!isEditing ? (
                <>
                  <p className="lead">{job.description}</p>
                  <p><strong>Category:</strong> {job.tradeCategory}</p>
                  <p><strong>Budget:</strong> ${job.budgetRange[0]} - ${job.budgetRange[1]}</p>
                  <p><strong>Suburb:</strong> {job.suburb}</p>

                  {job.imageUrl && (
                    <div className="image-container">
                      <img src={job.imageUrl} alt={job.title} className="img-fluid small-img rounded shadow-sm mb-4" />
                    </div>
                  )}
                  {job.imageUrls && job.imageUrls.length > 0 && (
                    <Row className="g-2">
                      {job.imageUrls.map((url, index) => (
                        <Col key={index} xs={6} md={4}>
                          <img src={url} alt={`Image ${index + 1}`} className="img-fluid small-img rounded shadow-sm" />
                        </Col>
                      ))}
                    </Row>
                  )}

                  {isJobCreator && !jobAccepted && (
                    <Button variant="primary" onClick={handleEditJob} className="mt-4">
                      Edit Job Details
                    </Button>
                  )}
                </>
              ) : (
                <Form className="mt-4">
                  <Form.Group controlId="formTitle">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      value={editedJob.title}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formDescription" className="mt-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      value={editedJob.description}
                      onChange={handleInputChange}
                      rows={3}
                    />
                  </Form.Group>
                  <Form.Group controlId="formTradeCategory" className="mt-3">
                    <Form.Label>Trade Category</Form.Label>
                    <Form.Control
                      type="text"
                      name="tradeCategory"
                      value={editedJob.tradeCategory}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBudgetRange" className="mt-3">
                    <Form.Label>Budget Range</Form.Label>
                    <Form.Control
                      type="text"
                      name="budgetRange"
                      value={editedJob.budgetRange.join(' - ')}
                      onChange={e => setEditedJob({
                        ...editedJob,
                        budgetRange: e.target.value.split(' - ').map(Number)
                      })}
                    />
                  </Form.Group>
                  <Form.Group controlId="formSuburb" className="mt-3">
                    <Form.Label>Suburb</Form.Label>
                    <Form.Control
                      type="text"
                      name="suburb"
                      value={editedJob.suburb}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Button variant="success" onClick={handleSaveJob} className="mt-4">
                    Save Changes
                  </Button>
                </Form>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="shadow-sm">
        <Card.Body>
          <Row>
            <Col>
              {auth.currentUser && (
                <>
                  <JobQuestions jobId={id} jobCreatorId={job.userId} />
                  <JobQuotes jobId={id} jobCreatorId={job.userId} />
                </>
              )}
              {auth.currentUser && !isJobCreator && (
                <>
                  <QuoteSubmission jobId={id} />
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default JobDetail;
