// src/components/SelectOrCreateProjectPrompt.js

import React, { useContext, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FaProjectDiagram } from 'react-icons/fa'; // Import an icon
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth/AuthContext'; 
import { PRO_PLAN_ID, FOUNDERS_CLUB_PLAN_ID } from '../../../context/subscriptions/subscriptionPlans';
import CreateProjectModal from '../ProjectsManager/CreateProjectModal'; 

const SelectOrCreateProjectPrompt = ({ projectCount }) => {
  const { currentUser, subscriptions } = useContext(AuthContext) || {};
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [projects, setProjects] = useState([]); // If needed

  // Determine subscription flags using imported plan IDs
  const hasProSubscription = subscriptions?.some(sub => sub.plan_id === PRO_PLAN_ID);
  const hasFoundersClubSubscription = subscriptions?.some(sub => sub.plan_id === FOUNDERS_CLUB_PLAN_ID);

  // Determine if user has any active subscription
  const hasActiveSubscription = hasProSubscription || hasFoundersClubSubscription;

  // Determine if user can create a new project based solely on subscription
  const canCreateNewProject = hasActiveSubscription;

  const handleCreateProject = () => {
    setShowModal(true);
  };

  const handleManageSubscription = () => {
    navigate('/billing-management');
  };

  const handleModalClose = () => setShowModal(false);

  const addProject = (project) => {
    setProjects([...projects, project]);
    // Optionally, you can perform additional actions like refreshing the project list
  };

  return (
    <>
      <Alert variant="info" className="text-center mt-5 p-5 shadow-sm" style={{ borderRadius: '10px' }}>
        <FaProjectDiagram size={50} className="mb-4" style={{ color: '#0d6efd' }} /> {/* Add icon */}
        <h4 className="mb-3">Select or Create a Project</h4>
        <p>Use the menu on the left to select an existing project or create a new one to get started.</p>

        {/* Conditional Button Rendering */}
        {canCreateNewProject ? (
          <Button variant="primary" onClick={handleCreateProject}>
            Create New Project
          </Button>
        ) : (
          <Button variant="primary" onClick={handleManageSubscription}>
            Manage Subscription
          </Button>
        )}

        {/* Conditional Promotional Message */}
        {!canCreateNewProject && (
          <div className="mt-4">
            <Alert variant="warning" className="text-center">
              Join our <strong>Founders Club</strong> by October 31st and Unlock Full Access for Just <strong>$5.00/Month</strong>. Limited Time, Unlimited Projects — Sign Up Now!
            </Alert>
          </div>
        )}
      </Alert>

      {/* Reusable CreateProjectModal */}
      <CreateProjectModal
        show={showModal}
        handleClose={handleModalClose}
        addProject={addProject}
      />
    </>
  );
};

export default SelectOrCreateProjectPrompt;
