import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';

const BetaBanner = ({ page }) => {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const lastClosed = localStorage.getItem('betaBannerClosed');
    if (lastClosed) {
      const diffInDays = (new Date() - new Date(lastClosed)) / (1000 * 3600 * 24);
      if (diffInDays < 14) {
        setShowBanner(false);
      }
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('betaBannerClosed', new Date());
    setShowBanner(false);
  };

  if (!showBanner || page === '/') return null;

  return (
    <Alert variant="info" onClose={handleClose} dismissible>
      <Alert.Heading>Beta Version</Alert.Heading>
      <p>
        This platform is currently in Beta. We welcome your feedback which can be submitted through the feedback button in the navigation bar.
      </p>
      <Button onClick={handleClose}>Got it!</Button>
    </Alert>
  );
};

export default BetaBanner;
