// src/components/AddUserModal.js
import React, { useState } from 'react';
import { Modal, Button, Form, Alert, Row, Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';

const AddUserModal = ({
  show,
  onClose,
  onAddUser,
}) => {
  const [inviteEmails, setInviteEmails] = useState(['']);
  const [invitePermissions, setInvitePermissions] = useState(['Edit']);
  const [addUserError, setAddUserError] = useState('');

  const handleInviteEmailChange = (index, event) => {
    const newInviteEmails = [...inviteEmails];
    newInviteEmails[index] = event.target.value;
    setInviteEmails(newInviteEmails);
  };

  const handleInvitePermissionChange = (index, event) => {
    const newInvitePermissions = [...invitePermissions];
    newInvitePermissions[index] = event.target.value;
    setInvitePermissions(newInvitePermissions);
  };

  const handleAddInviteField = () => {
    setInviteEmails([...inviteEmails, '']);
    setInvitePermissions([...invitePermissions, 'Edit']);
  };

  const handleRemoveInviteField = (index) => {
    const newInviteEmails = [...inviteEmails];
    const newInvitePermissions = [...invitePermissions];
    newInviteEmails.splice(index, 1);
    newInvitePermissions.splice(index, 1);
    setInviteEmails(newInviteEmails);
    setInvitePermissions(newInvitePermissions);
  };

  const handleSubmit = () => {
    onAddUser(inviteEmails, invitePermissions, setAddUserError);
  };

  const handleClose = () => {
    setInviteEmails(['']);
    setInvitePermissions(['Edit']);
    setAddUserError('');
    onClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {addUserError && <Alert variant="danger">{addUserError}</Alert>}
        {inviteEmails.map((email, index) => (
          <Row key={index} className="mb-3">
            <Col xs={12} md={5}>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => handleInviteEmailChange(index, e)}
                placeholder="Enter user email"
              />
            </Col>
            <Col xs={12} md={5}>
              <Form.Select
                value={invitePermissions[index]}
                onChange={(e) => handleInvitePermissionChange(index, e)}
              >
                <option value="Edit">Edit</option>
                <option value="Read">Read</option>
                <option value="Admin">Admin</option>
              </Form.Select>
            </Col>
            <Col xs={12} md={2} className="text-center">
              {inviteEmails.length > 1 && (
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => handleRemoveInviteField(index)}
                >
                  <FaTrash />
                </Button>
              )}
            </Col>
          </Row>
        ))}
        <Button variant="link" onClick={handleAddInviteField}>
          + Add another user
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Add Users
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddUserModal;
