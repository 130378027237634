import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { auth } from '../../firebase';
import { Spinner, Alert, Container, Row, Col, Card, Nav, Button } from 'react-bootstrap';
import CreateJob from './CreateJob';  // Import the CreateJob component
import { Link } from 'react-router-dom';

const JobList = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('createJob');
  const [userCompanies, setUserCompanies] = useState([]);
  const db = getFirestore();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchUserCompanies = async () => {
      if (!user) {
        setError('No authenticated user.');
        return;
      }

      const companySnapshot = await getDocs(collection(db, 'companies'));
      const companies = companySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((company) =>
          company.employees.some((employee) => employee.uid === user.uid.toString())
        );

      setUserCompanies(companies);
    };

    fetchUserCompanies();
  }, [db, user]);

  useEffect(() => {
    if (activeTab === 'createJob') return;

    const fetchJobs = async () => {
      try {
        if (!user) {
          setError('No authenticated user.');
          return;
        }

        let jobsQuery;

        if (activeTab === 'findJobs') {
          const userTradeTypes = userCompanies.map((company) => company.companyTradeType);

          if (userTradeTypes.length > 0) {
            jobsQuery = query(
              collection(db, 'jobs'),
              where('tradeCategory', 'in', userTradeTypes)
            );
          } else {
            setJobs([]);  // No matching trade types found
            setLoading(false);
            return;
          }

        } else if (activeTab === 'viewMyJobs') {
          jobsQuery = query(
            collection(db, 'jobs'),
            where('userId', '==', user.uid)
          );

        } else if (activeTab === 'viewAcceptedJobs') {
          const quotesSnapshot = await getDocs(
            query(collection(db, 'quotes'), where('userId', '==', user.uid), where('status', '==', 'accepted'))
          );
          const acceptedJobIds = quotesSnapshot.docs.map((doc) => doc.data().jobId);

          if (acceptedJobIds.length > 0) {
            jobsQuery = query(
              collection(db, 'jobs'),
              where('__name__', 'in', acceptedJobIds)
            );
          } else {
            setJobs([]);  // No accepted jobs found
            setLoading(false);
            return;
          }
        }

        const jobCollection = await getDocs(jobsQuery);
        const jobsData = jobCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        setJobs(jobsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [db, user, activeTab, userCompanies]);

  if (loading && activeTab !== 'createJob') {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Container>
      <Nav variant="tabs" activeKey={activeTab} onSelect={(selectedTab) => setActiveTab(selectedTab)} className='mt-3'>
        <Nav.Item>
          <Nav.Link eventKey="createJob">Create New Job</Nav.Link>
        </Nav.Item>
        {userCompanies.length > 0 && (
          <Nav.Item>
            <Nav.Link eventKey="findJobs">Find Jobs</Nav.Link>
          </Nav.Item>
        )}
        <Nav.Item>
          <Nav.Link eventKey="viewMyJobs">View My Jobs</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="viewAcceptedJobs">View My Accepted Jobs</Nav.Link>
        </Nav.Item>
      </Nav>

      {activeTab === 'createJob' ? (
        <Row className='mt-3 mb-5 modal-style'>
        <CreateJob />
        </Row>
      ) : jobs.length === 0 ? (
        <Alert variant="info">No jobs found for this category yet.</Alert>
      ) : (
        <Row className='mt-1 mb-5 modal-style'>
          {jobs.map((job) => (
            <Col key={job.id} md={4}>
              <Card className="mb-2">
                <Card.Body>
                  <Card.Title>
                    <Link to={`/jobs/${job.id}`}>{job.title}</Link>
                  </Card.Title>
                  <Card.Text>{job.description}</Card.Text>
                  <Card.Text>Budget: ${job.budgetRange[0]} - ${job.budgetRange[1]}</Card.Text>
                  <Card.Text>Category: {job.tradeCategory}</Card.Text>
                  <Card.Text>Location: {job.suburb}</Card.Text> {/* Show only suburb */}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default JobList;
