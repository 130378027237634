// src/components/FounderClubStatus.js

import React, { useContext } from 'react';
import { AuthContext } from '../../../context/auth/AuthContext';
import { Badge } from 'react-bootstrap';

const FounderClubStatus = () => {
  const { subscriptions } = useContext(AuthContext);

  const foundersClubPlanId = 'price_1QA64TF1Sd4Db5Lgl8bD1669';

  const hasFoundersClubSubscription = subscriptions.some(
    (sub) => sub.plan_id === foundersClubPlanId && sub.status === 'active'
  );

  return (
    <div>
      Renewal Status:{' '}
      {hasFoundersClubSubscription ? (
        <Badge bg="success">Active</Badge>
      ) : (
        <Badge bg="secondary">Inactive</Badge>
      )}
    </div>
  );
};

export default FounderClubStatus;
