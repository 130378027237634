
import React from 'react';
import { Alert, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../../../App.css';

const ListingSuccess = () => {
  const navigate = useNavigate();

  return (
    <Container className="mt-5 text-center">
      <Alert className="modal-style" variant="success">
        <Alert.Heading>🎉 Your Business is Now Listed!</Alert.Heading>
        <p className="banner-subtitle">Congratulations! Your business has been successfully listed on our platform.</p>
        <div className="next-steps mt-2">
          <strong className="banner-subtitle" >What's Next?</strong>
          <ul className="banner-benefits">
            <li>Manage your business profile within the <strong>My Company</strong> tab.</li>
            {/* <li>Start browsing and quoting on jobs in the <strong>Manage Jobs</strong> tab.</li> */}
            <li>Maximise your visibility by keeping your profile up to date.</li>
            <li>Receive notifications for new functionality directly on your teams dashboard.</li>
          </ul>
        </div>
      </Alert>
    </Container>
  );
};

export default ListingSuccess;
