// src/components/BillingManagement/PaymentDisplay.js

import React, { useContext } from 'react';
import { Container, Row, Col, Card, Button, Badge } from 'react-bootstrap'; 
import axios from 'axios';
import './SubscriptionStyles.css';
import { AuthContext } from '../../context/auth/AuthContext';

const PaymentDisplay = ({ plans }) => {
  const { currentUser } = useContext(AuthContext);
  const API_URL = `${process.env.REACT_APP_API_URL}`;

  const handleSelectPlan = async (planId) => {
    if (!currentUser || !currentUser.email) {
      console.error('User is not authenticated or email is missing.');
      // Optionally, redirect to login or show a message to the user
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/create-checkout-session`, {
        lookup_key: planId,
        email: currentUser.email, // Send the user's email
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      // Optionally, handle error by displaying a message to the user
    }
  };

  return (
    <Container className="payment-display-container">
      <Row className="justify-content-md-center">
        <h2 className="mb-5 text-center">Choose Your Plan</h2>
        {plans.map(plan => (
          <Col md="4" sm="6" xs="12" key={plan.id} className="mb-4">
            <Card className={`plan-card ${plan.name.includes('Founders Club') ? 'highlight-plan' : ''}`}>
              {plan.name.includes('Founders Club') && <Badge pill className="exclusive-badge">Exclusive Offer</Badge>}
              <Card.Body>
                <Card.Title className="text-center">{plan.name}</Card.Title>
                <Card.Subtitle className="mb-3 text-muted text-center">
                  ${plan.price} {plan.currency.toUpperCase()} / 
                  {plan.interval_count > 1 ? `${plan.interval_count} ${plan.interval}s` : plan.interval}
                </Card.Subtitle>
                <hr/>
                {plan.name.includes('Founders Club') && (
                  <>
                    <p className="text-center mt-3 terms-and-conditions">
                      <ul className="plan-features">
                        <li>Access to all Pro features</li>
                        <li>Early access to new exclusive features</li>
                        <li>Priority customer support & feature requests</li>
                      </ul>
                    </p>
                  </>
                )}
                {plan.name.includes('Pro') && (
                  <p className="text-center mt-3 terms-and-conditions">
                    <ul className="plan-features">
                      <li>Full Access to Lunar8 Project Management tooling</li>
                      <li>Create Unlimited Projects</li>
                      <li>Invite Unlimited Project Collaborators</li>
                      <li>Priority customer support</li>
                    </ul>
                  </p>
                )}
                
                <div className="text-center">
                  <Button variant="primary" className="plan-button" onClick={() => handleSelectPlan(plan.id)}>
                    {plan.name.includes('Founders Club') ? 'Join Now' : 'Select Plan'}
                  </Button>
                </div>
                {plan.name.includes('Founders Club') && (
                  <>
                    <p className="text-center mt-3 terms-and-conditions">
                      <i>Limited time offer! Only ${plan.price} {plan.currency.toUpperCase()}/month for the first 15 early subscribers. Offer availalbe until October 31st, 2024. Sign up now!</i>
                    </p>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default PaymentDisplay;
