// Dashboard.js
import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../../../firebase';
import {
  Row,
  Col,
  Card,
  ProgressBar,
  ListGroup,
  Modal,
  Button,
} from 'react-bootstrap';
import {
  FaTasks,
  FaCheckCircle,
  FaSpinner,
  FaExclamationCircle,
  FaClock,
  FaLink,
  FaCalendarTimes,
  FaBan,
} from 'react-icons/fa';
import './Dashboard.css';

const Dashboard = ({ project }) => {
  const [tasks, setTasks] = useState([]);
  const [phases, setPhases] = useState([]);

  // State for modal visibility and selected tasks
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [selectedTasks, setSelectedTasks] = useState([]);

  useEffect(() => {
    if (project) {
      const unsubscribe = onSnapshot(
        collection(firestore, `projects/${project.id}/tasks`),
        (snapshot) => {
          const fetchedTasks = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTasks(fetchedTasks.filter((task) => task.taskType !== 'Phase'));
          setPhases(fetchedTasks.filter((task) => task.taskType === 'Phase'));
        }
      );
      return () => unsubscribe();
    }
  }, [project]);

  // Helper function to parse date strings
  function parseDate(dateStr) {
    if (!dateStr) return null;
    const [day, month, year] = dateStr.split('/');
    return new Date(`${year}-${month}-${day}`);
  }

  // Helper function to calculate business days between two dates
  function calculateBusinessDays(startDate, endDate) {
    let count = 0;
    let curDate = new Date(startDate);
    while (curDate < endDate) {
      const dayOfWeek = curDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        // It's a weekday
        count++;
      }
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  }

  // Calculate stats
  const totalTasks = tasks.length;
  const completedTasks = tasks.filter((task) => task.status === 'Done');
  const inProgressTasks = tasks.filter((task) => task.status === 'In Progress');
  const notStartedTasks = tasks.filter((task) => task.status === 'To Do');

  const activeTasks = totalTasks;
  const projectProgress = activeTasks
    ? (completedTasks.length / activeTasks) * 100
    : 0;

  // Overdue tasks (tasks past their target date)
  const overdueTasks = tasks.filter((task) => {
    const targetDate = parseDate(task.targetDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize time
    return (
      targetDate &&
      targetDate < today &&
      task.status !== 'Done'
    );
  });

  // Tasks due today
  const tasksDueToday = tasks.filter((task) => {
    const targetDate = parseDate(task.targetDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize time
    return (
      targetDate &&
      targetDate.toDateString() === today.toDateString() &&
      task.status !== 'Done'
    );
  });

  // Upcoming deadlines (tasks due within the next 7 days)
  const upcomingTasks = tasks
    .filter((task) => {
      const targetDate = parseDate(task.targetDate);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize time
      const inSevenDays = new Date(today);
      inSevenDays.setDate(today.getDate() + 7);
      return (
        targetDate &&
        targetDate > today &&
        targetDate <= inSevenDays &&
        task.status !== 'Done'
      );
    })
    .sort((a, b) => parseDate(a.targetDate) - parseDate(b.targetDate));

// Helper function to safely get dependencies
const getDependencies = (task) => {
  if (Array.isArray(task.dependsOn)) {
    return task.dependsOn;
  } else if (typeof task.dependsOn === 'string') {
    // If dependsOn is a single string, convert it to an array
    return [task.dependsOn];
  } else {
    // Log a warning and return an empty array
    console.warn(`Task ${task.id} has invalid dependsOn:`, task.dependsOn);
    return [];
  }
};

// Upcoming tasks with unresolved dependencies
const upcomingTasksWithUnresolvedDependencies = upcomingTasks.filter(
  (task) => {
    const dependencies = getDependencies(task);
    if (dependencies.length === 0) return false;

    return dependencies.some((dependencyId) => {
      const dependencyTask = tasks.find((t) => t.id === dependencyId);
      return dependencyTask && dependencyTask.status !== 'Done';
    });
  }
);

// Tasks blocked by dependencies (regardless of due date)
const tasksBlockedByDependencies = tasks.filter((task) => {
  const dependencies = getDependencies(task);
  if (dependencies.length === 0) return false;

  return dependencies.some((dependencyId) => {
    const dependencyTask = tasks.find((t) => t.id === dependencyId);
    return dependencyTask && dependencyTask.status !== 'Done';
  });
});

  // Tasks without target dates
  const tasksWithoutTargetDates = tasks.filter((task) => !task.targetDate);

  // Handle card clicks
  const handleCardClick = (title, tasks, options = {}) => {
    setModalTitle(title);
    setSelectedTasks(tasks);
    setShowModal(true);
  };

  return (
    <div className="dashboard-container">
      <Row className="mb-4">
        <Col xs={12}>
          <h2>{project.name} Dashboard</h2>
        </Col>
      </Row>
      <Row className="mb-4">
        {/* Total Tasks */}
        <Col xs={12} md={2}>
          <Card
            className="stat-card h-100 clickable-card"
            onClick={() => handleCardClick('Total Tasks', tasks)}
          >
            <Card.Body>
              <FaTasks className="stat-icon" />
              <h5>Total Tasks</h5>
              <h3>{totalTasks}</h3>
            </Card.Body>
          </Card>
        </Col>
        {/* Completed Tasks */}
        <Col xs={12} md={2}>
          <Card
            className="stat-card h-100 clickable-card"
            onClick={() => handleCardClick('Completed Tasks', completedTasks)}
          >
            <Card.Body>
              <FaCheckCircle className="stat-icon text-success" />
              <h5>Completed Tasks</h5>
              <h3>{completedTasks.length}</h3>
            </Card.Body>
          </Card>
        </Col>
        {/* In Progress Tasks */}
        <Col xs={12} md={2}>
          <Card
            className="stat-card h-100 clickable-card"
            onClick={() =>
              handleCardClick('In Progress Tasks', inProgressTasks)
            }
          >
            <Card.Body>
              <FaSpinner className="stat-icon text-primary" />
              <h5>In Progress</h5>
              <h3>{inProgressTasks.length}</h3>
            </Card.Body>
          </Card>
        </Col>
        {/* Not Started Tasks */}
        <Col xs={12} md={2}>
          <Card
            className="stat-card h-100 clickable-card"
            onClick={() => handleCardClick('Not Started Tasks', notStartedTasks)}
          >
            <Card.Body>
              <FaExclamationCircle className="stat-icon text-warning" />
              <h5>Not Started</h5>
              <h3>{notStartedTasks.length}</h3>
            </Card.Body>
          </Card>
        </Col>
        {/* Overdue Tasks */}
        <Col xs={12} md={2}>
          <Card
            className="stat-card h-100 clickable-card"
            onClick={() =>
              handleCardClick('Overdue Tasks', overdueTasks, {
                showOverdueInfo: true,
              })
            }
          >
            <Card.Body>
              <FaClock className="stat-icon text-danger" />
              <h5>Overdue Tasks</h5>
              <h3>{overdueTasks.length}</h3>
            </Card.Body>
          </Card>
        </Col>
        {/* Tasks Due Today */}
        <Col xs={12} md={2}>
          <Card
            className="stat-card h-100 clickable-card"
            onClick={() => handleCardClick('Tasks Due Today', tasksDueToday)}
          >
            <Card.Body>
              <FaClock className="stat-icon text-info" />
              <h5>Due Today</h5>
              <h3>{tasksDueToday.length}</h3>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Additional Metrics Row */}
      <Row className="mb-4">
        {/* Upcoming Tasks with Unresolved Dependencies */}
        <Col xs={12} md={4}>
          <Card
            className="stat-card h-100 clickable-card"
            onClick={() =>
              handleCardClick(
                'Upcoming Tasks with Unresolved Dependencies',
                upcomingTasksWithUnresolvedDependencies,
                { showDependencies: true }
              )
            }
          >
            <Card.Body>
              <FaLink className="stat-icon text-secondary" />
              <h5>Upcoming Tasks with Unresolved Dependencies</h5>
              <h3>{upcomingTasksWithUnresolvedDependencies.length}</h3>
            </Card.Body>
          </Card>
        </Col>
        {/* Tasks Without Target Dates */}
        <Col xs={12} md={4}>
          <Card
            className="stat-card h-100 clickable-card"
            onClick={() =>
              handleCardClick(
                'Tasks Without Target Dates',
                tasksWithoutTargetDates
              )
            }
          >
            <Card.Body>
              <FaCalendarTimes className="stat-icon text-warning" />
              <h5>Tasks Without Target Dates</h5>
              <h3>{tasksWithoutTargetDates.length}</h3>
            </Card.Body>
          </Card>
        </Col>
        {/* Tasks Blocked by Dependencies */}
        <Col xs={12} md={4}>
          <Card
            className="stat-card h-100 clickable-card"
            onClick={() =>
              handleCardClick(
                'Tasks Blocked by Dependencies',
                tasksBlockedByDependencies,
                { showDependencies: true }
              )
            }
          >
            <Card.Body>
              <FaBan className="stat-icon text-danger" />
              <h5>Tasks Blocked by Dependencies</h5>
              <h3>{tasksBlockedByDependencies.length}</h3>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Project Progress */}
      <Row className="mb-4">
        <Col xs={12}>
          <Card>
            <Card.Body>
              <h5>Project Progress</h5>
              <ProgressBar
                now={projectProgress}
                label={`${projectProgress.toFixed(1)}%`}
                variant="success"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Upcoming Deadlines */}
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <h5>Upcoming Tasks</h5>
            </Card.Header>
            <Card.Body>
              {upcomingTasks.length > 0 ? (
                <ListGroup>
                  {upcomingTasks.map((task) => (
                    <ListGroup.Item key={task.id}>
                      <Row>
                        <Col xs={8}>{task.title}</Col>
                        <Col xs={4} className="text-end">
                          Due: {task.targetDate}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <p>No upcoming tasks in the next 7 days.</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal to display tasks */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTasks.length > 0 ? (
            <ListGroup>
              {selectedTasks.map((task) => {
                const targetDate = parseDate(task.targetDate);
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                // Calculate business days overdue
                let businessDaysOverdue = null;
                if (modalTitle === 'Overdue Tasks' && targetDate) {
                  businessDaysOverdue = calculateBusinessDays(
                    targetDate,
                    today
                  );
                }

                // Get unresolved dependencies
                let unresolvedDependencies = [];
                if (task.dependsOn && task.dependsOn.length > 0) {
                  unresolvedDependencies = task.dependsOn
                    .map((dependencyId) => tasks.find((t) => t.id === dependencyId))
                    .filter(
                      (dependencyTask) =>
                        dependencyTask && dependencyTask.status !== 'Done'
                    );
                }

                return (
                  <ListGroup.Item key={task.id}>
                    <strong>{task.title}</strong>
                    <div>Status: {task.status}</div>
                    {task.targetDate && (
                      <div>Due Date: {task.targetDate}</div>
                    )}
                    {businessDaysOverdue !== null && (
                      <div>
                        Overdue by {businessDaysOverdue} business day
                        {businessDaysOverdue !== 1 ? 's' : ''}
                      </div>
                    )}
                    {unresolvedDependencies.length > 0 && (
                      <div>
                        <strong>Unresolved Dependencies:</strong>
                        <ul>
                          {unresolvedDependencies.map((depTask) => (
                            <li key={depTask.id}>{depTask.title}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          ) : (
            <p>No tasks to display.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Dashboard;
