// src/components/ExpensesManager/ExpenseForm.js

import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse } from 'date-fns';
import {
  accountCodeOptions,
  taxCodeOptions,
  paymentMethodOptions,
} from './Config/expenseConfig';

const ExpenseForm = ({
  initialData = {},
  tasks = [],
  phases = [],
  onSubmit,
  isEditMode = false,
}) => {
  const [title, setTitle] = useState(initialData.title || '');
  const [description, setDescription] = useState(initialData.description || '');
  const [amount, setAmount] = useState(initialData.amount || '');

  // Initialize date state as a Date object
  const initialDate = initialData.date
    ? parse(initialData.date, 'dd/MM/yyyy', new Date())
    : new Date();
  const [date, setDate] = useState(initialDate);

  const [status, setStatus] = useState(initialData.status || 'Pending');
  const [linkedTaskId, setLinkedTaskId] = useState(initialData.linkedTaskId || '');
  const [linkedPhaseId, setLinkedPhaseId] = useState(initialData.linkedPhaseId || '');

  // New Fields
  const [accountCode, setAccountCode] = useState(initialData.accountCode || '');
  const [taxCode, setTaxCode] = useState(initialData.taxCode || 'GST');
  const [paymentMethod, setPaymentMethod] = useState(initialData.paymentMethod || '');
  const [vendor, setVendor] = useState(initialData.vendor || '');
  const [referenceNumber, setReferenceNumber] = useState(initialData.referenceNumber || '');
  const [attachment, setAttachment] = useState(null);
  const [attachmentUrl, setAttachmentUrl] = useState(initialData.attachmentUrl || '');
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type and size if necessary
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        setError('Only JPG, PNG, and PDF files are allowed.');
        return;
      }
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError('File size should be less than 5MB.');
        return;
      }
      setError('');
      setAttachment(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    if (!title.trim()) {
      setError('Title is required.');
      return;
    }
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      setError('A valid amount is required.');
      return;
    }
    if (!date) {
      setError('Date is required.');
      return;
    }
    if (!accountCode) {
      setError('Account Code is required.');
      return;
    }
    if (!taxCode) {
      setError('Tax Code is required.');
      return;
    }
    if (!paymentMethod) {
      setError('Payment Method is required.');
      return;
    }
    if (!vendor.trim()) {
      setError('Vendor is required.');
      return;
    }

    // Format date as DD/MM/YYYY
    const formattedDate = format(date, 'dd/MM/yyyy');

    const expenseData = {
      title: title.trim(),
      description: description.trim(),
      amount: parseFloat(amount),
      date: formattedDate, // Save in DD/MM/YYYY format
      status,
      linkedTaskId: linkedTaskId || null,
      linkedPhaseId: linkedPhaseId || null,
      accountCode,
      taxCode,
      paymentMethod,
      vendor: vendor.trim(),
      referenceNumber: referenceNumber.trim() || null,
      // attachmentUrl will be handled separately
    };

    // If editing and there's an existing attachment
    if (isEditMode && attachmentUrl && !attachment) {
      onSubmit(expenseData);
      return;
    }

    // If a new attachment is uploaded
    if (attachment) {
      onSubmit(expenseData, attachment);
    } else {
      onSubmit(expenseData);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Title */}
      <Form.Group controlId="expenseTitle">
        <Form.Label>
          Title <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter expense title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </Form.Group>

      {/* Description */}
      <Form.Group controlId="expenseDescription">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter expense description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Form.Group>

      {/* Amount */}
      <Form.Group controlId="expenseAmount">
        <Form.Label>
          Amount (AUD) <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
          min="0"
          step="0.01"
        />
      </Form.Group>

      {/* Date */}
      <Form.Group controlId="expenseDate">
        <Form.Label>
          Date <span className="text-danger">*</span>
        </Form.Label>
        <DatePicker
          selected={date}
          onChange={(date) => setDate(date)}
          dateFormat="dd/MM/yyyy"
          className="form-control"
          placeholderText="Select a date"
          required
        />
      </Form.Group>

      {/* Status */}
      <Form.Group controlId="expenseStatus">
        <Form.Label>Status</Form.Label>
        <Form.Control
          as="select"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="Pending">Pending</option>
          <option value="Approved">Approved</option>
          <option value="Rejected">Rejected</option>
        </Form.Control>
      </Form.Group>

      {/* Linked Task */}
      <Form.Group controlId="linkedTaskId">
        <Form.Label>Link to Task</Form.Label>
        <Form.Control
          as="select"
          value={linkedTaskId}
          onChange={(e) => setLinkedTaskId(e.target.value)}
        >
          <option value="">Select a Task</option>
          {tasks.map((task) => (
            <option key={task.id} value={task.id}>
              {task.title}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Linked Phase */}
      <Form.Group controlId="linkedPhaseId">
        <Form.Label>Link to Phase</Form.Label>
        <Form.Control
          as="select"
          value={linkedPhaseId}
          onChange={(e) => setLinkedPhaseId(e.target.value)}
        >
          <option value="">Select a Phase</option>
          {phases.map((phase) => (
            <option key={phase.id} value={phase.id}>
              {phase.title}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Account Code */}
      <Form.Group controlId="accountCode">
        <Form.Label>
          Account Code <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          as="select"
          value={accountCode}
          onChange={(e) => setAccountCode(e.target.value)}
          required
        >
          <option value="">Select an Account Code</option>
          {accountCodeOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label} ({option.value})
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Tax Code */}
      <Form.Group controlId="taxCode">
        <Form.Label>
          Tax Code <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          as="select"
          value={taxCode}
          onChange={(e) => setTaxCode(e.target.value)}
          required
        >
          {taxCodeOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Payment Method */}
      <Form.Group controlId="paymentMethod">
        <Form.Label>
          Payment Method <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          as="select"
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
          required
        >
          <option value="">Select a Payment Method</option>
          {paymentMethodOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Vendor */}
      <Form.Group controlId="vendor">
        <Form.Label>
          Vendor <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter vendor name"
          value={vendor}
          onChange={(e) => setVendor(e.target.value)}
          required
        />
      </Form.Group>

      {/* Reference Number */}
      <Form.Group controlId="referenceNumber">
        <Form.Label>Reference Number</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter reference number"
          value={referenceNumber}
          onChange={(e) => setReferenceNumber(e.target.value)}
        />
      </Form.Group>

      {/* Attachment */}
      <Form.Group controlId="attachment">
        <Form.Label>Upload Receipt/Invoice</Form.Label>
        <Form.Control
          type="file"
          accept=".jpg,.jpeg,.png,.pdf"
          onChange={handleFileChange}
        />
        {isEditMode && attachmentUrl && !attachment && (
          <div className="mt-2">
            <a href={attachmentUrl} target="_blank" rel="noopener noreferrer">
              View Existing Attachment
            </a>
          </div>
        )}
      </Form.Group>

      {/* Submit Button */}
      <Button variant="primary" type="submit" className="mt-3">
        {isEditMode ? 'Save Changes' : 'Add Expense'}
      </Button>
    </Form>
  );
};

export default ExpenseForm;
