import React, { useRef, useEffect } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { Spinner, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

import LayersManager from './LayersManager';
import DrawingManagerComponent from './DrawingManagerComponent';
import CustomLayersManager from './CustomLayersManager';

const containerStyle = {
  width: '100%',
  height: '90vh',
};

const center = {
  lat: -27.4698, // Brisbane latitude
  lng: 153.0251, // Brisbane longitude
};

const MapComponent = ({
  isLoaded,
  loadError,
  onLoad,
  selectedLayers,
  availableLayers,
  busStopTypeFilters,
  roadHierarchyTypeFilters,
  setLoadingLayers,
  setLayerErrors,
  transformDataToGeoJSON,
  customLayers,
  setCustomLayers,
  isDrawingMode,
  setIsDrawingMode,
  fetchedDataCache,
  selectedServices,
  serviceRatings,
  serviceSearchTrigger,
}) => {
  const mapRef = useRef(null);
  const serviceMarkersRef = useRef([]);
  const bestAreasRef = useRef([]);

  useEffect(() => {
    if (
      serviceSearchTrigger > 0 &&
      selectedServices.length > 0 &&
      mapRef.current
    ) {
      // Perform the search and computation
      const map = mapRef.current;
      const bounds = map.getBounds();

      const service = new window.google.maps.places.PlacesService(map);
      const promises = [];

      // Clear previous markers and areas
      serviceMarkersRef.current.forEach((markerObj) => markerObj.marker.setMap(null));
      serviceMarkersRef.current = [];
      bestAreasRef.current.forEach((circle) => circle.setMap(null));
      bestAreasRef.current = [];

      // Fetch places for each selected service
      selectedServices.forEach((serviceType) => {
        const minRating = serviceRatings[serviceType] || 0;
        const request = {
          bounds: bounds,
          keyword: serviceType,
        };

        promises.push(
          new Promise((resolve) => {
            service.nearbySearch(request, (results, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                // Filter results based on minimum rating
                const filteredResults = results.filter(
                  (place) => (place.rating || 0) >= minRating
                );
                resolve({ serviceType, results: filteredResults });
              } else {
                resolve({ serviceType, results: [] });
              }
            });
          })
        );
      });

      Promise.all(promises).then((servicesResults) => {
        // Create a map of service type to their locations
        const serviceTypeLocations = {};
        servicesResults.forEach(({ serviceType, results }) => {
          serviceTypeLocations[serviceType] = results.map((place) => {
            const marker = new window.google.maps.Marker({
              map: map,
              position: place.geometry.location,
              title: place.name,
            });

            const infoWindow = new window.google.maps.InfoWindow({
              content: `
                <div>
                  <strong>${place.name}</strong><br/>
                  Rating: ${place.rating || 'N/A'}<br/>
                  Address: ${place.vicinity || place.formatted_address || 'N/A'}
                </div>
              `,
            });

            marker.addListener('click', () => {
              infoWindow.open(map, marker);
            });

            serviceMarkersRef.current.push({ marker, infoWindow });

            return place.geometry.location;
          });
        });

        // Compute the best areas
        const bestAreas = computeBestAreas(serviceTypeLocations, 6);

        // Display the best areas on the map
        bestAreas.forEach((area) => {
          const circle = new window.google.maps.Circle({
            map: map,
            center: area.center,
            radius: area.radius,
            fillColor: '#FF0000',
            fillOpacity: 0.2,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
          });
          bestAreasRef.current.push(circle);
        });

        if (bestAreas.length > 0) {
          // Adjust the map to show all areas
          const combinedBounds = new window.google.maps.LatLngBounds();
          bestAreas.forEach((area) => {
            combinedBounds.union(area.circleBounds);
          });
          map.fitBounds(combinedBounds);
        } else {
          alert(
            'No areas found where all selected services are in close proximity.'
          );
        }
      });
    }
  }, [serviceSearchTrigger, selectedServices, serviceRatings]);

  // Function to compute the best areas
  const computeBestAreas = (serviceTypeLocations, topN) => {
    const serviceTypes = Object.keys(serviceTypeLocations);

    // If any service type has no locations, we cannot find areas
    if (serviceTypes.some((type) => serviceTypeLocations[type].length === 0)) {
      return [];
    }

    // Limit the number of locations per service type to reduce computation
    const MAX_LOCATIONS_PER_SERVICE = 10;
    serviceTypes.forEach((type) => {
      serviceTypeLocations[type] = serviceTypeLocations[type].slice(
        0,
        MAX_LOCATIONS_PER_SERVICE
      );
    });

    // Generate all combinations of one location per service type
    const combinations = cartesianProduct(
      Object.values(serviceTypeLocations)
    );

    // Compute total distance for each combination
    const areas = combinations.map((locations) => {
      const center = computeGeometricMedian(locations);
      const maxDistance = Math.max(
        ...locations.map((loc) =>
          window.google.maps.geometry.spherical.computeDistanceBetween(
            center,
            loc
          )
        )
      );
      return {
        locations,
        center,
        radius: maxDistance,
        totalDistance: computeTotalDistance(locations),
      };
    });

    // Sort areas by total distance (ascending)
    areas.sort((a, b) => a.totalDistance - b.totalDistance);

    // Take the top N areas
    const topAreas = areas.slice(0, topN);

    // Add circle bounds for adjusting the map view
    topAreas.forEach((area) => {
      const circle = new window.google.maps.Circle({
        center: area.center,
        radius: area.radius,
      });
      area.circleBounds = circle.getBounds();
    });

    return topAreas;
  };

  // Helper function to compute the cartesian product of arrays
  const cartesianProduct = (arrays) => {
    return arrays.reduce(
      (acc, curr) => acc.flatMap((a) => curr.map((b) => [...a, b])),
      [[]]
    );
  };

  // Helper function to compute total distance between all points
  const computeTotalDistance = (locations) => {
    let totalDistance = 0;
    for (let i = 0; i < locations.length; i++) {
      for (let j = i + 1; j < locations.length; j++) {
        totalDistance +=
          window.google.maps.geometry.spherical.computeDistanceBetween(
            locations[i],
            locations[j]
          );
      }
    }
    return totalDistance;
  };

  // Helper function to compute the geometric median (using average as approximation)
  const computeGeometricMedian = (locations) => {
    const avgLat =
      locations.reduce((sum, loc) => sum + loc.lat(), 0) / locations.length;
    const avgLng =
      locations.reduce((sum, loc) => sum + loc.lng(), 0) / locations.length;
    return new window.google.maps.LatLng(avgLat, avgLng);
  };

  if (loadError) {
    console.error('Error loading Google Maps script:', loadError);
    return (
      <Alert variant="danger">
        Error loading Google Maps. Please try again later.
      </Alert>
    );
  }

  return (
    <>
      {!isLoaded ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '600px' }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
          onLoad={(map) => {
            mapRef.current = map;
            onLoad(map);
          }}
        >
          {/* LayersManager handles predefined layers */}
          <LayersManager
            mapRef={mapRef}
            isLoaded={isLoaded}
            selectedLayers={selectedLayers}
            availableLayers={availableLayers}
            busStopTypeFilters={busStopTypeFilters}
            roadHierarchyTypeFilters={roadHierarchyTypeFilters}
            setLoadingLayers={setLoadingLayers}
            setLayerErrors={setLayerErrors}
            transformDataToGeoJSON={transformDataToGeoJSON}
            fetchedDataCache={fetchedDataCache}
          />

          {/* DrawingManagerComponent handles drawing custom overlays */}
          <DrawingManagerComponent
            mapRef={mapRef}
            isDrawingMode={isDrawingMode}
            setIsDrawingMode={setIsDrawingMode}
            customLayers={customLayers}
            setCustomLayers={setCustomLayers}
          />

          {/* CustomLayersManager manages custom layers */}
          <CustomLayersManager
            mapRef={mapRef}
            customLayers={customLayers}
            setCustomLayers={setCustomLayers}
          />
        </GoogleMap>
      )}
    </>
  );
};

MapComponent.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  loadError: PropTypes.object,
  onLoad: PropTypes.func.isRequired,
  selectedLayers: PropTypes.object.isRequired,
  availableLayers: PropTypes.object.isRequired,
  busStopTypeFilters: PropTypes.array.isRequired,
  roadHierarchyTypeFilters: PropTypes.array.isRequired,
  setLoadingLayers: PropTypes.func.isRequired,
  setLayerErrors: PropTypes.func.isRequired,
  transformDataToGeoJSON: PropTypes.func.isRequired,
  customLayers: PropTypes.array.isRequired,
  setCustomLayers: PropTypes.func.isRequired,
  isDrawingMode: PropTypes.bool.isRequired,
  setIsDrawingMode: PropTypes.func.isRequired,
  fetchedDataCache: PropTypes.object.isRequired,
  selectedServices: PropTypes.array.isRequired,
  serviceRatings: PropTypes.object.isRequired,
  serviceSearchTrigger: PropTypes.number.isRequired,
};

export default React.memo(MapComponent);
