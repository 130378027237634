// src/components/ProjectBacklog/ProjectBacklog.js

import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Button, Spinner, Dropdown, Form } from 'react-bootstrap';
import {
  collection,
  addDoc,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
  writeBatch,
} from 'firebase/firestore';
import { firestore } from '../../../../firebase';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Toolbar,
  ColumnMenu,
  Resize,
  Reorder,
  RowDD,
  Inject,
  Edit,
} from '@syncfusion/ej2-react-grids';
import AddTaskModal from './AddTaskModal';
import EditTaskModal from './EditTaskModal';
import DeleteTaskModal from './DeleteTaskModal';
import { AuthContext } from '../../../../context/auth/AuthContext';
import { fetchProjectUserDetails } from '../../ProjectUtils/fetchUsers';
import { hasEditAccess } from '../../ProjectUtils/permissions';
import '@syncfusion/ej2-react-grids/styles/material.css';
import { FaRegCircle, FaLayerGroup, FaEdit, FaTrash } from 'react-icons/fa';

const ProjectBacklog = ({ project }) => {
  const { currentUser } = useContext(AuthContext);
  const [tasks, setTasks] = useState([]);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [userPermission, setUserPermission] = useState('Read');
  const [projectUsers, setProjectUsers] = useState([]);
  const [tasksLoading, setTasksLoading] = useState(true);
  const [usersLoading, setUsersLoading] = useState(true);
  const [filteredPhase, setFilteredPhase] = useState(null);

  // Function to parse 'DD/MM/YYYY' to Date object
  const parseDate = (dateStr) => {
    if (!dateStr) return null;
    const [day, month, year] = dateStr.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return isNaN(date.getTime()) ? null : date;
  };

  // Function to format Date object to 'DD/MM/YYYY'
  const formatDate = (dateObj) => {
    if (!dateObj) return '';
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Fetch project user details and determine permissions
  useEffect(() => {
    if (project && currentUser) {
      const userEntry = project.users.find((user) => user.uid === currentUser.uid);
      const permission = userEntry ? userEntry.permission : 'Read';
      setUserPermission(permission);

      fetchProjectUserDetails(project.users)
        .then((users) => {
          setProjectUsers(users);
          console.log('Project Users Fetched:', users);
          setUsersLoading(false); // Set users loading to false
        })
        .catch((error) => {
          console.error('Error fetching project users:', error);
          setUsersLoading(false); // Set users loading to false even on error
        });
    }
  }, [project, currentUser]);

  // Fetch tasks from Firestore
  useEffect(() => {
    if (project) {
      const unsubscribe = onSnapshot(
        collection(firestore, `projects/${project.id}/tasks`),
        (snapshot) => {
          let fetchedTasks = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              // Convert date strings to Date objects
              startDate: parseDate(data.startDate),
              targetDate: parseDate(data.targetDate),
            };
          });

          // Sort tasks based on priority
          fetchedTasks.sort((a, b) => a.priority - b.priority);

          // If a phase is selected, filter tasks by that phase
          if (filteredPhase) {
            fetchedTasks = fetchedTasks.filter((task) => task.phaseId === filteredPhase);
          }

          setTasks(fetchedTasks);
          console.log('Tasks Fetched:', fetchedTasks);
          setTasksLoading(false); // Set tasks loading to false
        },
        (error) => {
          console.error('Error fetching tasks:', error);
          setTasksLoading(false); // Set tasks loading to false even on error
        }
      );
      return () => unsubscribe();
    }
  }, [project, filteredPhase]);

  // Add task handler
  const handleAddTask = async (newTask) => {
    if (!hasEditAccess(userPermission)) {
      alert('You do not have permission to add tasks.');
      return;
    }

    try {
      // Determine the next priority value
      const maxPriority = tasks.reduce((max, task) => (task.priority > max ? task.priority : max), 0);
      await addDoc(collection(firestore, `projects/${project.id}/tasks`), {
        ...newTask,
        priority: maxPriority + 1, // Assign next priority
      });
      setShowAddTaskModal(false);
    } catch (error) {
      console.error('Error adding task:', error);
      alert('Failed to add task.');
    }
  };

  // Edit task handler
  const handleEditTask = async (editedTask) => {
    if (!hasEditAccess(userPermission)) {
      alert('You do not have permission to edit tasks.');
      return;
    }

    try {
      await updateDoc(doc(firestore, `projects/${project.id}/tasks`, editedTask.id), editedTask);
      setShowEditTaskModal(false);
    } catch (error) {
      console.error('Error editing task:', error);
      alert('Failed to edit task.');
    }
  };

  const handleOpenEditTaskModal = (task) => {
    setSelectedTask(task);
    setShowEditTaskModal(true);
  };

  // Handle task deletion
  const handleOpenDeleteModal = (task) => {
    setSelectedTask(task);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!hasEditAccess(userPermission)) {
      alert('You do not have permission to delete tasks.');
      return;
    }

    try {
      // If deleting a phase, update linked tasks
      if (selectedTask && selectedTask.taskType === 'Phase') {
        const linkedTasks = tasks.filter((task) => task.phaseId === selectedTask.id);
        await Promise.all(
          linkedTasks.map(async (task) => {
            await updateDoc(doc(firestore, `projects/${project.id}/tasks`, task.id), {
              phaseId: null,
            });
          })
        );
      }

      // Delete the selected task
      await deleteDoc(doc(firestore, `projects/${project.id}/tasks`, selectedTask.id));
      setShowDeleteModal(false);
      setSelectedTask(null);
    } catch (error) {
      console.error('Error deleting task:', error);
      alert('Failed to delete task.');
    }
  };

  // Memoize grid columns to prevent unnecessary re-renders
  const gridColumns = useMemo(
    () => [
      {
        field: 'taskType',
        headerText: 'Task Type',
        width: '120',
        textAlign: 'Center',
        allowEditing: false,
        template: (data) => (
          <div className="d-flex align-items-center">
            {data.taskType === 'Phase' ? (
              <FaLayerGroup className="me-2 text-primary" />
            ) : (
              <FaRegCircle className="me-2 text-success" />
            )}
            <span>{data.taskType}</span>
          </div>
        ),
      },
      {
        field: 'title',
        headerText: 'Title',
        width: '200',
        textAlign: 'Left',
        editType: 'stringedit',
      },
      {
        field: 'description',
        headerText: 'Description',
        width: '250',
        textAlign: 'Left',
        editType: 'stringedit',
      },
      {
        field: 'startDate',
        headerText: 'Start Date',
        width: '130',
        textAlign: 'Center',
        type: 'date',
        format: 'dd/MM/yyyy',
        editType: 'datepickeredit',
        template: (data) => formatDate(data.startDate),
      },
      {
        field: 'targetDate',
        headerText: 'Target Date',
        width: '130',
        textAlign: 'Center',
        type: 'date',
        format: 'dd/MM/yyyy',
        editType: 'datepickeredit',
        template: (data) => formatDate(data.targetDate),
      },
      {
        field: 'status',
        headerText: 'Status',
        width: '120',
        textAlign: 'Center',
        editType: 'dropdownedit',
        allowEditing: false,
        edit: {
          params: {
            dataSource: [
              { StatusText: 'To Do', StatusId: 'To Do' },
              { StatusText: 'In Progress', StatusId: 'In Progress' },
              { StatusText: 'Done', StatusId: 'Done' },
              { StatusText: 'Cancelled', StatusId: 'Cancelled' },
            ],
            fields: { text: 'StatusText', value: 'StatusId' },
          },
        },
      },
      {
        field: 'priority',
        headerText: 'Priority',
        width: '100',
        textAlign: 'Center',
        allowEditing: false,
      },
      {
        field: 'assignee',
        headerText: 'Assignee',
        width: '150',
        textAlign: 'Left',
        allowEditing: false,
        template: (data) => {
          const user = projectUsers.find((u) => u.uid === data.assignee);
          return user ? user.name : 'None';
        },
        editType: 'dropdownedit',
        edit: {
          params: {
            dataSource:
              projectUsers && projectUsers.length > 0
                ? projectUsers.map((user) => ({
                    AssigneeText: `${user.name} (${user.email})`,
                    AssigneeId: user.uid,
                  }))
                : [{ AssigneeText: 'None', AssigneeId: '' }],
            fields: { text: 'AssigneeText', value: 'AssigneeId' },
          },
        },
      },
      {
        field: 'dependsOn',
        headerText: 'Depends On',
        width: '150',
        textAlign: 'Left',
        template: (data) =>
          Array.isArray(data.dependsOn) && data.dependsOn.length > 0
            ? data.dependsOn.join(', ')
            : 'None',
        allowEditing: false,
      },
      {
        headerText: 'Actions',
        width: '150',
        textAlign: 'Center',
        allowEditing: false,
        template: (data) => (
          <div className="d-flex justify-content-center">
            <FaEdit
              className="me-3 text-primary"
              style={{ cursor: 'pointer' }}
              onClick={() => handleOpenEditTaskModal(data)}
              aria-label={`Edit ${data.title}`}
              title="Edit Task"
            />
            <FaTrash
              className="text-danger"
              style={{ cursor: 'pointer' }}
              onClick={() => handleOpenDeleteModal(data)}
              aria-label={`Delete ${data.title}`}
              title="Delete Task"
            />
          </div>
        ),
        allowFiltering: false,
        allowSorting: false,
      },
    ],
    [projectUsers]
  );

  // Toolbar options including Column Chooser and Refresh
  const toolbarOptions = ['Search', 'ColumnChooser', 'Refresh'];

  // Page settings
  const pageSettings = { pageSize: 10 };

  // Handle row drag and drop
  const handleRowDragStop = async (args) => {
    const draggedRowIndex = args.row.getAttribute('aria-rowindex') - 1;
    const droppedRowIndex = args.dropIndex;

    if (draggedRowIndex === droppedRowIndex) return;

    const updatedTasks = [...tasks];
    const [movedTask] = updatedTasks.splice(draggedRowIndex, 1);
    updatedTasks.splice(droppedRowIndex, 0, movedTask);

    // Update priority based on new order
    const batch = writeBatch(firestore);
    updatedTasks.forEach((task, index) => {
      const taskRef = doc(firestore, `projects/${project.id}/tasks`, task.id);
      batch.update(taskRef, { priority: index + 1 });
      task.priority = index + 1; // Update local state
    });

    try {
      await batch.commit();
      setTasks(updatedTasks);
    } catch (error) {
      console.error('Error updating task priorities:', error);
      alert('Failed to update task order.');
    }
  };

  // Handle inline editing actions
  const handleActionBegin = async (args) => {
    if (args.requestType === 'save') {
      const editedTask = args.data;
      try {
        await updateDoc(doc(firestore, `projects/${project.id}/tasks`, editedTask.id), {
          title: editedTask.title,
          description: editedTask.description,
          startDate: formatDate(new Date(editedTask.startDate)),
          targetDate: formatDate(new Date(editedTask.targetDate)),
          status: editedTask.status,
          assignee: editedTask.assignee,
        });
      } catch (error) {
        console.error('Error saving edited task:', error);
        alert('Failed to save changes.');
      }
    }
  };

  // Handle "Sort by Phase" selection
  const handlePhaseSelect = (phaseId) => {
    setFilteredPhase(phaseId);
  };

  // Get unique phases for the dropdown
  const uniquePhases = useMemo(() => {
    return Array.from(
      new Set(
        tasks
          .filter((task) => task.taskType === 'Phase')
          .map((phase) => ({ id: phase.id, title: phase.title }))
      )
    );
  }, [tasks]);

  if (tasksLoading || usersLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '500px' }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading tasks...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div>
      {/* Add Task and Sort by Phase Buttons */}
      <div className="d-flex justify-content-between mb-3">
        <Button onClick={() => setShowAddTaskModal(true)} disabled={!hasEditAccess(userPermission)}>
          Add Task
        </Button>

        {/* Sort by Phase Dropdown */}
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-sort-phase">
            Sort by Phase
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <Form.Control
              type="text"
              placeholder="Search Phase"
              className="m-2"
              onChange={(e) => {
                const search = e.target.value.toLowerCase();
                const filtered = uniquePhases.filter((phase) =>
                  phase.title.toLowerCase().includes(search)
                );
                // If exactly one phase matches the search, select it
                if (filtered.length === 1) {
                  setFilteredPhase(filtered[0].id);
                }
              }}
            />
            {uniquePhases.map((phase) => (
              <Dropdown.Item
                key={phase.id}
                onClick={() => handlePhaseSelect(phase.id)}
                active={filteredPhase === phase.id}
              >
                {phase.title}
              </Dropdown.Item>
            ))}
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => setFilteredPhase(null)}>Clear Filter</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {/* Syncfusion DataGrid Component */}
      <GridComponent
        dataSource={tasks}
        toolbar={toolbarOptions}
        editSettings={{
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: 'Normal',
        }}
        actionBegin={handleActionBegin}
        allowPaging={true}
        pageSettings={pageSettings}
        allowSorting={true}
        allowFiltering={false}
        allowResizing={true}
        allowRowDragAndDrop={true}
        rowDragStop={handleRowDragStop}
        allowColumnReordering={true}
        allowColumnResizing={true}
        allowExcelExport={true}
        allowPdfExport={false}
        allowTextWrap={true}
        allowSelection={true}
        allowVirtualization={false}
        height={500}
        columnMenu={true}
        showColumnChooser={true}
        allowColumnChooser={true}
        allowReordering={true}
      >
        <ColumnsDirective>
          {gridColumns.map((col, index) => (
            <ColumnDirective key={index} {...col} />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Sort,
            Toolbar,
            ColumnMenu,
            Resize,
            Reorder,
            RowDD,
            Edit,
          ]}
        />
      </GridComponent>

      {/* Add Task Modal */}
      <AddTaskModal
        show={showAddTaskModal}
        onHide={() => setShowAddTaskModal(false)}
        onAddTask={handleAddTask}
        tasks={tasks}
        projectUsers={projectUsers}
      />

      {/* Edit Task Modal */}
      <EditTaskModal
        show={showEditTaskModal}
        onHide={() => setShowEditTaskModal(false)}
        onEditTask={handleEditTask}
        selectedTask={selectedTask}
        tasks={tasks}
        projectUsers={projectUsers}
      />

      {/* Delete Task Modal */}
      <DeleteTaskModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={handleConfirmDelete}
        selectedTask={selectedTask}
        project={project}
        tasks={tasks}
      />
    </div>
  );
};

export default ProjectBacklog;
