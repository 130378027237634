// TimelineToolbar.js
import React from 'react';
import { Button } from 'react-bootstrap';

const TimelineToolbar = ({ handleTimelineChange }) => {
  return (
    <div className="toolbar-container">
      <Button className="toolbar-btn" onClick={() => handleTimelineChange('Week')}>
        Week View
      </Button>
      <Button className="toolbar-btn" onClick={() => handleTimelineChange('Month')}>
        Month View
      </Button>
      <Button className="toolbar-btn" onClick={() => handleTimelineChange('Year')}>
        Year View
      </Button>
    </div>
  );
};

export default TimelineToolbar;
