import React, { useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, Button, NavDropdown, Container, Row, Col } from 'react-bootstrap';
import { AuthContext } from '../context/auth/AuthContext';
import FounderClubStatus from '../pages/Payments/SubscriptionStatusDisplay/FounderClubStatus';
import FeedbackForm from './FeedbackForm'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './HeaderFooter.css';

const Header = () => {
  const { currentUser, subscriptions, logout } = useContext(AuthContext) || {};
  const [expanded, setExpanded] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false); // State to control feedback form visibility
  const location = useLocation(); // Get the current location

  const isAuthenticated = !!currentUser;

  const proPlanId = "price_1PJuh5F1Sd4Db5LgYAyuMizE"; 
  const platinumPlanId = "price_1PJujlF1Sd4Db5LgS9jwMyw0"; 

  const hasProSubscription = subscriptions && subscriptions.some(sub => sub.plan_id === proPlanId);
  const hasPlatinumSubscription = subscriptions && subscriptions.some(sub => sub.plan_id === platinumPlanId);

  const handleNavClick = () => {
    setExpanded(false); 
  };

  const handleFeedbackClose = () => setShowFeedback(false);
  const handleFeedbackShow = () => setShowFeedback(true);

  // Check if the current path is the LandingPage
  const isLandingPage = location.pathname === '/';

  return (
    <>
      <Navbar className="custom-navbar" variant="dark" expand="lg" expanded={expanded}>
        <Container fluid>
          <Navbar.Brand as={Link} to="/" className="header-brand">
            <img src={`${process.env.PUBLIC_URL}/assets/Lunar8-Property-Development-Logo2.png`} alt="Banner" className="banner-image" />
          </Navbar.Brand>
          
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Row className="w-100">
            {/* <Col xs={12} lg={2} className="text-lg-left text-center mb-2 mb-lg-0">
              {!isAuthenticated && (
                <Button as={Link} to="/projects-home" variant="outline-light" className="" onClick={handleNavClick}>Post Jobs</Button>
              )}
            </Col> */}
              {/* <Col xs={12} lg={10} className="d-flex justify-content-lg-end justify-content-center"></Col> */}
              <Col xs={12} lg={12} className="d-flex justify-content-lg-end justify-content-center">
                <Nav className="w-100 justify-content-lg-end justify-content-center">
                  {isAuthenticated ? (
                    <>
                      {/* <Nav.Link as={Link} to="/find-services" onClick={handleNavClick}>Find Services</Nav.Link> */}
                      {/* <Nav.Link as={Link} to="/jobs" onClick={handleNavClick}>Manage Jobs</Nav.Link> */}
                      <Nav.Link as={Link} to="/projects-dashboard" onClick={handleNavClick}>Projects Dashboard</Nav.Link>
                      {/* <Nav.Link as={Link} to="/budget-dashboard" onClick={handleNavClick}>Budgets Dashboard</Nav.Link> */}
                      <Nav.Link as={Link} to="/billing-management" onClick={handleNavClick}>Subscriptions</Nav.Link>
                      <Nav.Link as={Link} to="/hunting-ground" onClick={handleNavClick}>Hunting Ground</Nav.Link>
                      {(hasPlatinumSubscription || hasProSubscription) && (
                        <>
                          <Nav.Link as={Link} to="/company-service" onClick={handleNavClick}>My Services</Nav.Link>
                          <Nav.Link as={Link} to="/billing-management" onClick={handleNavClick}>Subscriptions</Nav.Link>
                        </>
                      )}
                      <NavDropdown title="Account" id="account-dropdown" className="custom-dropdown">
                        <NavDropdown.Item as={Link} to="/user-profile" onClick={handleNavClick}>User Profile</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/billing-management" onClick={handleNavClick}>
                          <FounderClubStatus />
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={() => { logout(); handleNavClick(); }}>Logout</NavDropdown.Item>
                        {/* Conditionally render the Submit Feedback button if not on the LandingPage */}
                        {!isLandingPage && (
                          <>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={handleFeedbackShow}>
                              Submit Feedback
                            </NavDropdown.Item>
                          </>
                        )}
                      </NavDropdown>
                    </>
                  ) : (
                    <>
                      <Button as={Link} to="/login" variant="outline-light" className="" onClick={handleNavClick}>Login</Button>
                      <Button as={Link} to="/signup" variant="outline-light" className="margin-right-10" onClick={handleNavClick}>Sign Up</Button>
                      {/* <Button as={Link} to="/trades-home" variant="outline-light" className="" onClick={handleNavClick}>Find Jobs</Button> */}
                    </>
                  )}
                </Nav>
              </Col>
            </Row>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Render FeedbackForm as a modal */}
      <FeedbackForm show={showFeedback} handleClose={handleFeedbackClose} page={location.pathname} />
    </>
  );
};

export default Header;
