import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { EnvelopeFill, Facebook, Instagram } from 'react-bootstrap-icons';
import './HeaderFooter.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer bg-dark text-white py-4">
      <div className="container">
        <div className="row justify-content-between text-center align-items-center">
          <div className="col-md-4 text-md-left mb-3 mb-md-0">
            <a href="mailto:info@lunar8.com.au" className="text-white me-3 footer-link">
              <EnvelopeFill size={20} /> info@lunar8.com.au
            </a>
          </div>
          <div className="col-md-4 text-center mb-3 mb-md-0">
            <a href="https://www.facebook.com/lunar8propertygroup" target="_blank" rel="noopener noreferrer" className="text-white me-3 footer-link">
              <Facebook size={20} />
            </a>
            <a href="https://www.instagram.com/lunar8propertygroup" target="_blank" rel="noopener noreferrer" className="text-white footer-link">
              <Instagram size={20} />
            </a>
          </div>
          <div className="col-md-4 text-center text-md-right">
            © {currentYear} Lunar8
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
