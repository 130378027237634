import React, { useState, useEffect, useCallback } from 'react';
import { Form, ListGroup } from 'react-bootstrap';

const AddressSuggestions = ({ setAddress, subtitle}) => { 
  const [input, setInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [lastFetchedTime, setLastFetchedTime] = useState(0);

  const fetchSuggestions = useCallback(async (inputText) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const url = 'https://places.googleapis.com/v1/places:autocomplete';

    const requestBody = {
      input: inputText,
      locationBias: {
        circle: {
          center: {
            latitude: -27.4698, // Example latitude for Brisbane
            longitude: 153.0251, // Example longitude for Brisbane
          },
          radius: 5000, // 5km radius
        },
      },
      languageCode: "en",
      includedRegionCodes: ["au"],
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': apiKey,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      setSuggestions(data.suggestions);
      console.log('Suggestions:', data.suggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  }, []);

  useEffect(() => {
    const currentTime = Date.now();

    if (input.length >= 5 && (currentTime - lastFetchedTime >= 4000)) {
      fetchSuggestions(input);
      setLastFetchedTime(currentTime);
    } else if (input.length < 5) {
      setSuggestions([]);
    }
  }, [input, lastFetchedTime, fetchSuggestions]);

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSelectSuggestion = (suggestion) => {
    const selectedAddress = suggestion.placePrediction?.text.text || suggestion.queryPrediction?.text.text;
    setInput(selectedAddress);
    setAddress(selectedAddress); // Set the suburb in the parent component
    setSuggestions([]);
  };

  return (
    <div>
      <Form.Group controlId="addressInput">
        <Form.Control
          type="text"
          value={input}
          onChange={handleChange}
          placeholder="Enter a suburb or address..."
        />
      </Form.Group>
      {subtitle && <p>{subtitle}</p>}
      <ListGroup>
        {suggestions.map((suggestion, index) => (
          <ListGroup.Item
            key={index}
            onClick={() => handleSelectSuggestion(suggestion)}
            style={{ cursor: 'pointer' }}
          >
            {suggestion.placePrediction?.text.text || suggestion.queryPrediction?.text.text}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default AddressSuggestions;
