// src/components/ProjectsManager.js

import React, { useState, useContext, useEffect } from 'react';
import {
  collection,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';
import { firestore } from '../../firebase';
import { AuthContext } from '../../context/auth/AuthContext';
import { PRO_PLAN_ID, FOUNDERS_CLUB_PLAN_ID } from '../../context/subscriptions/subscriptionPlans';
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  ListGroup,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { FaPlus, FaSearch } from 'react-icons/fa';
import './ProjectsManager.css'; // For custom styling

// Import templates
import propertySubdivisionQLD from './templates/propertySubdivisionQLD.json';

// Import the CreateProjectModal component
import CreateProjectModal from './ProjectsManager/CreateProjectModal';

// Import permission utilities
import {
  hasAdminAccess,
  hasEditAccess,
  hasReadAccess,
} from './ProjectUtils/permissions';

const ProjectsManager = ({ onSelectProject }) => {
  const { currentUser, subscriptions } = useContext(AuthContext) || {};
  const [showModal, setShowModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Determine subscription flags using imported plan IDs
  const hasProSubscription = subscriptions?.some(sub => sub.plan_id === PRO_PLAN_ID);
  const hasFoundersClubSubscription = subscriptions?.some(sub => sub.plan_id === FOUNDERS_CLUB_PLAN_ID);

  // Determine if user can create a new project
  const canCreateNewProject =
    hasProSubscription ||
    hasFoundersClubSubscription ||
    (!hasProSubscription && !hasFoundersClubSubscription && projects.length < 1);

  // Templates array
  const templates = [
    {
      id: 'propertySubdivisionQLD',
      name: 'Property Subdivision',
      data: propertySubdivisionQLD,
    },
    // Add more templates here
  ];

  useEffect(() => {
    if (!currentUser) {
      setProjects([]);
      return;
    }

    const projectsQuery = query(
      collection(firestore, 'projects'),
      where('userIds', 'array-contains', currentUser.uid)
    );

    // Set up the real-time listener
    const unsubscribe = onSnapshot(
      projectsQuery,
      (querySnapshot) => {
        const fetchedProjects = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          // Find the current user's permission in this project
          const userEntry = data.users.find(user => user.uid === currentUser.uid);
          const userPermission = userEntry ? userEntry.permission : 'Read'; // Default to 'Read' if not found

          return {
            ...data,
            id: doc.id,
            userPermission, // Add userPermission to each project
          };
        });
        setProjects(fetchedProjects);
      },
      (error) => {
        console.error('Error fetching projects: ', error);
      }
    );

    // Cleanup listener on unmount or when currentUser changes
    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter projects based on search term
  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Determine the tooltip message based on subscription and project count
  const getTooltipMessage = () => {
    if (hasProSubscription || hasFoundersClubSubscription) {
      return 'Add a new project.';
    }
    if (projects.length >= 1) {
      return 'You can only create one project. Upgrade your subscription to create more.';
    }
    return 'Add a subscription to create a new project.';
  };

  return (
    <Container fluid className="projects-manager">
      <Row className="align-items-center my-4">
        <Col xs={12} md={12}>
          <h3>My Projects</h3>
        </Col>
        <Col xs={12} md={12} className="mt-3">
          {canCreateNewProject ? (
            <Button variant="primary" onClick={handleModalShow}>
              <FaPlus className="me-2" /> Create New Project
            </Button>
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-disabled">
                  {getTooltipMessage()}
                </Tooltip>
              }
            >
              <span className="d-inline-block">
                <Button variant="primary" disabled style={{ pointerEvents: 'none' }}>
                  <FaPlus className="me-2" /> Create New Project
                </Button>
              </span>
            </OverlayTrigger>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12} md={12}>
          <InputGroup>
            <Form.Control
              placeholder="Search Projects"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <InputGroup.Text className="search-height">
              <FaSearch />
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <ListGroup>
            {filteredProjects.map((project) => (
              <ListGroup.Item
                key={project.id}
                action
                onClick={() => onSelectProject(project)}
                className="project-list-item"
                aria-label={`Select project ${project.name}`}
              >
                {project.name}
              </ListGroup.Item>
            ))}
            {filteredProjects.length === 0 && (
              <ListGroup.Item>No projects found.</ListGroup.Item>
            )}
          </ListGroup>
        </Col>
      </Row>

      {/* Reusable CreateProjectModal */}
      <CreateProjectModal
        show={showModal}
        handleClose={handleModalClose}
      />
    </Container>
  );
};

export default ProjectsManager;
