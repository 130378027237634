// src/components/ProjectBacklog/TaskForm.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  InputGroup,
  FormControl,
  ListGroup,
  Button,
} from 'react-bootstrap';

/**
 * Reusable form component for adding and editing tasks/phases.
 */
const TaskForm = ({
  initialData,
  tasks,
  projectUsers,
  onSubmit,
  isEditMode = false,
}) => {
  const [taskType, setTaskType] = useState(initialData.taskType || 'Task');
  const [title, setTitle] = useState(initialData.title || '');
  const [description, setDescription] = useState(initialData.description || '');

  // Ensure proper date formatting
  const [startDate, setStartDate] = useState(formatDateForInput(initialData.startDate) || '');
  const [targetDate, setTargetDate] = useState(formatDateForInput(initialData.targetDate) || '');

  const [status, setStatus] = useState(initialData.status || 'To Do');
  const [phaseId, setPhaseId] = useState(initialData.phaseId || '');
  const [dependsOn, setDependsOn] = useState(initialData.dependsOn || []);
  const [assignee, setAssignee] = useState(initialData.assignee || '');
  const [priority, setPriority] = useState(initialData.priority || 'Medium');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTasks, setFilteredTasks] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const lowerQuery = searchQuery.toLowerCase();
      const filtered = tasks.filter(
        (task) =>
          task.title.toLowerCase().includes(lowerQuery) &&
          !dependsOn.includes(task.id) &&
          task.id !== initialData.id // Exclude current task in edit mode
      );
      setFilteredTasks(filtered);
    } else {
      setFilteredTasks([]);
    }
  }, [searchQuery, tasks, dependsOn, initialData.id]);

  const handleAddDependency = (taskId) => {
    setDependsOn([...dependsOn, taskId]);
    setSearchQuery('');
  };

  const handleRemoveDependency = (taskId) => {
    setDependsOn(dependsOn.filter((id) => id !== taskId));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    if (!title.trim()) {
      alert('Title is required.');
      return;
    }

    const formattedData = {
      taskType,
      title: title.trim(),
      description: description.trim(),
      startDate: convertToDDMMYYYY(startDate),
      targetDate: convertToDDMMYYYY(targetDate),
      status,
      phaseId: phaseId || null,
      dependsOn,
      assignee: assignee || null,
      priority,
    };

    onSubmit(formattedData);
  };

  // Helper function to format date for input fields ('YYYY-MM-DD')
  function formatDateForInput(date) {
    if (!date) return '';
    if (typeof date === 'string') {
      if (date.includes('-')) return date; // 'YYYY-MM-DD'
      const [day, month, year] = date.split('/');
      if (year && month && day) {
        return `${year}-${month}-${day}`;
      }
      return '';
    }
    if (date instanceof Date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    return '';
  }

  // Helper function to convert 'YYYY-MM-DD' to 'DD/MM/YYYY'
  function convertToDDMMYYYY(dateStr) {
    if (!dateStr) return '';
    const [year, month, day] = dateStr.split('-');
    return `${day}/${month}/${year}`;
  }

  return (
    <Form onSubmit={handleSubmit}>
      {/* Task Type */}
      <Form.Group controlId="taskType">
        <Form.Label>Item Type</Form.Label>
        <Form.Control
          as="select"
          value={taskType}
          onChange={(e) => setTaskType(e.target.value)}
          disabled={isEditMode} // Prevent changing task type in edit mode
          aria-label="Task Type Selection"
        >
          <option value="Task">Task</option>
          <option value="Phase">Phase</option>
        </Form.Control>
      </Form.Group>

      {/* Title */}
      <Form.Group controlId="taskTitle">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          placeholder={`Enter ${taskType.toLowerCase()} title`}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          aria-required="true"
        />
      </Form.Group>

      {/* Description */}
      <Form.Group controlId="taskDescription">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder={`Enter ${taskType.toLowerCase()} description`}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Form.Group>

      {/* Start Date */}
      <Form.Group controlId="startDate">
        <Form.Label>Start Date</Form.Label>
        <Form.Control
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          aria-label="Start Date Input"
        />
      </Form.Group>

      {/* Target Date */}
      <Form.Group controlId="targetDate">
        <Form.Label>Target Date</Form.Label>
        <Form.Control
          type="date"
          value={targetDate}
          onChange={(e) => setTargetDate(e.target.value)}
          aria-label="Target Date Input"
        />
      </Form.Group>

      {/* Status */}
      <Form.Group controlId="status">
        <Form.Label>Status</Form.Label>
        <Form.Control
          as="select"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          aria-label="Status Selection"
        >
          <option value="To Do">To Do</option>
          <option value="In Progress">In Progress</option>
          <option value="Done">Done</option>
          <option value="Cancelled">Cancelled</option>
        </Form.Control>
      </Form.Group>

      {/* Phase Selection (only for Tasks) */}
      {taskType === 'Task' && (
        <Form.Group controlId="phaseId">
          <Form.Label>Link to Phase</Form.Label>
          <Form.Control
            as="select"
            value={phaseId}
            onChange={(e) => setPhaseId(e.target.value)}
            aria-label="Phase Selection"
          >
            <option value="">Select a Phase</option>
            {tasks
              .filter((task) => task.taskType === 'Phase')
              .map((phase) => (
                <option key={phase.id} value={phase.id}>
                  {phase.title}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
      )}

      {/* Assignee Selection */}
      <Form.Group controlId="assignee">
        <Form.Label>Assignee</Form.Label>
        <Form.Control
          as="select"
          value={assignee}
          onChange={(e) => setAssignee(e.target.value)}
          aria-label="Assignee Selection"
        >
          <option value="">Unassigned</option>
          {projectUsers.map((user) => (
            <option key={user.uid} value={user.uid}>
              {user.name} ({user.email})
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Priority Selection */}
      <Form.Group controlId="priority">
        <Form.Label>Priority</Form.Label>
        <Form.Control
          as="select"
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
          aria-label="Priority Selection"
        >
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </Form.Control>
      </Form.Group>

      {/* Dependency Management */}
      <Form.Group controlId="dependsOn">
        <Form.Label>Add Dependency</Form.Label>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Search task to add as dependency..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            aria-label="Dependency Search"
          />
        </InputGroup>

        {filteredTasks.length > 0 && (
          <ListGroup>
            {filteredTasks.map((task) => (
              <ListGroup.Item
                key={task.id}
                onClick={() => handleAddDependency(task.id)}
                style={{ cursor: 'pointer' }}
                aria-label={`Add dependency: ${task.title}`}
              >
                {task.title}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}

        {/* Display selected dependencies */}
        <Form.Label>Dependencies</Form.Label>
        <ListGroup className="mt-3">
          {dependsOn.length > 0 ? (
            dependsOn.map((taskId) => {
              const task = tasks.find((t) => t.id === taskId);
              return (
                <ListGroup.Item
                  key={taskId}
                  className="d-flex justify-content-between align-items-center"
                >
                  {task ? task.title : 'Unknown Task'}
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleRemoveDependency(taskId)}
                    aria-label={`Remove dependency: ${task ? task.title : 'Unknown Task'}`}
                  >
                    &times;
                  </Button>
                </ListGroup.Item>
              );
            })
          ) : (
            <ListGroup.Item>No dependencies added.</ListGroup.Item>
          )}
        </ListGroup>
      </Form.Group>

      {/* Submit Button */}
      <Button variant="primary" type="submit" className="mt-3">
        {isEditMode ? 'Save Changes' : `Add ${taskType}`}
      </Button>
    </Form>
  );
};

// PropTypes validation
TaskForm.propTypes = {
  initialData: PropTypes.shape({
    taskType: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    targetDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    status: PropTypes.string,
    phaseId: PropTypes.string,
    dependsOn: PropTypes.arrayOf(PropTypes.string),
    assignee: PropTypes.string,
    priority: PropTypes.string,
    id: PropTypes.string,
  }),
  tasks: PropTypes.array,
  projectUsers: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
};

// Default props to prevent undefined errors
TaskForm.defaultProps = {
  tasks: [],
  projectUsers: [],
};

export default TaskForm;
