import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import './CookieConsent.css';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [consent, setConsent] = useState({
    necessary: true,
    allCookies: false,
  });

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsent');
    if (!consentGiven) {
      setIsVisible(true);
    }
  }, []);

  const handleConsentChange = (e) => {
    setConsent({
      ...consent,
      [e.target.name]: e.target.checked,
    });
  };

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(consent));
    setIsVisible(false);
    // Trigger necessary actions based on consent
    if (consent.allCookies) {
      // Initialize analytics and other cookies here
    } else {
      // Initialize only necessary cookies
    }
  };

  if (!isVisible) return null;

  return (
    <div className="cookie-consent-banner">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <p>
              We use cookies to ensure you get the best experience on our
              website. You can accept only necessary cookies or all cookies for
              a personalized experience.
            </p>
          </Col>
          <Col md={4} className="text-right">
            <Form.Check
              type="checkbox"
              label="Accept Necessary Cookies"
              name="necessary"
              checked={consent.necessary}
              onChange={handleConsentChange}
              disabled
            />
            <Form.Check
              type="checkbox"
              label="Accept All Cookies"
              name="allCookies"
              checked={consent.allCookies}
              onChange={handleConsentChange}
            />

          </Col>
            <Col md={2}>
              <Button variant="primary" onClick={handleAccept} className="mt-2">
                Accept Selected
              </Button>
            </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CookieConsent;
