// src/analytics.js
import ReactGA from 'react-ga4';

export const initGA = (trackingID) => {
  ReactGA.initialize(trackingID, { debug: true });
};

export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};

export const logEvent = (category, action, label) => {
  if (category && action) {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
};
