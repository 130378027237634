// src/components/BillingManagement/SuccessDisplay.js

import React, { useContext } from 'react';
import { Container, Button } from 'react-bootstrap';
import axios from 'axios';
import './SubscriptionStyles.css';
import { AuthContext } from '../../context/auth/AuthContext';

const SuccessDisplay = ({ sessionId }) => {
  const API_URL = `${process.env.REACT_APP_API_URL}`;
  const { currentUser, fetchSubscriptions } = useContext(AuthContext);

  const handleManageBilling = async () => {
    try {
      const response = await axios.post(`${API_URL}/create-portal-session`, {
        session_id: sessionId,
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating portal session:', error);
      // Optionally, handle error by displaying a message to the user
    }
  };

  const handleRefresh = async () => {
    if (currentUser) {
      await fetchSubscriptions(currentUser.email);
    }
  };

  return (
    <Container className="success-display-container text-center">
      <div className="success-message">
        <h3>Subscription Successful!</h3>
        <p>Thank you for subscribing. You can manage your billing information below.</p>
      </div>
      <Button variant="success" id="checkout-and-portal-button" onClick={handleManageBilling}>
        Manage your billing information
      </Button>
      <Button variant="primary" className="mt-3" onClick={handleRefresh}>
        Refresh Subscription Status
      </Button>
    </Container>
  );
};

export default SuccessDisplay;
