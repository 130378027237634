// src/components/TaskActions.js

import React from 'react';
import { Button } from 'react-bootstrap';
import AddTaskModal from '../ProjectBacklog/AddTaskModal';
import PropTypes from 'prop-types';

/**
 * Component to handle task-related actions like adding a new task.
 */
const TaskActions = ({
  showAddTaskModal,
  setShowAddTaskModal,
  handleAddTask,
  tasks,
  selectedTask,
  setSelectedTask,
  projectUsers, // Receive projectUsers as a prop
}) => {
  const handleClose = () => {
    setShowAddTaskModal(false);
    setSelectedTask(null);
  };

  return (
    <div className="mb-3">
      <Button
        variant="primary"
        onClick={() => {
          setShowAddTaskModal(true);
          setSelectedTask(null);
        }}
        aria-label="Add Item"
      >
        Add Item
      </Button>
      <AddTaskModal
        show={showAddTaskModal}
        onHide={handleClose}
        onAddTask={handleAddTask}
        tasks={tasks}
        projectUsers={projectUsers} // Pass projectUsers to AddTaskModal
        preSelectedPhaseId={selectedTask ? selectedTask.phaseId : null}
      />
    </div>
  );
};

// PropTypes validation
TaskActions.propTypes = {
  showAddTaskModal: PropTypes.bool.isRequired,
  setShowAddTaskModal: PropTypes.func.isRequired,
  handleAddTask: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,
  selectedTask: PropTypes.object,
  setSelectedTask: PropTypes.func.isRequired,
  projectUsers: PropTypes.array.isRequired, // Ensure projectUsers is passed
};

export default TaskActions;
