import React, { useState, useEffect } from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../../../firebase';

const DeleteTaskModal = ({ show, onHide, onDelete, selectedTask, project, tasks }) => {
  const [linkedPhases, setLinkedPhases] = useState([]);

  useEffect(() => {
    const findLinkedPhases = () => {
      if (selectedTask && selectedTask.taskType === 'Phase') {
        const linkedPhasesInfo = tasks
          .filter((task) => task.phaseId === selectedTask.id)
          .map((task) => ({ id: task.id, title: task.title }));
        setLinkedPhases(linkedPhasesInfo);
      }
    };

    findLinkedPhases();
  }, [selectedTask, tasks]);

  const handleDelete = async () => {
    try {
      if (selectedTask && selectedTask.taskType === 'Phase') {
        // Update the phaseId of linked phases to null
        await Promise.all(
          linkedPhases.map(async (phase) => {
            await updateDoc(doc(firestore, `projects/${project.id}/tasks`, phase.id), {
              phaseId: null,
            });
          })
        );
      }

      // Delete the selected task
      await deleteDoc(doc(firestore, `projects/${project.id}/tasks`, selectedTask.id));

      onDelete(); // Call the onDelete callback to update state or perform any necessary actions
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this {selectedTask && selectedTask.title}?</p>
        {linkedPhases.length > 0 && (
          <>
            <p>The following linked phases will be affected:</p>
            <ListGroup>
              {linkedPhases.map((phase, index) => (
                <ListGroup.Item key={index}>{phase.title}</ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTaskModal;
