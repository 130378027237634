import React, { useState, useEffect } from 'react';
import { Form, Button, ProgressBar, Col, Container, Card } from 'react-bootstrap';
import { getFirestore, collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth } from '../../firebase';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Resizer from 'react-image-file-resizer';
import CreationSuccess from './CreationSuccess';
import AddressSuggestions from './AddressValidation';
import './jobs.css';

const CreateJob = () => {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(50);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [whenNeeded, setWhenNeeded] = useState('ASAP');
  const [selectedDate, setSelectedDate] = useState(null);
  const [tradeCategory, setTradeCategory] = useState('');
  const [tradeCategories, setTradeCategories] = useState([]);
  const [budgetRange, setBudgetRange] = useState([0, 1000]);
  const [address, setAddress] = useState('');
  const [image, setImage] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    const fetchTradeCategories = async () => {
      const querySnapshot = await getDocs(collection(db, 'companies'));
      const categories = new Set();
      querySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.companyTradeType) {
          categories.add(data.companyTradeType);
        }
      });
      setTradeCategories(Array.from(categories));
    };
    fetchTradeCategories();
  }, [db]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (!user) {
      alert('Please log in to create a job.');
      return;
    }

    if (!title || !description || !tradeCategory || !address || budgetRange[0] < 0 || budgetRange[1] < budgetRange[0]) {
      alert('Please fill in all required fields correctly.');
      return;
    }

    let imageUrl = '';
    if (image) {
      const storageRef = ref(storage, `job_images/${user.uid}/${Date.now()}_${image.name}`);
      const resizedImage = await resizeFile(image);
      await uploadBytes(storageRef, resizedImage);
      imageUrl = await getDownloadURL(storageRef);
    }

    const suburb = address.split(',')[1]?.trim() || address; // Extracting the suburb

    await addDoc(collection(db, 'jobs'), {
      title,
      description,
      whenNeeded,
      selectedDate: selectedDate ? selectedDate.toISOString() : null,
      tradeCategory,
      budgetRange,
      address, 
      suburb,
      imageUrl,
      userId: user.uid,
      createdAt: serverTimestamp()
    });    

    // Reset form
    setTitle('');
    setDescription('');
    setWhenNeeded('ASAP');
    setSelectedDate(null);
    setTradeCategory('');
    setBudgetRange([0, 1000]);
    setAddress('');
    setImage(null);

    setShowSuccess(true);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        'JPEG',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'blob'
      );
    });

  const handleNextStep = () => {
    if (step === 1 && (!title || !description)) {
      alert('Please fill in the required fields.');
      return;
    }
    setStep(step + 1);
    setProgress(100);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
    setProgress(50);
  };

  if (showSuccess) {
    return <CreationSuccess />;
  }

  return (
    <div>
      <Card.Body>
        <Container>
          <Col md={9} sm={12} className="mx-auto modal-style">
            <h2>Post New Job!</h2>
            <ProgressBar now={progress} variant="custom" className='progress-bar-custom' />
            {step === 1 && (
              <Form>
                <Form.Group controlId="formTitle">
                  <Form.Label>In a few words, what is required? *</Form.Label>
                  <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                </Form.Group>

                <Form.Group controlId="formDescription">
                  <Form.Label>What are the details? *</Form.Label>
                  <Form.Control as="textarea" rows={5} value={description} onChange={(e) => setDescription(e.target.value)} required />
                </Form.Group>

                <Form.Group controlId="formWhenNeeded">
                  <Form.Label>When do you need this done? *</Form.Label>
                  <Form.Control as="select" value={whenNeeded} onChange={(e) => setWhenNeeded(e.target.value)}>
                    <option value="ASAP">ASAP</option>
                    <option value="On Date">On Date</option>
                    <option value="Before Date">Before Date</option>
                    <option value="I'm Flexible">I'm Flexible</option>
                  </Form.Control>
                  {(whenNeeded === 'On Date' || whenNeeded === 'Before Date') && (
                    <DatePicker selected={selectedDate} onChange={(date) => setSelectedDate(date)} />
                  )}
                </Form.Group>

                <Button variant="primary" onClick={handleNextStep}>
                  Next
                </Button>
              </Form>
            )}

            {step === 2 && (
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formTradeCategory">
                  <Form.Label>Trade Category *</Form.Label>
                  <Form.Control as="select" value={tradeCategory} onChange={(e) => setTradeCategory(e.target.value)} required>
                    <option value="">Select a category</option>
                    {tradeCategories.map((category, index) => (
                      <option key={index} value={category}>{category}</option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="formBudgetRange">
                  <Form.Label>Budget Range</Form.Label>
                  <Slider range min={0} max={10000} defaultValue={budgetRange} onChange={(value) => setBudgetRange(value)} />
                  <div>{`$${budgetRange[0]} - $${budgetRange[1]}`}</div>
                </Form.Group>

                <AddressSuggestions 
                  subtitle="Full address will only be shared once you have accepted a quote."
                  setAddress={setAddress} 
                />

                <Form.Group controlId="formImage">
                  <Form.Label>Supporting Images/ Files</Form.Label>
                  <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
                </Form.Group>

                <Button variant="secondary" onClick={handlePrevStep}>
                  Back
                </Button>
                <Button variant="primary" type="submit">
                  Create Job
                </Button>
              </Form>
            )}
          </Col>
        </Container>
      </Card.Body>
    </div>
  );
};

export default CreateJob;