// src/components/Roadmap.js

import React, { useState, useEffect, useContext } from 'react';
import { collection, onSnapshot, updateDoc, doc, addDoc } from 'firebase/firestore';
import { firestore } from '../../../../firebase';
import './roadmap.css';
import TaskActions from './TaskActions';
import TimelineToolbar from './TimelineToolbar';
import GanttChart from './GanttChart';
import EditTaskModal from '../ProjectBacklog/EditTaskModal'; // Import the EditTaskModal component

// Import permission utilities
import {
  hasAdminAccess,
  hasEditAccess,
  hasReadAccess,
} from '../../ProjectUtils/permissions';

import { AuthContext } from '../../../../context/auth/AuthContext';

// Import the utility function
import { fetchProjectUserDetails } from '../../ProjectUtils/fetchUsers';

const Roadmap = ({ project }) => {
  const { currentUser } = useContext(AuthContext);
  const [tasks, setTasks] = useState([]);
  const [timelineView, setTimelineView] = useState('Week');
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false); // State for EditTaskModal
  const [userPermission, setUserPermission] = useState('Read'); // Default to Read
  const [projectUsers, setProjectUsers] = useState([]); // State for project users

  // Determine user permissions and fetch project users
  useEffect(() => {
    if (project && currentUser) {
      const userEntry = project.users.find(user => user.uid === currentUser.uid);
      const permission = userEntry ? userEntry.permission : 'Read';
      setUserPermission(permission);

      // Fetch project user details
      fetchProjectUserDetails(project.users)
        .then(setProjectUsers)
        .catch((error) => {
          console.error(error);
          // Handle error as needed
        });
    }
  }, [project, currentUser]);

  useEffect(() => {
    if (project) {
      const unsubscribe = onSnapshot(
        collection(firestore, `projects/${project.id}/tasks`),
        (snapshot) => {
          const fetchedTasks = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTasks(fetchedTasks);
        }
      );
      return () => unsubscribe();
    }
  }, [project]);

  // Function to add a new task
  const handleAddTask = async (newTask) => {
    // Only Admin and Edit users can add tasks
    if (!hasEditAccess(userPermission)) {
      alert('You do not have permission to add tasks.');
      return;
    }
    await addDoc(collection(firestore, `projects/${project.id}/tasks`), newTask);
    setShowAddTaskModal(false);
  };

  // Helper function to format Date object to 'dd/MM/yyyy'
  const formatDate = (date) => {
    if (!date) return '';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Function to parse 'dd/MM/yyyy' to Date object and set time to noon to prevent timezone issues
  const parseDate = (dateStr) => {
    if (!dateStr) return null;
    const [day, month, year] = dateStr.split('/');
    const date = new Date(year, month - 1, day);
    date.setHours(12, 0, 0, 0); // Set time to noon to minimize timezone shifts
    return isNaN(date.getTime()) ? null : date;
  };

  // Function to format tasks for the Gantt chart and sort them by startDate
  const formatTasksForGantt = (tasksData) => {
    const phases = tasksData.filter((task) => task.taskType === 'Phase');
    const nonPhaseTasks = tasksData.filter((task) => task.taskType !== 'Phase');
    const tasksByPhase = {};

    // Group tasks by their phaseId
    nonPhaseTasks.forEach((task) => {
      if (task.phaseId) {
        if (!tasksByPhase[task.phaseId]) {
          tasksByPhase[task.phaseId] = [];
        }
        tasksByPhase[task.phaseId].push(task);
      }
    });

    // Sort tasks within each phase by startDate
    for (const phaseId in tasksByPhase) {
      tasksByPhase[phaseId].sort((a, b) => {
        const dateA = parseDate(a.startDate);
        const dateB = parseDate(b.startDate);
        return !dateA || !dateB ? 0 : dateA - dateB;
      });
    }

    const msPerDay = 1000 * 3600 * 24; // Number of milliseconds in a day
    const formattedTasks = [];

    // Format phases and their subtasks, sorted by phase startDate
    phases.sort((a, b) => {
      const dateA = parseDate(a.startDate);
      const dateB = parseDate(b.startDate);
      return !dateA || !dateB ? 0 : dateA - dateB;
    }).forEach((phase) => {
      const phaseStartDateOriginal = parseDate(phase.startDate);
      const phaseEndDateOriginal = parseDate(phase.targetDate);

      const tasksUnderPhase = tasksByPhase[phase.id] || [];

      // Find earliest and latest task dates
      const earliestTaskStartDate = tasksUnderPhase.reduce((earliest, task) => {
        const taskStart = parseDate(task.startDate);
        if (!taskStart) return earliest;
        return (!earliest || taskStart < earliest) ? taskStart : earliest;
      }, null);

      const latestTaskEndDate = tasksUnderPhase.reduce((latest, task) => {
        const taskEnd = parseDate(task.targetDate);
        if (!taskEnd) return latest;
        return (!latest || taskEnd > latest) ? taskEnd : latest;
      }, null);

      // Apply Phase Start Date Rules
      let finalPhaseStartDate = phaseStartDateOriginal;
      if (earliestTaskStartDate) {
        if (!phaseStartDateOriginal || earliestTaskStartDate < phaseStartDateOriginal) {
          finalPhaseStartDate = earliestTaskStartDate;
        }
      }

      // Apply Phase Target Date Rules
      let finalPhaseEndDate = phaseEndDateOriginal;
      if (latestTaskEndDate) {
        if (!phaseEndDateOriginal || latestTaskEndDate > phaseEndDateOriginal) {
          finalPhaseEndDate = latestTaskEndDate;
        }
      }

      // If both phase dates are missing, use default logic based on tasks
      if (!finalPhaseStartDate && !finalPhaseEndDate && tasksUnderPhase.length > 0) {
        finalPhaseStartDate = earliestTaskStartDate || new Date();
        finalPhaseEndDate = latestTaskEndDate || new Date();
      }

      const duration = finalPhaseStartDate && finalPhaseEndDate
        ? Math.round((finalPhaseEndDate - finalPhaseStartDate) / msPerDay) + 1
        : 1; // Inclusive duration

      formattedTasks.push({
        TaskID: phase.id,
        TaskName: phase.title,
        StartDate: finalPhaseStartDate || new Date(),
        EndDate: finalPhaseEndDate || new Date(),
        Duration: duration,
        subtasks: tasksUnderPhase.map((task) => {
          const taskStartDate = parseDate(task.startDate) || new Date();
          const taskEndDate = parseDate(task.targetDate) || new Date();
          const taskDuration = Math.round((taskEndDate - taskStartDate) / msPerDay) + 1 || 1; // Inclusive duration
          return {
            TaskID: task.id,
            TaskName: task.title,
            StartDate: taskStartDate,
            EndDate: taskEndDate,
            Duration: taskDuration,
            Predecessor: task.dependsOn ? task.dependsOn.join(',') : '',
            phaseId: task.phaseId,
          };
        }),
      });
    });

    // Format unlinked tasks (tasks without a phase) and sort them by startDate
    const unlinkedTasks = nonPhaseTasks
      .filter((task) => !task.phaseId)
      .sort((a, b) => {
        const dateA = parseDate(a.startDate);
        const dateB = parseDate(b.startDate);
        return !dateA || !dateB ? 0 : dateA - dateB;
      })
      .map((task) => {
        const taskStartDate = parseDate(task.startDate) || new Date();
        const taskEndDate = parseDate(task.targetDate) || new Date();
        const taskDuration = Math.round((taskEndDate - taskStartDate) / msPerDay) + 1 || 1; // Inclusive duration
        return {
          TaskID: task.id,
          TaskName: task.title,
          StartDate: taskStartDate,
          EndDate: taskEndDate,
          Duration: taskDuration,
          Predecessor: task.dependsOn ? task.dependsOn.join(',') : '',
          phaseId: task.phaseId,
        };
      });

    return [...formattedTasks, ...unlinkedTasks];
  };

  const formattedGanttTasks = formatTasksForGantt(tasks);

  // Define task fields for Syncfusion GanttChart
  const taskFields = {
    id: 'TaskID',
    name: 'TaskName',
    startDate: 'StartDate',
    endDate: 'EndDate',
    duration: 'Duration',
    dependency: 'Predecessor',
    child: 'subtasks',
  };

  const editSettings = {
    allowEditing: hasEditAccess(userPermission),
    allowTaskbarEditing: hasEditAccess(userPermission),
    showDeleteConfirmDialog: hasEditAccess(userPermission),
  };

  const labelSettings = {
    rightLabel: 'TaskName',
  };

  const dateFormat = 'dd/MM/yyyy';

  const toolbarOptions = [
    hasEditAccess(userPermission) ? 'Add' : null,
    hasEditAccess(userPermission) ? 'Edit' : null,
    hasEditAccess(userPermission) ? 'Update' : null,
    hasEditAccess(userPermission) ? 'Delete' : null,
    'Cancel',
    'ExpandAll',
    'CollapseAll',
    'ZoomIn',
    'ZoomOut',
    'ZoomToFit',
    'Search',
  ].filter(option => option !== null); // Remove nulls based on permissions

  // Function to handle date changes and update Firestore
  const handleTaskDateChange = async (updatedTask) => {
    const taskRef = doc(firestore, `projects/${project.id}/tasks`, updatedTask.TaskID);

    const startDate = parseDate(updatedTask.StartDate);
    const endDate = parseDate(updatedTask.EndDate);

    if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
      // Manually format dates to 'dd/MM/yyyy'
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      await updateDoc(taskRef, { startDate: formattedStartDate, targetDate: formattedEndDate });
    } else {
      console.error('Invalid Date:', {
        startDate: updatedTask.StartDate,
        endDate: updatedTask.EndDate,
      });
    }
  };

  // Function to handle dependency changes and update Firestore
  const handleTaskDependencyChange = async (updatedTask) => {
    const taskRef = doc(firestore, `projects/${project.id}/tasks`, updatedTask.TaskID);
    // Parse dependencies to retain ID and type
    const dependencies = updatedTask.Predecessor
      ? updatedTask.Predecessor.split(',').map((dep) => dep.trim())
      : [];
    await updateDoc(taskRef, { dependsOn: dependencies });
  };

  // Function to handle task name changes and update Firestore
  const handleTaskNameChange = async (updatedTask) => {
    const taskRef = doc(firestore, `projects/${project.id}/tasks`, updatedTask.TaskID);
    await updateDoc(taskRef, { title: updatedTask.TaskName });
  };

  // Function to handle timeline view changes
  const handleTimelineChange = (view) => {
    setTimelineView(view);
  };

  // Function to handle actions completed in the Gantt chart
  const actionCompleteHandler = async (args) => {
    if (
      args.requestType === 'save' ||
      args.requestType === 'taskbarEditing' ||
      args.requestType === 'dependency'
    ) {
      const updatedTask = args.data;

      // Handle task date changes
      if (updatedTask.StartDate && updatedTask.EndDate) {
        const formattedStartDate = formatDate(updatedTask.StartDate);
        const formattedEndDate = formatDate(updatedTask.EndDate);

        await handleTaskDateChange({
          TaskID: updatedTask.TaskID,
          StartDate: formattedStartDate,
          EndDate: formattedEndDate,
        });
      }

      // Handle task name changes
      if (updatedTask.TaskName !== undefined) {
        await handleTaskNameChange(updatedTask);
      }

      // Handle dependency changes
      if (updatedTask.Predecessor !== undefined) {
        await handleTaskDependencyChange(updatedTask);
      }
    }
  };

  // Function to handle double-click on a task to open the custom EditTaskModal
  const handleTaskDoubleClick = (taskData) => {
    if (hasEditAccess(userPermission)) {
      const taskId = taskData.TaskID;
      const task = tasks.find((t) => t.id === taskId);
      if (task) {
        setSelectedTask(task);
        setShowEditTaskModal(true);
      }
    } else {
      alert('You do not have permission to edit tasks.');
    }
  };

  // Function to handle editing a task from the EditTaskModal
  const handleEditTask = async (updatedTask) => {
    const taskRef = doc(firestore, `projects/${project.id}/tasks`, updatedTask.id);
    await updateDoc(taskRef, {
      title: updatedTask.title,
      description: updatedTask.description,
      startDate: updatedTask.startDate,
      targetDate: updatedTask.targetDate,
      status: updatedTask.status,
      taskType: updatedTask.taskType,
      phaseId: updatedTask.phaseId,
      dependsOn: updatedTask.dependsOn,
    });
    setShowEditTaskModal(false);
  };

  return (
    <div>
      {/* Task Actions - Conditionally render based on permissions */}
      {(hasEditAccess(userPermission) || hasAdminAccess(userPermission)) && (
        <TaskActions
          showAddTaskModal={showAddTaskModal}
          setShowAddTaskModal={setShowAddTaskModal}
          handleAddTask={handleAddTask}
          tasks={tasks}
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
          projectUsers={projectUsers} // Pass projectUsers to TaskActions
        />
      )}

      {/* Timeline Toolbar - Always visible but functionalities depend on permissions */}
      <TimelineToolbar handleTimelineChange={handleTimelineChange} />

      {formattedGanttTasks.length > 0 ? (
        <GanttChart
          dataSource={formattedGanttTasks}
          taskFields={taskFields}
          editSettings={editSettings}
          labelSettings={labelSettings}
          toolbarOptions={toolbarOptions}
          timelineView={timelineView}
          dateFormat={dateFormat}
          actionComplete={actionCompleteHandler}
          onTaskDoubleClick={handleTaskDoubleClick} // Pass the handler with permission check
        />
      ) : (
        <div>No tasks available for this project.</div>
      )}

      {/* Edit Task Modal */}
      {(hasEditAccess(userPermission) || hasAdminAccess(userPermission)) && (
        <EditTaskModal
          show={showEditTaskModal}
          onHide={() => setShowEditTaskModal(false)}
          onEditTask={handleEditTask}
          selectedTask={selectedTask}
          tasks={tasks}
          projectUsers={projectUsers} // Pass projectUsers to EditTaskModal
        />
      )}
    </div>
  );
};

export default Roadmap;
