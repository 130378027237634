// src/components/GanttChart.js

import React from 'react';
import {
  GanttComponent,
  Inject,
  Edit,
  Filter,
  Sort,
  Toolbar,
  DayMarkers,
  ColumnsDirective,
  ColumnDirective,
} from '@syncfusion/ej2-react-gantt';

const GanttChart = ({
  dataSource,
  taskFields,
  editSettings,
  labelSettings,
  toolbarOptions,
  timelineView,
  dateFormat,
  actionComplete,
  onTaskDoubleClick,
}) => {
  // Prevent the default Syncfusion edit dialog
  const actionBegin = (args) => {
    if (args.requestType === 'OpenDialog') {
      args.cancel = true;
    }
  };

  // Handle double-click on a task record
  const recordDoubleClick = (args) => {
    onTaskDoubleClick(args.rowData); // Call the custom handler
  };

  return (
    <GanttComponent
      dataSource={dataSource}
      taskFields={taskFields}
      allowFiltering={true}
      allowSorting={true}
      editSettings={editSettings}
      labelSettings={labelSettings}
      toolbar={toolbarOptions}
      timelineSettings={{
        timelineViewMode: timelineView,
        showTooltip: true,
      }}
      includeWeekend={true}
      allowSelection={true}
      allowResizing={true} // Enable column resizing globally
      actionBegin={actionBegin}
      recordDoubleClick={recordDoubleClick}
      actionComplete={actionComplete}
      gridLines="Both"
      dateFormat={dateFormat}
      eventMarkers={[{ day: new Date()}]} // Add event marker for today's date
    >
      <ColumnsDirective>
        {/* Hide the ID column by setting visible to false */}
        <ColumnDirective field="TaskID" headerText="ID" width="0" visible={false} />

        {/* Define other columns with desired widths and enable resizing */}
        <ColumnDirective
          field="TaskName"
          headerText="Task Name"
          width="250"
          allowResizing={true}
          textAlign="Left"
        />
        <ColumnDirective
          field="StartDate"
          headerText="Start Date"
          width="150"
          format="dd/MM/yyyy"
          textAlign="Center"
          allowResizing={true}
        />
        <ColumnDirective
          field="EndDate"
          headerText="End Date"
          width="150"
          format="dd/MM/yyyy"
          textAlign="Center"
          allowResizing={true}
        />
        <ColumnDirective
          field="Duration"
          headerText="Duration (days)"
          width="120"
          textAlign="Center"
          allowResizing={true}
        />
        {/* Add more columns as needed */}
      </ColumnsDirective>
      {/* Remove Zoom from Inject */}
      <Inject services={[Edit, Filter, Sort, Toolbar, DayMarkers]} />
    </GanttComponent>
  );
};

export default GanttChart;
