// src/components/FileManager/FilesManager.js

import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, ListGroup, Button, InputGroup, FormControl, Alert, Table } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import './FilesManager.css';
import axios from 'axios'; // Import axios for HTTP requests

// Import permission utilities
import {
  hasAdminAccess,
  hasEditAccess,
  hasReadAccess,
} from '../../ProjectUtils/permissions'

import { AuthContext } from '../../../../context/auth/AuthContext';

const FilesManager = ({ project }) => {
  const { currentUser } = useContext(AuthContext);
  const [files, setFiles] = useState([]);
  const [daProcessing, setDaProcessing] = useState(false);
  const [daResults, setDaResults] = useState(null);
  const [userPermission, setUserPermission] = useState('Read'); // Default to Read

  // Determine user permissions
  useEffect(() => {
    if (project && currentUser) {
      const userEntry = project.users.find(user => user.uid === currentUser.uid);
      const permission = userEntry ? userEntry.permission : 'Read';
      setUserPermission(permission);
    }
  }, [project, currentUser]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      console.log("Dropped files:", acceptedFiles);

      acceptedFiles.forEach(file => {
        if (file.name.toLowerCase().includes("da") && file.type === 'application/pdf') {
          handleDAFile(file); // Handle each file directly
        }
      });

      const newFiles = acceptedFiles.map(file => ({
        ...file,
        preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
        tags: []
      }));

      setFiles(prevFiles => [...prevFiles, ...newFiles]);
    },
    // Disable dropzone if user has only Read access
    disabled: hasReadAccess(userPermission) && !hasEditAccess(userPermission) && !hasAdminAccess(userPermission)
  });

  const handleDAFile = async (file) => {
    if (!hasEditAccess(userPermission) && !hasAdminAccess(userPermission)) {
      alert('You do not have permission to process DA PDF files.');
      return;
    }

    setDaProcessing(true);

    const formData = new FormData();
    formData.append('pdf', file);
    formData.append('projectId', project.id);

    try {
      const response = await axios.post('http://127.0.0.1:5000/analyze-da-pdf', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log(`Response data for file ${file.name}:`, response.data);

      // Use the actual API response to update state
      setDaResults(response.data);
    } catch (error) {
      console.error("Error processing DA PDF file " + file.name + ": ", error.response ? error.response.data : error);
    }

    setDaProcessing(false);
  };

  // Example function to fetch updated project details (modify as per your API/backend setup)
  const fetchUpdatedProjectDetails = async (projectId) => {
    const response = await axios.get(`/path-to-your-api/projects/${projectId}`);
    return response.data;
  };

  useEffect(() => {
    // Clean up previews
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const addTag = (fileName, tag) => {
    if (!hasEditAccess(userPermission) && !hasAdminAccess(userPermission)) {
      alert('You do not have permission to add tags.');
      return;
    }

    setFiles(files.map(file => {
      if (file.name === fileName) {
        return { ...file, tags: [...file.tags, tag] };
      }
      return file;
    }));
  };

  const removeFile = (fileName) => {
    if (!hasEditAccess(userPermission) && !hasAdminAccess(userPermission)) {
      alert('You do not have permission to remove files.');
      return;
    }

    setFiles(files.filter(file => file.name !== fileName));
  };

  return (
    <Container className="files-manager">
      <Row className="my-4">
        <Col>
          <h2>Files for Project: {project.name}</h2>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop files here, or click to upload</p>
          </div>
          {/* Inform users if they cannot upload */}
          {(hasReadAccess(userPermission) && !hasEditAccess(userPermission) && !hasAdminAccess(userPermission)) && (
            <Alert variant="warning" className="mt-3">
              You have read-only access and cannot upload or modify files.
            </Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup>
            {files.map((file, index) => (
              <ListGroup.Item key={index}>
                {file.preview && <img src={file.preview} alt={file.name} style={{ width: '100px', height: 'auto' }} />}
                <span>{file.name} - {file.size} bytes</span>
                <Button href={file.preview || '#'} download={file.name} className="me-2">
                  Download
                </Button>
                <div>
                  {file.tags.map((tag, idx) => <span key={idx} className="badge bg-secondary me-1">{tag}</span>)}
                  {(hasEditAccess(userPermission) || hasAdminAccess(userPermission)) && (
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Tag name"
                        aria-label="Tag name"
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            addTag(file.name, e.target.value);
                            e.target.value = '';
                          }
                        }}
                      />
                    </InputGroup>
                  )}
                </div>
                {(hasEditAccess(userPermission) || hasAdminAccess(userPermission)) && (
                  <Button variant="danger" size="sm" onClick={() => removeFile(file.name)}>
                    Remove
                  </Button>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
      {daProcessing && (
        <Row>
          <Col>
            <Alert variant="info">Processing DA PDF, please wait...</Alert>
          </Col>
        </Row>
      )}
      {daResults && (
        <Row>
          <Col>
            <Alert variant="success">
              <pre>{JSON.stringify(daResults.conditions, null, 2)}</pre>
            </Alert>
          </Col>
        </Row>
      )}
      {daResults && daResults.DA_Conditions && (
        <Row>
          <Col>
            <Alert variant="success">
              <h5>Development Approval Conditions:</h5>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Timing</th>
                  </tr>
                </thead>
                <tbody>
                  {daResults.DA_Conditions.map((condition, index) => (
                    <tr key={index}>
                      <td>{condition.Condition_Number}</td>
                      <td>{condition.Condition_Details.Permit_to_Which_These_Conditions_Relate}</td>
                      <td>{condition.Condition_Details.Description}</td>
                      <td>{condition.Condition_Details.Timing}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Alert>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default FilesManager;
