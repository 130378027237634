import React from 'react';
import { Alert, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CreationSuccess = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/jobs');
  };

  return (
    <Container className="mt-5 text-center">
      <Alert className="modal-style">
        <Alert.Heading>Job Created Successfully!</Alert.Heading>
        <p>Your job has been created successfully. Thank you for using our service!</p>
        <div className="next-steps">
          <strong>Next Steps:</strong>
          <ul>
            <li>Our partnered companies will review your job and submit quotes.</li>
            <li>You'll receive notifications once quotes are available.</li>
            <li>Accept your preferred quote to move to the next stage.</li>
            <li>We'll connect you directly with the chosen company to finalise details.</li>
          </ul>
        </div>
        <Button variant="primary" onClick={handleGoBack}>
          View Jobs
        </Button>
      </Alert>
    </Container>
  );
};

export default CreationSuccess;
