import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, where, addDoc, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import { Button, Form, Alert, Spinner, ListGroup } from 'react-bootstrap';
import { auth } from '../../firebase';

const JobQuestions = ({ jobId, jobCreatorId }) => {
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [newResponse, setNewResponse] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getFirestore();

  useEffect(() => {
    if (!jobId) {
      setError('Job ID is not defined');
      setLoading(false);
      return;
    }
  
    const fetchQuestions = async () => {
      try {
        const questionsQuery = query(collection(db, 'questions'), where('jobId', '==', jobId));
        const questionsSnapshot = await getDocs(questionsQuery);
        const questionsData = questionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setQuestions(questionsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchQuestions();
  }, [jobId, db]);
  

  const handlePostQuestion = async () => {
    const user = auth.currentUser;

    if (!user) {
      alert('Please log in to post a question.');
      return;
    }

    if (!newQuestion) {
      alert('Please enter a question.');
      return;
    }

    try {
      await addDoc(collection(db, 'questions'), {
        jobId,
        userId: user.uid,
        question: newQuestion,
        responses: [],
        createdAt: serverTimestamp(),
      });
      setNewQuestion('');
      const questionsQuery = query(collection(db, 'questions'), where('jobId', '==', jobId));
      const questionsSnapshot = await getDocs(questionsQuery);
      const questionsData = questionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setQuestions(questionsData);
    } catch (err) {
      setError(err.message);
    }
  };

  const handlePostResponse = async (questionId) => {
    try {
      const questionRef = doc(db, 'questions', questionId);
      const questionSnapshot = await getDocs(query(collection(db, 'questions'), where('jobId', '==', jobId)));
      const questionData = questionSnapshot.docs.find(doc => doc.id === questionId).data();
      const updatedResponses = [...(questionData.responses || []), { response: newResponse, respondedAt: new Date().toISOString() }];
      await updateDoc(questionRef, {
        responses: updatedResponses,
      });
      setNewResponse('');
      const questionsQuery = query(collection(db, 'questions'), where('jobId', '==', jobId));
      const questionsSnapshot = await getDocs(questionsQuery);
      const questionsData = questionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setQuestions(questionsData);
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  const isJobCreator = auth.currentUser?.uid === jobCreatorId;

  return (
    <>
      <h4>Questions & Answers</h4>
      {questions.length === 0 ? (
        <p>No questions yet.</p>
      ) : (
        <ListGroup>
          {questions.map((q) => (
            <ListGroup.Item className='margin-top-15' key={q.id}>
              <p><strong>{q.userId === auth.currentUser.uid ? 'You' : 'Other'}:</strong> {q.question}</p>
              <p><strong>Asked At:</strong> {q.createdAt?.toDate().toLocaleString()}</p>
              {q.responses?.map((res, index) => (
                <p key={index}><strong>Response {index + 1}:</strong> {res.response} (at {new Date(res.respondedAt).toLocaleString()})</p>
              )) ?? <p>No responses yet</p>}
              {isJobCreator && (
                <Form>
                  <hr />
                  <Form.Group controlId="formResponse">
                    <Form.Label><strong>Respond to Question</strong></Form.Label>
                    <Form.Control
                      type="text"
                      value={newResponse}
                      onChange={(e) => setNewResponse(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Button variant="primary" onClick={() => handlePostResponse(q.id)}>
                    Post Response
                  </Button>
                </Form>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
      {auth.currentUser && !isJobCreator && (
        <Form>
          <Form.Group controlId="formQuestion">
            <Form.Label>Ask a Question</Form.Label>
            <Form.Control
              type="text"
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" onClick={handlePostQuestion}>
            Post Question
          </Button>
        </Form>
      )}
    </>
  );
};

export default JobQuestions;
