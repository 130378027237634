// src/components/DrawingManagerComponent.js

import React, { useRef, useEffect } from 'react';
import { DrawingManager } from '@react-google-maps/api';
import PropTypes from 'prop-types';

const DrawingManagerComponent = ({
  mapRef,
  isDrawingMode,
  setIsDrawingMode,
  customLayers,
  setCustomLayers,
}) => {
  const drawingManagerRef = useRef(null);

  /**
   * Handle loading of the DrawingManager
   */
  const onDrawingManagerLoad = (drawingManager) => {
    drawingManagerRef.current = drawingManager;
  };

  /**
   * Handle completion of a drawing (e.g., when a user finishes drawing a shape)
   */
  const onOverlayComplete = (e) => {
    const overlay = e.overlay;
    const type = e.type;

    let newOverlay = {
      type: type, // e.g., 'circle', 'polygon', etc.
      overlay: overlay, // The overlay instance
      visible: true, // Visibility flag
      name: `Layer ${customLayers.length + 1}`, // Default name
      strokeColor: '#FF0000', // Default stroke color
      fillColor: '#FF0000', // Default fill color
      opacity: 0.35, // Default transparency
      noFill: false, // New property for "No Fill"
      radius: type === 'circle' ? overlay.getRadius() : null, // Store radius if circle
      radiusLabel: null, // To store the radius label for circles
    };

    if (type === 'circle') {
      // Create a radius label for the circle
      const radius = overlay.getRadius();
      const center = overlay.getCenter();

      // Calculate the position on the circle's edge where the label will be placed
      const labelPosition = window.google.maps.geometry.spherical.computeOffset(
        center,
        radius,
        45 // 45 degrees from north (northeast)
      );

      const label = new window.google.maps.Marker({
        position: labelPosition,
        map: mapRef.current,
        label: {
          text: `${Math.round(radius)} m`,
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold',
        },
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 0, // Hide the default marker icon
        },
        draggable: false,
        clickable: false,
        zIndex: 1000,
      });

      newOverlay.radiusLabel = label;
    }

    // Add the new overlay to customLayers
    setCustomLayers((prevLayers) => [...prevLayers, newOverlay]);

    // Disable drawing mode after creating a shape
    drawingManagerRef.current.setDrawingMode(null);
    setIsDrawingMode(false);

    console.log(`New overlay created: ${type}`);
  };

  /**
   * Effect to handle toggling of drawing mode based on isDrawingMode state
   */
  useEffect(() => {
    if (drawingManagerRef.current) {
      const mode = isDrawingMode
        ? window.google.maps.drawing.OverlayType.CIRCLE
        : null;
      drawingManagerRef.current.setDrawingMode(mode);
    }
  }, [isDrawingMode]);

  return (
    <DrawingManager
      onLoad={onDrawingManagerLoad}
      onOverlayComplete={onOverlayComplete}
      options={{
        drawingControl: false, // Disable default drawing controls
        drawingMode: isDrawingMode
          ? window.google.maps.drawing.OverlayType.CIRCLE
          : null,
        // Define which drawing modes are available
        drawingControlOptions: {
          drawingModes: ['circle', 'polygon', 'rectangle', 'polyline'],
        },
        // Styling options for drawn shapes
        circleOptions: {
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          strokeWeight: 2,
          strokeColor: '#FF0000',
          clickable: true,
          editable: true,
          zIndex: 1,
        },
        polygonOptions: {
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          strokeWeight: 2,
          strokeColor: '#FF0000',
          clickable: true,
          editable: true,
          zIndex: 1,
        },
        rectangleOptions: {
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          strokeWeight: 2,
          strokeColor: '#FF0000',
          clickable: true,
          editable: true,
          zIndex: 1,
        },
        polylineOptions: {
          strokeColor: '#FF0000',
          strokeWeight: 2,
          clickable: true,
          editable: true,
          zIndex: 1,
        },
      }}
    />
  );
};

DrawingManagerComponent.propTypes = {
  mapRef: PropTypes.object.isRequired,
  isDrawingMode: PropTypes.bool.isRequired,
  setIsDrawingMode: PropTypes.func.isRequired,
  customLayers: PropTypes.array.isRequired,
  setCustomLayers: PropTypes.func.isRequired,
};

export default DrawingManagerComponent;
