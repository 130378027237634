// src/components/ExpensesManager/EditExpenseModal.js

import React from 'react';
import { Modal } from 'react-bootstrap';
import ExpenseForm from './ExpenseForm'; // Ensure correct path and default export

const EditExpenseModal = ({
  show,
  onHide,
  onEditExpense,
  selectedExpense,
  tasks,
  phases,
}) => {
  const handleSubmit = (expenseData, attachment) => {
    onEditExpense(expenseData, attachment);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Edit Expense</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ExpenseForm
          initialData={selectedExpense}
          tasks={tasks}
          phases={phases}
          onSubmit={handleSubmit}
          isEditMode={true}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EditExpenseModal;
