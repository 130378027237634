// src/components/LayersManager.js

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const LayersManager = ({
  mapRef,
  isLoaded,
  selectedLayers,
  availableLayers,
  busStopTypeFilters,
  roadHierarchyTypeFilters,
  setLoadingLayers,
  setLayerErrors,
  transformDataToGeoJSON,
  fetchedDataCache,
}) => {
  const dataLayerRefs = useRef({});

  /**
   * Helper function to check if cached data is still valid (within 24 hours)
   */
  const isCacheValid = (timestamp) => {
    const now = Date.now();
    const cacheDuration = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    return now - timestamp < cacheDuration;
  };

  /**
   * Load and unload predefined layers based on user selection
   */
  useEffect(() => {
    if (!isLoaded) {
      console.log('Google Maps script not loaded yet');
      return;
    }

    const map = mapRef.current;
    if (!map) {
      console.log('Map reference is not available');
      return;
    }

    /**
     * Function to load a predefined layer onto the map
     */
    const loadLayer = async (layerName, layerInfo) => {
      console.log(`Loading layer: ${layerName}`);
      setLoadingLayers((prev) => ({ ...prev, [layerName]: true }));
      try {
        let geoJsonData;

        if (layerName === 'Bus Stops') {
          // Handle Bus Stops layer with client-side filtering
          if (
            !fetchedDataCache.current[layerInfo.cacheKey] ||
            !isCacheValid(fetchedDataCache.current[layerInfo.cacheKey].timestamp)
          ) {
            console.log(`Fetching Bus Stops from URL: ${layerInfo.url}`);
            const response = await fetch(layerInfo.url); // No query parameters
            if (!response.ok) {
              const errorText = await response.text();
              throw new Error(
                `Error ${response.status}: ${response.statusText} - ${errorText}`
              );
            }
            const data = await response.json();
            console.log(`Fetched data for ${layerName}:`, data);
            geoJsonData = transformDataToGeoJSON(data);
            if (!geoJsonData || geoJsonData.type !== 'FeatureCollection') {
              throw new Error(
                `Invalid GeoJSON data for layer ${layerName}: ${JSON.stringify(
                  geoJsonData
                )}`
              );
            }
            // Cache the data
            fetchedDataCache.current[layerInfo.cacheKey] = {
              data: geoJsonData,
              timestamp: Date.now(),
            };
            console.log(`Data fetched and cached for layer: ${layerName}`);
          } else {
            console.log(`Using cached data for layer: ${layerName}`);
            geoJsonData = fetchedDataCache.current[layerInfo.cacheKey].data;
          }

          // Apply client-side filtering
          if (busStopTypeFilters.length > 0) {
            const filteredFeatures = geoJsonData.features.filter((feature) =>
              busStopTypeFilters.includes(feature.properties.bus_stop_type)
            );
            geoJsonData = {
              type: 'FeatureCollection',
              features: filteredFeatures,
            };
            console.log(
              `Applied client-side filtering: ${busStopTypeFilters.join(', ')}`
            );
            console.log(
              `Number of features after filtering: ${geoJsonData.features.length}`
            );
          }
        } else if (layerName === 'Road Hierarchy') {
          // Handle Road Hierarchy layer with client-side filtering
          if (
            fetchedDataCache.current[layerInfo.cacheKey] &&
            isCacheValid(fetchedDataCache.current[layerInfo.cacheKey].timestamp)
          ) {
            console.log(`Using cached data for layer: ${layerName}`);
            geoJsonData = fetchedDataCache.current[layerInfo.cacheKey].data;
          } else {
            console.log(`Fetching Road Hierarchy from URL: ${layerInfo.url}`);
            const response = await fetch(layerInfo.url);
            if (!response.ok) {
              const errorText = await response.text();
              throw new Error(
                `Error ${response.status}: ${response.statusText} - ${errorText}`
              );
            }
            const data = await response.json();
            console.log(`Fetched data for ${layerName}:`, data);
            geoJsonData = transformDataToGeoJSON(data);
            console.log(
              `Transformed GeoJSON data for ${layerName}:`,
              geoJsonData
            );

            if (!geoJsonData || geoJsonData.type !== 'FeatureCollection') {
              throw new Error(
                `Invalid GeoJSON data for layer ${layerName}: ${JSON.stringify(
                  geoJsonData
                )}`
              );
            }
            // Cache the data
            fetchedDataCache.current[layerInfo.cacheKey] = {
              data: geoJsonData,
              timestamp: Date.now(),
            };
            console.log(`Data fetched and cached for layer: ${layerName}`);
          }

          // Apply client-side filtering based on Road Hierarchy Types
          if (roadHierarchyTypeFilters.length > 0) {
            const filteredFeatures = geoJsonData.features.filter((feature) =>
              roadHierarchyTypeFilters.includes(feature.properties.ovl2_cat)
            );
            geoJsonData = {
              type: 'FeatureCollection',
              features: filteredFeatures,
            };
            console.log(
              `Applied Road Hierarchy type filtering: ${roadHierarchyTypeFilters.join(
                ', '
              )}`
            );
            console.log(
              `Number of Road Hierarchy features after filtering: ${geoJsonData.features.length}`
            );
          }
        } else if (layerName === 'Train Stations') {
          // Handle Train Stations layer
          if (
            !fetchedDataCache.current[layerInfo.cacheKey] ||
            !isCacheValid(fetchedDataCache.current[layerInfo.cacheKey].timestamp)
          ) {
            console.log(`Fetching Train Stations from URL: ${layerInfo.url}`);
            const response = await fetch(layerInfo.url); // No query parameters
            if (!response.ok) {
              const errorText = await response.text();
              throw new Error(
                `Error ${response.status}: ${response.statusText} - ${errorText}`
              );
            }
            const data = await response.json();
            console.log(`Fetched data for ${layerName}:`, data);
            geoJsonData = transformDataToGeoJSON(data);
            if (!geoJsonData || geoJsonData.type !== 'FeatureCollection') {
              throw new Error(
                `Invalid GeoJSON data for layer ${layerName}: ${JSON.stringify(
                  geoJsonData
                )}`
              );
            }
            // Cache the data
            fetchedDataCache.current[layerInfo.cacheKey] = {
              data: geoJsonData,
              timestamp: Date.now(),
            };
            console.log(`Data fetched and cached for layer: ${layerName}`);
          } else {
            console.log(`Using cached data for layer: ${layerName}`);
            geoJsonData = fetchedDataCache.current[layerInfo.cacheKey].data;
          }
        }

        // Create and style the data layer
        if (window.google && window.google.maps) {
          const dataLayer = new window.google.maps.Data({ map });
          dataLayer.addGeoJson(geoJsonData);
          dataLayer.setStyle((feature) => {
            if (layerName === 'Bus Stops') {
              return {
                icon: {
                  url: 'https://maps.google.com/mapfiles/kml/shapes/bus.png', // Bus icon URL
                  scaledSize: new window.google.maps.Size(24, 24), // Adjust size as needed
                },
              };
            } else if (layerName === 'Road Hierarchy') {
              const roadType =
                feature.getProperty('ovl2_cat') ||
                feature.getProperty('road_hierarchy') ||
                feature.getProperty('road_type');
              let color;
              switch (roadType) {
                case 'INF_AR': // Arterial roads
                case 'Arterial Road':
                  color = '#0000FF';
                  break;
                case 'INF_DR': // District roads
                case 'District Road':
                  color = '#00FF00';
                  break;
                case 'INF_SR': // Suburban roads
                case 'Suburban Road':
                  color = '#FFA500';
                  break;
                case 'INF_MW': // Motorways
                case 'Motorway':
                  color = '#800080';
                  break;
                default:
                  color = '#808080'; // Default color for other types
              }
              return {
                strokeColor: color,
                strokeWeight: 2,
              };
            } else if (layerName === 'Train Stations') {
              return {
                icon: {
                  url: 'https://maps.google.com/mapfiles/kml/shapes/rail.png', // Train icon URL
                  scaledSize: new window.google.maps.Size(24, 24), // Adjust size as needed
                },
              };
            } else {
              return {}; // Default style for other layers
            }
          });

          dataLayerRefs.current[layerName] = dataLayer;
          console.log(`Data layer added to map: ${layerName}`);
        } else {
          throw new Error('Google Maps API is not available');
        }

        // After successful loading, update loading state
        setLoadingLayers((prev) => ({ ...prev, [layerName]: false }));
      } catch (error) {
        console.error(`Error loading layer ${layerName}:`, error);
        setLayerErrors((prev) => ({ ...prev, [layerName]: error.message }));
        setLoadingLayers((prev) => ({ ...prev, [layerName]: false }));
      }
    };

    /**
     * Function to unload a predefined layer from the map
     */
    const unloadLayer = (layerName) => {
      if (dataLayerRefs.current[layerName]) {
        dataLayerRefs.current[layerName].setMap(null);
        delete dataLayerRefs.current[layerName];
        console.log(`Data layer removed from map: ${layerName}`);
      }
    };

    // Get names of selected layers
    const selectedLayerNames = Object.keys(selectedLayers).filter(
      (layerName) => selectedLayers[layerName]
    );

    // Load selected layers
    selectedLayerNames.forEach((layerName) => {
      const layerInfo = availableLayers[layerName];
      if (layerInfo && !dataLayerRefs.current[layerName]) {
        loadLayer(layerName, layerInfo);
      } else if (layerInfo && dataLayerRefs.current[layerName]) {
        // Layer is already loaded, but we might need to update filters
        if (
          layerName === 'Bus Stops' ||
          layerName === 'Road Hierarchy' ||
          layerName === 'Train Stations'
        ) {
          dataLayerRefs.current[layerName].setMap(null);
          delete dataLayerRefs.current[layerName];
          loadLayer(layerName, layerInfo);
        }
      }
    });

    // Unload deselected layers
    Object.keys(dataLayerRefs.current).forEach((layerName) => {
      if (!selectedLayers[layerName]) {
        unloadLayer(layerName);
      }
    });

    // Cleanup function to remove all data layers on unmount
    return () => {
      Object.keys(dataLayerRefs.current).forEach((layerName) => {
        unloadLayer(layerName);
      });
    };
  }, [
    selectedLayers,
    availableLayers,
    isLoaded,
    busStopTypeFilters,
    roadHierarchyTypeFilters,
    transformDataToGeoJSON,
    setLayerErrors,
    setLoadingLayers,
    fetchedDataCache,
    mapRef,
  ]);

  return null; // This component does not render anything
};

LayersManager.propTypes = {
  mapRef: PropTypes.object.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  selectedLayers: PropTypes.object.isRequired,
  availableLayers: PropTypes.object.isRequired,
  busStopTypeFilters: PropTypes.array.isRequired,
  roadHierarchyTypeFilters: PropTypes.array.isRequired,
  setLoadingLayers: PropTypes.func.isRequired,
  setLayerErrors: PropTypes.func.isRequired,
  transformDataToGeoJSON: PropTypes.func.isRequired,
  fetchedDataCache: PropTypes.object.isRequired,
};

export default LayersManager;
