// src/components/ProjectBacklog/EditTaskModal.js

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import TaskForm from './TaskForm';

/**
 * Modal component for editing an existing Task or Phase.
 * Utilizes the reusable TaskForm component to handle form fields.
 */
const EditTaskModal = ({
  show,
  onHide,
  onEditTask,
  selectedTask,
  tasks,
  projectUsers, // Pass projectUsers to allow assignee selection
}) => {
  /**
   * Handles the submission of the edited task data.
   * @param {Object} editedTaskData - The data from the form after editing.
   */
  const handleEditTask = async (editedTaskData) => {
    // Prepare the task object to be updated in Firestore
    const taskToUpdate = {
      ...editedTaskData,
      id: selectedTask.id, // Ensure the task ID is included
    };

    await onEditTask(taskToUpdate);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="edit-task-modal">
      <Modal.Header closeButton>
        <Modal.Title id="edit-task-modal">Edit Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TaskForm
          initialData={selectedTask} // Pass the selected task for pre-filling fields
          tasks={tasks} // All tasks for dependencies
          projectUsers={projectUsers} // All project users for the assignee field
          onSubmit={handleEditTask}
          isEditMode={true} // Indicates we're editing a task
        />
      </Modal.Body>
    </Modal>
  );
};

// PropTypes validation to ensure correct prop types are passed
EditTaskModal.propTypes = {
  show: PropTypes.bool.isRequired,               // Controls modal visibility
  onHide: PropTypes.func.isRequired,             // Function to close the modal
  onEditTask: PropTypes.func.isRequired,         // Function to handle task editing
  selectedTask: PropTypes.object.isRequired,     // The task object to be edited
  tasks: PropTypes.array.isRequired,             // Array of all tasks in the project
  projectUsers: PropTypes.array.isRequired,      // Array of user details in the project
};

export default EditTaskModal;
