import React from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';

const VerifyEmail = () => (
  <Container className="mt-5">
    <Row>
      <Col md={6} className="mx-auto">
        <h2 className="text-center mb-4">Email Verification</h2>
        <Alert variant="warning">
          Please verify your email address to access the site. A verification link has been sent to your email.
        </Alert>
      </Col>
    </Row>
  </Container>
);

export default VerifyEmail;
