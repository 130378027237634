import React, { useState, useContext } from 'react';
import { Button, Alert, Form, Row, Col } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { doc, updateDoc, arrayUnion, addDoc, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firestore } from '../../../firebase';
import { AuthContext } from '../../../context/auth/AuthContext';
import CreationSuccess from '../components/CompanyCreationSuccess';
import CompanyForm from './CompanyForm';
import FileUpload from './FileUpload'; // Optional, uncomment if used
import AddressSuggestions from '../../../components/SharedFunctions/AddressValidation';

const AddCompany = () => {
  const { currentUser } = useContext(AuthContext);

  const [companyData, setCompanyData] = useState({
    companyName: '',
    companyTradeType: '',
    companyNumber: '',
    companyABN: '',
    companyBio: '',
    websiteLink: '',
    QBCCLicense: '',
    companyAddress: '',
    contactDetails: {
      email: '',
      phone: '',
    },
    profileCreator: currentUser ? currentUser.uid : null,
    employees: currentUser ? [{ uid: currentUser.uid, role: 'Admin' }] : [],
  });

  const [companyLogo, setCompanyLogo] = useState(null);
  const [message, setMessage] = useState({ type: '', content: '' });
  const [errors, setErrors] = useState({});
  const [isCreated, setIsCreated] = useState(false);

  const tradeTypes = [
    'Property Developer',
    'Architect',
    'Builder',
    'Electrician',
    'Plumber',
    'Surveyor',
    'Carpenter',
    'Project Manager',
    'Civil Engineer',
    'Quantity Surveyor',
    'Interior Designer',
    'Landscape Architect',
    'Structural Engineer',
    'Building Inspector',
    'Legal Advisor',
    'Financial Advisor',
    'Real Estate Agent',
    'IT Specialist',
    'Sustainability Consultant',
    'Demolition Contractor',
    'Roofing Contractor',
    'Flooring Specialist',
    'Painting Contractor',
    'Waste Management Specialist',
    'Compliance Officer',
    'Urban Planner',
    'Marketing Specialist',
    'Construction Manager',
    'Health and Safety Officer'
  ];

  const validateForm = () => {
    const newErrors = {};

    if (!companyData.companyName.trim()) {
      newErrors.companyName = 'Company name is required';
    }
    if (!companyData.companyTradeType.trim()) {
      newErrors.companyTradeType = 'Please select a trade type';
    }
    if (!companyData.companyABN.trim()) {
      newErrors.companyABN = 'ABN is required';
    } else if (!/^[0-9]{11}$/.test(companyData.companyABN)) {
      newErrors.companyABN = 'Please enter a valid 11-digit ABN';
    }
    if (!companyData.contactDetails.email.includes('@')) {
      newErrors.email = 'Please enter a valid email address';
    }
    if (!companyData.contactDetails.phone.trim()) {
      newErrors.phone = 'Contact number is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (field, value) => {
    setCompanyData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleContactDetailsChange = (field, value) => {
    setCompanyData((prevData) => ({
      ...prevData,
      contactDetails: {
        ...prevData.contactDetails,
        [field]: value,
      },
    }));
  };

  const saveCompanyProfile = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      if (!currentUser) {
        throw new Error('User is not authenticated');
      }

      let logoUrl = '';
      if (companyLogo) {
        const logoRef = ref(getStorage(), `companyLogos/${companyData.companyName}/${companyLogo.name}`);
        await uploadBytes(logoRef, companyLogo);
        logoUrl = await getDownloadURL(logoRef);
      }

      const updatedCompanyData = {
        ...companyData,
        companyLogoUrl: logoUrl,
      };

      const docRef = await addDoc(collection(firestore, 'companies'), updatedCompanyData);

      if (docRef) {
        const companyId = docRef.id;
        await updateDoc(doc(firestore, 'companies', companyId), {
          employees: arrayUnion({ uid: currentUser.uid, role: 'Admin' }),
        });

        setMessage({ type: 'success', content: 'Company profile added successfully!' });
        setIsCreated(true);
      } else {
        setMessage({ type: 'error', content: 'Error adding company profile.' });
      }
    } catch (error) {
      setMessage({ type: 'error', content: `Error adding company profile: ${error.message}` });
    }
  };

  const handleLogoUpload = (e) => {
    if (e.target.files[0]) {
      setCompanyLogo(e.target.files[0]);
    }
  };

  return (
    <>
      {message.content && (
        <Alert variant={message.type === 'success' ? 'success' : 'danger'}>
          {message.content}
        </Alert>
      )}
      {isCreated ? (
        <CreationSuccess />
      ) : (
        <Form className="p-4 rounded shadow-sm bg-light">
          <h3 className="mb-4">Add New Company</h3>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formCompanyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter company name"
                value={companyData.companyName}
                onChange={(e) => handleInputChange('companyName', e.target.value)}
                isInvalid={!!errors.companyName}
              />
              <Form.Control.Feedback type="invalid">{errors.companyName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="formCompanyTradeType">
              <Form.Label>Trade Type</Form.Label>
              <Typeahead
                id="trade-type-typeahead"
                labelKey="tradeType"
                onChange={(selected) => handleInputChange('companyTradeType', selected[0] || '')}
                options={tradeTypes}
                placeholder="Select a trade type"
                selected={companyData.companyTradeType ? [companyData.companyTradeType] : []}
                isInvalid={!!errors.companyTradeType}
              />
              <Form.Control.Feedback type="invalid">{errors.companyTradeType}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formCompanyNumber">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter contact number"
                value={companyData.contactDetails.phone}
                onChange={(e) => handleContactDetailsChange('phone', e.target.value)}
                isInvalid={!!errors.phone}
              />
              <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="formCompanyABN">
              <Form.Label>Company ABN</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter company ABN"
                value={companyData.companyABN}
                onChange={(e) => handleInputChange('companyABN', e.target.value)}
                isInvalid={!!errors.companyABN}
              />
              <Form.Control.Feedback type="invalid">{errors.companyABN}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="formCompanyEmail">
            <Form.Label>Contact Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter contact email"
              value={companyData.contactDetails.email}
              onChange={(e) => handleContactDetailsChange('email', e.target.value)}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formCompanyWebsite">
            <Form.Label>Website</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter website URL"
              value={companyData.websiteLink}
              onChange={(e) => handleInputChange('websiteLink', e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formCompanyAddress">
            <Form.Label>Company Address</Form.Label>
            <AddressSuggestions
              setAddress={(address) => handleInputChange('companyAddress', address)}
              subtitle="Please enter your address"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formCompanyBio">
            <Form.Label>Company Bio</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Write a brief bio of the company"
              value={companyData.companyBio}
              onChange={(e) => handleInputChange('companyBio', e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formCompanyLogo">
            <Form.Label>Upload Logo</Form.Label>
            <Form.Control type="file" onChange={handleLogoUpload} />
          </Form.Group>

          <Button variant="primary" className="mt-3" onClick={saveCompanyProfile}>Save Company</Button>
        </Form>
      )}
    </>
  );
};

export default AddCompany;