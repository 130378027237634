import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Spinner,
  Alert,
  Button,
  Modal,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { useLoadScript } from '@react-google-maps/api';
import { AuthContext } from '../../context/auth/AuthContext';
import ErrorBoundary from './ErrorBoundary';
import MapComponent from './MapComponent';
import CustomLayers from './CustomLayers';

const HuntingGroundMaps = () => {
  const [selectedLayers, setSelectedLayers] = useState({});
  const [loadingLayers, setLoadingLayers] = useState({});
  const [layerErrors, setLayerErrors] = useState({});
  const { isSubscribed } = useContext(AuthContext);

  // State for Bus Stop Type Filters
  const [busStopTypeFilters, setBusStopTypeFilters] = useState([]);
  const busStopTypes = [
    '1 - Premium',
    '4 - Regular',
    'Interchange',
    'Busway Station',
    'Railway Station',
  ];

  // State for Road Hierarchy Type Filters
  const [roadHierarchyTypeFilters, setRoadHierarchyTypeFilters] = useState([]);
  const roadHierarchyTypes = [
    { code: 'INF_AR', label: 'Arterial Roads (INF_AR)' },
    { code: 'INF_DR', label: 'District Roads (INF_DR)' },
    { code: 'INF_SR', label: 'Suburban Roads (INF_SR)' },
    { code: 'INF_MW', label: 'Motorways (INF_MW)' },
  ];

  const [customLayers, setCustomLayers] = useState([]);
  const [isDrawingMode, setIsDrawingMode] = useState(false);

  // State for Service Selection Modal
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [serviceRatings, setServiceRatings] = useState({}); // { serviceName: minRating }
  const [services, setServices] = useState([
    'Gym',
    'Shopping Centre',
    'School',
    // Add more default services as needed
  ]);

  // State to trigger the service search in MapComponent
  const [serviceSearchTrigger, setServiceSearchTrigger] = useState(0);

  const FIREBASE_FUNCTION_URL =
    'https://us-central1-lunar8platform.cloudfunctions.net/api';

  const layerUrls = useMemo(
    () => ({
      'Bus Stops': {
        url: `${FIREBASE_FUNCTION_URL}/proxy/brisbane-bus-stops`,
        type: 'api',
        cacheKey: 'busStops',
      },
      'Road Hierarchy': {
        url: `${FIREBASE_FUNCTION_URL}/proxy/road-hierarchy`,
        type: 'api',
        cacheKey: 'roadHierarchy',
      },
      'Train Stations': {
        url: `${FIREBASE_FUNCTION_URL}/proxy/brisbane-train-stations`,
        type: 'api',
        cacheKey: 'trainStations',
      },
      // Add more layers as needed
    }),
    [FIREBASE_FUNCTION_URL]
  );

  const availableLayers = useMemo(() => {
    if (isSubscribed) {
      return layerUrls;
    } else {
      return {
        'Bus Stops': layerUrls['Bus Stops'],
        'Road Hierarchy': layerUrls['Road Hierarchy'],
        'Train Stations': layerUrls['Train Stations'],
        // Include only free layers or those accessible to non-subscribed users
      };
    }
  }, [isSubscribed, layerUrls]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['drawing', 'geometry', 'places'],
  });

  const onLoad = useCallback((map) => {
    console.log('Google Map loaded');
  }, []);

  const handleLayerToggle = useCallback((layerName) => {
    console.log(`Toggling layer: ${layerName}`);
    setSelectedLayers((prev) => ({
      ...prev,
      [layerName]: !prev[layerName],
    }));

    // Clear previous errors when toggling
    setLayerErrors((prev) => ({
      ...prev,
      [layerName]: null,
    }));
  }, []);

  const handleBusStopTypeToggle = (type) => {
    setBusStopTypeFilters((prev) => {
      if (prev.includes(type)) {
        return prev.filter((t) => t !== type);
      } else {
        return [...prev, type];
      }
    });
  };

  const handleRoadHierarchyTypeToggle = (typeCode) => {
    setRoadHierarchyTypeFilters((prev) => {
      if (prev.includes(typeCode)) {
        return prev.filter((t) => t !== typeCode);
      } else {
        return [...prev, typeCode];
      }
    });
  };

  const handleToggleVisibility = (index) => {
    setCustomLayers((prevLayers) =>
      prevLayers.map((layer, i) =>
        i === index ? { ...layer, visible: !layer.visible } : layer
      )
    );
  };

  const handleDeleteLayer = (index) => {
    setCustomLayers((prevLayers) => {
      const layerToRemove = prevLayers[index];
      // Remove overlay from map
      layerToRemove.overlay.setMap(null);
      if (layerToRemove.radiusLabel) {
        layerToRemove.radiusLabel.setMap(null);
      }
      return prevLayers.filter((_, i) => i !== index);
    });
  };

  /**
   * Handle updating the style (strokeColor, fillColor, opacity) of a custom layer
   */
  const handleUpdateStyle = (index, newStyle) => {
    setCustomLayers((prevLayers) =>
      prevLayers.map((layer, i) =>
        i === index
          ? {
              ...layer,
              strokeColor: newStyle.strokeColor,
              fillColor: newStyle.fillColor,
              opacity: newStyle.opacity,
            }
          : layer
      )
    );
  };

  /**
   * Handle updating the layer name
   */
  const handleUpdateLayerName = (index, newName) => {
    setCustomLayers((prevLayers) =>
      prevLayers.map((layer, i) =>
        i === index ? { ...layer, name: newName } : layer
      )
    );
  };

  /**
   * Handle updating the layer radius
   */
  const handleUpdateLayerRadius = (index, newRadius) => {
    setCustomLayers((prevLayers) =>
      prevLayers.map((layer, i) => {
        if (i === index && layer.type === 'circle') {
          layer.overlay.setRadius(newRadius);
          return {
            ...layer,
            radius: newRadius,
          };
        }
        return layer;
      })
    );
  };

  /**
   * Handle toggling the "No Fill" option
   */
  const handleToggleNoFill = (index) => {
    setCustomLayers((prevLayers) =>
      prevLayers.map((layer, i) => {
        if (i === index) {
          return {
            ...layer,
            noFill: !layer.noFill,
          };
        }
        return layer;
      })
    );
  };

  // Cache for fetched data with timestamps
  const fetchedDataCache = useRef({});

  // Memoized function to transform API data to GeoJSON
  const transformDataToGeoJSON = useCallback((data) => {
    // Since data is already in GeoJSON format, we can return it directly
    if (data.type === 'FeatureCollection') {
      return data;
    }

    // For other data formats, handle accordingly
    // ... existing logic ...

    return null;
  }, []);

  // Handle opening the Service Selection Modal
  const handleOpenServiceModal = () => {
    setShowServiceModal(true);
  };

  // Handle closing the Service Selection Modal
  const handleCloseServiceModal = () => {
    setShowServiceModal(false);
  };

  // Handle selecting services
  const handleServiceToggle = (service) => {
    setSelectedServices((prev) => {
      if (prev.includes(service)) {
        const newSelected = prev.filter((s) => s !== service);
        const newRatings = { ...serviceRatings };
        delete newRatings[service];
        setServiceRatings(newRatings);
        return newSelected;
      } else {
        return [...prev, service];
      }
    });
  };

  // Handle minimum rating change
  const handleServiceRatingChange = (service, rating) => {
    setServiceRatings((prev) => ({
      ...prev,
      [service]: rating,
    }));
  };

  // Handle adding a new custom service
  const [newService, setNewService] = useState('');
  const handleAddCustomService = () => {
    if (newService.trim() !== '' && !services.includes(newService.trim())) {
      setServices((prev) => [...prev, newService.trim()]);
      setNewService('');
    }
  };

  // Handle "Go" button click
  const handleServiceSearch = () => {
    // Close the modal
    setShowServiceModal(false);

    // Perform the search and computation
    // For simplicity, we'll pass this task to the MapComponent
    // Set the selected services in a state that MapComponent can access
    setServiceSearchTrigger((prev) => prev + 1); // Trigger the effect in MapComponent
  };

  if (loadError) {
    console.error('Error loading Google Maps script:', loadError);
    return (
      <Alert variant="danger">
        Error loading Google Maps. Please try again later.
      </Alert>
    );
  }

  return (
    <ErrorBoundary>
      <Container fluid>
        <Row>
          <Col md={2} className="border">
            <h4>Select Layers</h4>
            <Form>
              {Object.keys(availableLayers).map((layerName) => (
                <div key={layerName}>
                  <Form.Check
                    type="checkbox"
                    label={layerName}
                    checked={!!selectedLayers[layerName]}
                    onChange={() => handleLayerToggle(layerName)}
                    disabled={loadingLayers[layerName]}
                  />
                  {layerName === 'Bus Stops' && selectedLayers[layerName] && (
                    <div style={{ marginLeft: '20px' }}>
                      {busStopTypes.map((type) => (
                        <Form.Check
                          key={type}
                          type="checkbox"
                          label={type}
                          checked={busStopTypeFilters.includes(type)}
                          onChange={() => handleBusStopTypeToggle(type)}
                        />
                      ))}
                    </div>
                  )}
                  {layerName === 'Road Hierarchy' &&
                    selectedLayers[layerName] && (
                      <div style={{ marginLeft: '20px' }}>
                        {roadHierarchyTypes.map((type) => (
                          <Form.Check
                            key={type.code}
                            type="checkbox"
                            label={type.label}
                            checked={roadHierarchyTypeFilters.includes(
                              type.code
                            )}
                            onChange={() =>
                              handleRoadHierarchyTypeToggle(type.code)
                            }
                          />
                        ))}
                      </div>
                    )}
                </div>
              ))}
            </Form>
            {/* Show loading indicators for layers */}
            {Object.keys(loadingLayers).some((layer) => loadingLayers[layer]) && (
              <div className="mt-2">
                <Spinner animation="border" size="sm" /> Loading layers...
              </div>
            )}
            {/* Show error messages for layers */}
            {Object.keys(layerErrors).map(
              (layerName) =>
                layerErrors[layerName] && (
                  <Alert key={layerName} variant="danger" className="mt-2">
                    Error loading {layerName}: {layerErrors[layerName]}
                  </Alert>
                )
            )}
            <hr />
            <Button
              variant="primary"
              onClick={() => setIsDrawingMode((prev) => !prev)}
              className="mb-2"
            >
              {isDrawingMode ? 'Cancel Drawing' : 'Add Custom Layer'}
            </Button>
            <CustomLayers
              customLayers={customLayers}
              onToggleVisibility={handleToggleVisibility}
              onDeleteLayer={handleDeleteLayer}
              onUpdateStyle={handleUpdateStyle}
              onUpdateLayerName={handleUpdateLayerName}
              onUpdateLayerRadius={handleUpdateLayerRadius} // New prop
              onToggleNoFill={handleToggleNoFill} // New prop
            />
            <hr />
            <Button
              variant="success"
              onClick={handleOpenServiceModal}
              className="mb-2"
            >
              Find Best Areas
            </Button>
          </Col>
          <Col md={10}>
            {!isLoaded ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '600px' }}
              >
                <Spinner animation="border" />
              </div>
            ) : (
              <MapComponent
                isLoaded={isLoaded}
                loadError={loadError}
                onLoad={onLoad}
                selectedLayers={selectedLayers}
                availableLayers={availableLayers}
                busStopTypeFilters={busStopTypeFilters}
                roadHierarchyTypeFilters={roadHierarchyTypeFilters} // Pass the new road hierarchy filters
                setLoadingLayers={setLoadingLayers}
                setLayerErrors={setLayerErrors}
                transformDataToGeoJSON={transformDataToGeoJSON}
                customLayers={customLayers}
                setCustomLayers={setCustomLayers}
                isDrawingMode={isDrawingMode}
                setIsDrawingMode={setIsDrawingMode}
                fetchedDataCache={fetchedDataCache}
                selectedServices={selectedServices}
                serviceRatings={serviceRatings}
                serviceSearchTrigger={serviceSearchTrigger}
              />
            )}
          </Col>
        </Row>

        {/* Service Selection Modal */}
        <Modal show={showServiceModal} onHide={handleCloseServiceModal}>
          <Modal.Header closeButton>
            <Modal.Title>Select Services</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {services.map((service) => (
                <div key={service} className="mb-2">
                  <Form.Check
                    type="checkbox"
                    label={service}
                    checked={selectedServices.includes(service)}
                    onChange={() => handleServiceToggle(service)}
                  />
                  {selectedServices.includes(service) && (
                    <Form.Group controlId={`rating-${service}`}>
                      <Form.Label>Minimum Rating:</Form.Label>
                      <Form.Control
                        type="number"
                        min="1"
                        max="5"
                        step="0.1"
                        value={serviceRatings[service] || ''}
                        onChange={(e) =>
                          handleServiceRatingChange(
                            service,
                            parseFloat(e.target.value)
                          )
                        }
                      />
                    </Form.Group>
                  )}
                </div>
              ))}
              {/* Add custom service */}
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Add custom service"
                  value={newService}
                  onChange={(e) => setNewService(e.target.value)}
                />
                <Button variant="outline-secondary" onClick={handleAddCustomService}>
                  Add
                </Button>
              </InputGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseServiceModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleServiceSearch}>
              Go
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </ErrorBoundary>
  );
};

export default React.memo(HuntingGroundMaps);
