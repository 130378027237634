import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { AuthContext } from '../context/auth/AuthContext';
import {
  Button,
  Form,
  Alert,
  Container,
  Row,
  Col,
  Card,
  Spinner,
  InputGroup,
} from 'react-bootstrap';
import { auth, firestore } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';

const T_AND_C_VERSION = '1.0';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [inForgotPasswordMode, setInForgotPasswordMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const authContext = useContext(AuthContext) || {
    currentUser: null,
    setCurrentUser: () => {},
  };
  const { setCurrentUser } = authContext;

  const navigate = useNavigate();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const mapFirebaseErrors = (errorCode) => {
    switch (errorCode) {
      case 'auth/invalid-email':
        return 'The email address is invalid.';
      case 'auth/user-disabled':
        return 'This user has been disabled. Please contact support.';
      case 'auth/user-not-found':
        return 'No user found with this email.';
      case 'auth/wrong-password':
        return 'Incorrect password. Please try again.';
      case 'auth/too-many-requests':
        return 'Too many attempts. Please try again later.';
      default:
        return 'An unexpected error occurred. Please try again.';
    }
  };

  const loginWithEmail = async () => {
    if (!email || !password) {
      setErrorMessage('Email and password cannot be empty.');
      return;
    }
    setLoading(true);
    try {
      await setPersistence(auth, browserSessionPersistence);
      const result = await signInWithEmailAndPassword(auth, email, password);
      if (!result.user.emailVerified) {
        setErrorMessage('Please verify your email before logging in.');
        await auth.signOut();
        return;
      }
      setCurrentUser(result.user);
      navigate('/projects-dashboard');
    } catch (error) {
      console.error('Login failed:', error.code, error.message);
      const friendlyError = mapFirebaseErrors(error.code);
      setErrorMessage(friendlyError);
    } finally {
      setLoading(false);
    }
  };

  const loginWithGoogle = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userRef = doc(firestore, 'users', user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        await setDoc(userRef, {
          email: user.email,
          firstName: user.displayName || '',
          lastName: '',
          role: 'user',
          termsAcceptedVersion: T_AND_C_VERSION,
          emailVerified: true,
        });
      }

      setCurrentUser(user);
      navigate('/projects-dashboard');
    } catch (error) {
      console.error('Google login failed:', error.code, error.message);
      const friendlyError = mapFirebaseErrors(error.code);
      setErrorMessage(friendlyError);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setErrorMessage('Please enter your email address.');
      return;
    }
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage('Password reset email sent! Check your inbox.');
      setErrorMessage(null);
      setInForgotPasswordMode(false);
    } catch (error) {
      console.error('Password reset failed:', error.code, error.message);
      setErrorMessage('Failed to send password reset email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const renderLoginForm = () => (
    <>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="email">Email address</Form.Label>
        <Form.Control
          id="email"
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="password">Password</Form.Label>
        <InputGroup>
          <Form.Control
            id="password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="outline-secondary" className="small-btn" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? 'Hide' : 'Show'}
          </Button>
        </InputGroup>
      </Form.Group>
      <Button variant="primary" onClick={loginWithEmail} className="w-100 mb-2" disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : 'Login'}
      </Button>
      <Button variant="danger" onClick={loginWithGoogle} className="w-100 mb-2" disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : 'Login with Google'}
      </Button>
      <Button variant="link" onClick={() => setInForgotPasswordMode(true)} className="w-100">
        Forgot Password?
      </Button>
      <div className="text-center mt-3">
        No account yet? <Link to="/signup">Click to Sign up!</Link>
      </div>
    </>
  );

  const renderForgotPasswordForm = () => (
    <>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="email">Email address</Form.Label>
        <Form.Control
          id="email"
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Button
        variant="primary"
        onClick={handleForgotPassword}
        className="w-100 mb-2"
        disabled={loading}
      >
        {loading ? <Spinner animation="border" size="sm" /> : 'Reset Password'}
      </Button>
      <Button variant="link" onClick={() => setInForgotPasswordMode(false)} className="w-100">
        Back to Login
      </Button>
    </>
  );

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} sm={10} md={8} lg={6}>
          <Card className="p-4">
            <h2 className="text-center mb-4">
              {inForgotPasswordMode ? 'Reset Password' : 'Login'}
            </h2>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            <Form>
              {inForgotPasswordMode ? renderForgotPasswordForm() : renderLoginForm()}
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
