// src/components/TemplateImporter.js
import React, { useState } from 'react';
import { ListGroup, Row, Col, Form, Button } from 'react-bootstrap';

const TemplateImporter = ({
  templates,
  onImport,
  isAdmin,
}) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleImport = () => {
    if (!selectedTemplateId) {
      setError('Please select a template to import.');
      setSuccess('');
      return;
    }
    onImport(selectedTemplateId, () => {
      setSuccess('Template tasks imported successfully.');
      setError('');
    }, (errMsg) => {
      setError(errMsg);
      setSuccess('');
    });
    setSelectedTemplateId('');
  };

  if (!isAdmin) return null;

  return (
    <ListGroup.Item className="mb-3">
      <strong>Import Template Tasks:</strong>
      <Row className="align-items-center mt-2">
        <Col xs={12} md={6}>
          <Form.Select
            value={selectedTemplateId}
            onChange={(e) => setSelectedTemplateId(e.target.value)}
          >
            <option value="">Select a template</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col xs={12} md={6} className="mt-2 mt-md-0">
          <Button
            variant="primary"
            onClick={handleImport}
            disabled={!selectedTemplateId}
          >
            Import Template
          </Button>
        </Col>
      </Row>
      {error && <div className="text-danger mt-2">{error}</div>}
      {success && <div className="text-success mt-2">{success}</div>}
    </ListGroup.Item>
  );
};

export default TemplateImporter;
