// src/components/ProjectNameEditor.js
import React, { useState } from 'react';
import { ListGroup, Button, Form } from 'react-bootstrap';

const ProjectNameEditor = ({
  projectName,
  isAdmin,
  onSave,
  onCancel,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newProjectName, setNewProjectName] = useState(projectName);
  const [error, setError] = useState('');

  const handleEdit = () => {
    setIsEditing(true);
    setError('');
  };

  const handleCancel = () => {
    setIsEditing(false);
    setNewProjectName(projectName);
    setError('');
    if (onCancel) onCancel();
  };

  const handleSave = () => {
    if (!newProjectName.trim()) {
      setError('Project name cannot be empty.');
      return;
    }
    onSave(newProjectName.trim(), () => setIsEditing(false), setError);
  };

  return (
    <ListGroup.Item className="mb-3">
      <strong>Project Name:</strong>{' '}
      {!isEditing ? (
        <>
          {projectName}{' '}
          {isAdmin && (
            <Button
              variant="link"
              onClick={handleEdit}
              className="p-0 ms-2"
            >
              Edit
            </Button>
          )}
        </>
      ) : (
        <>
          <Form.Control
            type="text"
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
            placeholder="Enter new project name"
            className="d-inline-block w-auto me-2"
          />
          <Button
            variant="success"
            size="sm"
            onClick={handleSave}
            className="me-2"
          >
            Save
          </Button>
          <Button
            variant="secondary"
            size="sm"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          {error && <div className="text-danger mt-2">{error}</div>}
        </>
      )}
    </ListGroup.Item>
  );
};

export default ProjectNameEditor;
