import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, where, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import { Button, Alert, Spinner, ListGroup, Container } from 'react-bootstrap';
import { auth } from '../../firebase';  // Import auth from firebase

const JobQuotes = ({ jobId, jobCreatorId }) => {
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getFirestore();
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (!jobId) {
      setError('Job ID is not defined');
      setLoading(false);
      return;
    }

    const fetchQuotes = async () => {
      try {
        const quotesQuery = query(collection(db, 'quotes'), where('jobId', '==', jobId));
        const quotesSnapshot = await getDocs(quotesQuery);
        const quotesData = quotesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setQuotes(quotesData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchQuotes();
  }, [jobId, db]);

  const handleAcceptQuote = async (quoteId) => {
    try {
      await updateDoc(doc(db, 'quotes', quoteId), {
        status: 'accepted',
        updatedAt: serverTimestamp(),
      });
      const quotesQuery = query(collection(db, 'quotes'), where('jobId', '==', jobId));
      const quotesSnapshot = await getDocs(quotesQuery);
      const quotesData = quotesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setQuotes(quotesData);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeclineQuote = async (quoteId) => {
    try {
      await updateDoc(doc(db, 'quotes', quoteId), {
        status: 'declined',
        updatedAt: serverTimestamp(),
      });
      const quotesQuery = query(collection(db, 'quotes'), where('jobId', '==', jobId));
      const quotesSnapshot = await getDocs(quotesQuery);
      const quotesData = quotesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setQuotes(quotesData);
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Container>
      <h3>Quotes Received</h3>
      {quotes.length === 0 ? (
        <Alert variant="info">No quotes yet</Alert>
      ) : (
        <ListGroup>
          {quotes.map((quote) => (
            <ListGroup.Item className='margin-top-15' key={quote.id}>
              <p><strong>Last Updated At:</strong> {quote.updatedAt?.toDate().toLocaleString()}</p>
              <p>Price ${quote.quote}</p>
              <p>What's Included: {quote.description}</p>
              <p>Status: {quote.status}</p>
              {quote.status === 'accepted' && (
                <p><strong>Accepted by:</strong> {quote.userId}</p>
              )}
              {(currentUser && currentUser.uid === jobCreatorId) && (
                <>
                  <Button variant="success" onClick={() => handleAcceptQuote(quote.id)} disabled={quote.status === 'accepted'}>
                    Accept
                  </Button>
                  <Button variant="danger" onClick={() => handleDeclineQuote(quote.id)} disabled={quote.status === 'declined'}>
                    Decline
                  </Button>
                </>
              )}
              {(currentUser && currentUser.uid === quote.userId) && (
                <p><strong>Your Quote Status:</strong> {quote.status}</p>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>
  );
};

export default JobQuotes;
