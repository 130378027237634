// src/components/ProjectBacklog/AddTaskModal.js

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import TaskForm from './TaskForm';

/**
 * Modal component for adding a new Task or Phase.
 */
const AddTaskModal = ({
  show,
  onHide,
  onAddTask,
  tasks,
  projectUsers, // New prop to pass project users
  preSelectedPhaseId,
}) => {
  const handleAddTask = async (newTaskData) => {
    // Prepare the task object to be added to Firestore
    const taskToAdd = {
      ...newTaskData,
      // Additional fields can be added here if necessary
    };

    await onAddTask(taskToAdd);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="add-task-modal">
      <Modal.Header closeButton>
        <Modal.Title id="add-task-modal">Add Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TaskForm
          initialData={{
            phaseId: preSelectedPhaseId || '',
          }}
          tasks={tasks}
          projectUsers={projectUsers}
          onSubmit={handleAddTask}
          isEditMode={false}
        />
      </Modal.Body>
    </Modal>
  );
};

// PropTypes validation
AddTaskModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onAddTask: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,
  projectUsers: PropTypes.array.isRequired, // Ensure this prop is passed
  preSelectedPhaseId: PropTypes.string,
};

export default AddTaskModal;
