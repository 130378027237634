// src/components/CustomLayers.js

import React, { useState } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Modal,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Trash, EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

const CustomLayers = ({
  customLayers,
  onToggleVisibility,
  onDeleteLayer,
  onUpdateStyle,
  onUpdateLayerName,
  onUpdateLayerRadius,
  onToggleNoFill,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [layerToDelete, setLayerToDelete] = useState(null);

  const handleDeleteClick = (index) => {
    setLayerToDelete(index);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (layerToDelete !== null) {
      onDeleteLayer(layerToDelete);
      setLayerToDelete(null);
      setShowDeleteModal(false);
    }
  };

  const handleCancelDelete = () => {
    setLayerToDelete(null);
    setShowDeleteModal(false);
  };

  return (
    <>
      <h4 className="mb-3">Custom Layers</h4>
      {customLayers.length === 0 ? (
        <p>No custom layers created.</p>
      ) : (
        <Form>
          {customLayers.map((layer, index) => (
            <div key={index} className="p-2 border rounded mb-2">
              <Row className="align-items-center">
                <Col xs={8}>
                  <InputGroup>
                  <Button
                    variant="link"
                    size="sm"
                    onClick={() => handleDeleteClick(index)}
                    aria-label="Delete Layer"
                  >
                    <Trash color="red" size={20} />
                  </Button>
                    <FormControl
                      type="text"
                      value={layer.name}
                      onChange={(e) => onUpdateLayerName(index, e.target.value)}
                      placeholder={`Layer ${index + 1}`}
                      aria-label="Layer Name"
                      size="md"
                    />
                  </InputGroup>
                </Col>
                <Col xs={4} className="text-right">
                  
                  <Button
                      variant="link"
                      size="sm"
                      onClick={() => onToggleVisibility(index)}
                      aria-label="Toggle Visibility"
                    >
                      {layer.visible ? (
                        <EyeFill size={20} />
                      ) : (
                        <EyeSlashFill size={20} />
                      )}
                    </Button>
                </Col>
              </Row>

              {/* Radius input for circles */}
              {layer.type === 'circle' && (
                <Row className="mt-1">
                  <Col xs={12}>
                    <Form.Label className="small">Radius (meters):</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      size="sm"
                      value={Math.round(layer.radius)}
                      onChange={(e) => onUpdateLayerRadius(index, parseFloat(e.target.value) || 0)}
                      aria-label="Radius"
                    />
                  </Col>
                </Row>
              )}

              <Row className="mt-1">
                <Col xs={6}>
                  <Form.Label className="small">Stroke Color:</Form.Label>
                  <Form.Control
                    type="color"
                    size="sm"
                    value={layer.strokeColor}
                    onChange={(e) =>
                      onUpdateStyle(index, {
                        strokeColor: e.target.value,
                        fillColor: layer.fillColor,
                        opacity: layer.opacity,
                      })
                    }
                    aria-label="Stroke Color"
                  />
                </Col>
                <Col xs={6}>
                  <Form.Label className="small">Fill Color:</Form.Label>
                  <Form.Control
                    type="color"
                    size="sm"
                    value={layer.fillColor}
                    disabled={layer.noFill}
                    onChange={(e) =>
                      onUpdateStyle(index, {
                        strokeColor: layer.strokeColor,
                        fillColor: e.target.value,
                        opacity: layer.opacity,
                      })
                    }
                    aria-label="Fill Color"
                  />
                  <Form.Check
                    type="checkbox"
                    label="No Fill"
                    checked={layer.noFill}
                    onChange={() => onToggleNoFill(index)}
                    aria-label="No Fill"
                    className="small"
                  />
                </Col>
              </Row>

              <Row className="mt-1">
                <Col xs={12}>
                  <Form.Label className="small">Transparency (%):</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    max="100"
                    size="sm"
                    value={Math.round(layer.opacity * 100)}
                    onChange={(e) => {
                      const value = Math.min(Math.max(parseFloat(e.target.value) || 0, 0), 100);
                      onUpdateStyle(index, {
                        strokeColor: layer.strokeColor,
                        fillColor: layer.fillColor,
                        opacity: value / 100,
                      });
                    }}
                    aria-label="Transparency"
                  />
                </Col>
              </Row>
            </div>
          ))}
        </Form>
      )}

      <Modal show={showDeleteModal} onHide={handleCancelDelete} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Delete Layer</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this layer?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" size="sm" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

CustomLayers.propTypes = {
  customLayers: PropTypes.array.isRequired,
  onToggleVisibility: PropTypes.func.isRequired,
  onDeleteLayer: PropTypes.func.isRequired,
  onUpdateStyle: PropTypes.func.isRequired,
  onUpdateLayerName: PropTypes.func.isRequired,
  onUpdateLayerRadius: PropTypes.func.isRequired,
  onToggleNoFill: PropTypes.func.isRequired,
};

export default CustomLayers;
