import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import {
  collection,
  addDoc,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firestore } from '../../../../firebase'; // Adjust path as necessary
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Toolbar,
  ColumnMenu,
  Resize,
  Reorder,
  Inject,
  Edit,
} from '@syncfusion/ej2-react-grids';
import AddExpenseModal from './AddExpenseModal';
import EditExpenseModal from './EditExpenseModal';
import DeleteExpenseModal from './DeleteExpenseModal';
import { AuthContext } from '../../../../context/auth/AuthContext';
import { hasEditAccess } from '../../ProjectUtils/permissions';
import '@syncfusion/ej2-react-grids/styles/material.css';
import { FaEdit, FaTrash } from 'react-icons/fa';

const ExpensesManager = ({ project }) => {
  const { currentUser } = useContext(AuthContext);
  const [expenses, setExpenses] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [phases, setPhases] = useState([]);
  const [showAddExpenseModal, setShowAddExpenseModal] = useState(false);
  const [showEditExpenseModal, setShowEditExpenseModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [userPermission, setUserPermission] = useState('Read');
  const [loading, setLoading] = useState(true);

  const storage = getStorage();

  // Fetch user permissions
  useEffect(() => {
    if (project && currentUser) {
      const userEntry = project.users.find(
        (user) => user.uid === currentUser.uid
      );
      const permission = userEntry ? userEntry.permission : 'Read';
      setUserPermission(permission);
    }
  }, [project, currentUser]);

  // Fetch expenses from Firestore
  useEffect(() => {
    if (project) {
      const unsubscribeExpenses = onSnapshot(
        collection(firestore, `projects/${project.id}/expenses`),
        (snapshot) => {
          const fetchedExpenses = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setExpenses(fetchedExpenses);
          setLoading(false);
        },
        (error) => {
          console.error('Error fetching expenses:', error);
          setLoading(false);
        }
      );

      // Fetch tasks and phases to use in linking
      const unsubscribeTasks = onSnapshot(
        collection(firestore, `projects/${project.id}/tasks`),
        (snapshot) => {
          const fetchedTasks = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTasks(
            fetchedTasks.filter((task) => task.taskType !== 'Phase')
          );
          setPhases(
            fetchedTasks.filter((task) => task.taskType === 'Phase')
          );
        }
      );

      return () => {
        unsubscribeExpenses();
        unsubscribeTasks();
      };
    }
  }, [project]);

  // Function to upload attachment to Firebase Storage
  const uploadAttachment = async (expenseId, file) => {
    if (!file) return null;
    const storageRef = ref(storage, `projects/${project.id}/expenses/${expenseId}/${file.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  // Add expense handler
  const handleAddExpense = async (newExpense, attachment) => {
    if (!hasEditAccess(userPermission)) {
      alert('You do not have permission to add expenses.');
      return;
    }

    try {
      // Add expense without attachment first
      const docRef = await addDoc(
        collection(firestore, `projects/${project.id}/expenses`),
        newExpense
      );

      // If there's an attachment, upload it and update the expense
      if (attachment) {
        const attachmentUrl = await uploadAttachment(docRef.id, attachment);
        await updateDoc(docRef, { attachmentUrl });
      }

      setShowAddExpenseModal(false);
    } catch (error) {
      console.error('Error adding expense:', error);
      alert('Failed to add expense.');
    }
  };

  // Edit expense handler
  const handleEditExpense = async (editedExpense, attachment) => {
    if (!hasEditAccess(userPermission)) {
      alert('You do not have permission to edit expenses.');
      return;
    }

    try {
      const expenseDocRef = doc(firestore, `projects/${project.id}/expenses`, editedExpense.id);

      // If a new attachment is uploaded, upload it and set the URL
      if (attachment) {
        const attachmentUrl = await uploadAttachment(editedExpense.id, attachment);
        await updateDoc(expenseDocRef, { ...editedExpense, attachmentUrl });
      } else {
        // If no new attachment, retain the existing URL
        await updateDoc(expenseDocRef, editedExpense);
      }

      setShowEditExpenseModal(false);
      setSelectedExpense(null);
    } catch (error) {
      console.error('Error editing expense:', error);
      alert('Failed to edit expense.');
    }
  };

  // Delete expense handler
  const handleDeleteExpense = async () => {
    if (!hasEditAccess(userPermission)) {
      alert('You do not have permission to delete expenses.');
      return;
    }

    try {
      await deleteDoc(
        doc(firestore, `projects/${project.id}/expenses`, selectedExpense.id)
      );
      setShowDeleteModal(false);
      setSelectedExpense(null);
    } catch (error) {
      console.error('Error deleting expense:', error);
      alert('Failed to delete expense.');
    }
  };

  const handleOpenEditExpenseModal = (expense) => {
    setSelectedExpense(expense);
    setShowEditExpenseModal(true);
  };

  const handleOpenDeleteModal = (expense) => {
    setSelectedExpense(expense);
    setShowDeleteModal(true);
  };

  // Define grid columns
  const gridColumns = useMemo(
    () => [
      {
        field: 'title',
        headerText: 'Title',
        width: '150',
        textAlign: 'Left',
      },
      {
        field: 'description',
        headerText: 'Description',
        width: '200',
        textAlign: 'Left',
      },
      {
        field: 'amount',
        headerText: 'Amount (AUD)',
        width: '100',
        textAlign: 'Right',
        format: 'C2',
        // Disable editing currency format in grid
      },
      {
        field: 'date',
        headerText: 'Date',
        width: '120',
        textAlign: 'Center',
        type: 'date',
        format: 'dd/MM/yyyy',
      },
      {
        field: 'status',
        headerText: 'Status',
        width: '100',
        textAlign: 'Center',
      },
      {
        field: 'accountCode',
        headerText: 'Account Code',
        width: '120',
        textAlign: 'Center',
      },
      {
        field: 'taxCode',
        headerText: 'Tax Code',
        width: '100',
        textAlign: 'Center',
      },
      {
        field: 'paymentMethod',
        headerText: 'Payment Method',
        width: '130',
        textAlign: 'Center',
      },
      {
        field: 'vendor',
        headerText: 'Vendor',
        width: '150',
        textAlign: 'Left',
      },
      {
        field: 'referenceNumber',
        headerText: 'Reference Number',
        width: '150',
        textAlign: 'Left',
      },
      {
        field: 'attachmentUrl',
        headerText: 'Attachment',
        width: '150',
        textAlign: 'Center',
        template: (data) =>
          data.attachmentUrl ? (
            <a href={data.attachmentUrl} target="_blank" rel="noopener noreferrer">
              View
            </a>
          ) : (
            'N/A'
          ),
      },
      {
        field: 'linkedTaskId',
        headerText: 'Linked Task',
        width: '150',
        textAlign: 'Left',
        template: (data) => {
          const task = tasks.find((t) => t.id === data.linkedTaskId);
          return task ? task.title : 'None';
        },
      },
      {
        field: 'linkedPhaseId',
        headerText: 'Linked Phase',
        width: '150',
        textAlign: 'Left',
        template: (data) => {
          const phase = phases.find((p) => p.id === data.linkedPhaseId);
          return phase ? phase.title : 'None';
        },
      },
      {
        headerText: 'Actions',
        width: '100',
        textAlign: 'Center',
        template: (data) => (
          <div className="d-flex justify-content-center">
            <FaEdit
              className="me-3 text-primary"
              style={{ cursor: 'pointer' }}
              onClick={() => handleOpenEditExpenseModal(data)}
              aria-label={`Edit ${data.title}`}
              title="Edit Expense"
            />
            <FaTrash
              className="text-danger"
              style={{ cursor: 'pointer' }}
              onClick={() => handleOpenDeleteModal(data)}
              aria-label={`Delete ${data.title}`}
              title="Delete Expense"
            />
          </div>
        ),
        allowFiltering: false,
        allowSorting: false,
      },
    ],
    [tasks, phases]
  );

  // Toolbar options
  const toolbarOptions = ['Search', 'ColumnChooser', 'Refresh'];

  // Page settings
  const pageSettings = { pageSize: 10 };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '500px' }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading expenses...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div>
      {/* Add Expense Button */}
      <div className="d-flex justify-content-between mb-3">
        <Button
          onClick={() => setShowAddExpenseModal(true)}
          disabled={!hasEditAccess(userPermission)}
        >
          Add Expense
        </Button>
      </div>

      {/* Syncfusion DataGrid Component */}
      <GridComponent
        dataSource={expenses}
        toolbar={toolbarOptions}
        editSettings={{
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: 'Normal',
        }}
        allowPaging={true}
        pageSettings={pageSettings}
        allowSorting={true}
        allowFiltering={true}
        allowResizing={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        allowExcelExport={true}
        allowPdfExport={false}
        allowTextWrap={true}
        allowSelection={true}
        height={600}
        columnMenu={true}
        showColumnChooser={true}
        allowColumnChooser={true}
        allowReordering={true}
      >
        <ColumnsDirective>
          {gridColumns.map((col, index) => (
            <ColumnDirective key={index} {...col} />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Sort,
            Toolbar,
            ColumnMenu,
            Resize,
            Reorder,
          ]}
        />
      </GridComponent>

      {/* Add Expense Modal */}
      <AddExpenseModal
        show={showAddExpenseModal}
        onHide={() => setShowAddExpenseModal(false)}
        onAddExpense={handleAddExpense}
        tasks={tasks}
        phases={phases}
      />

      {/* Edit Expense Modal */}
      <EditExpenseModal
        show={showEditExpenseModal}
        onHide={() => setShowEditExpenseModal(false)}
        onEditExpense={handleEditExpense}
        selectedExpense={selectedExpense}
        tasks={tasks}
        phases={phases}
      />

      {/* Delete Expense Modal */}
      <DeleteExpenseModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={handleDeleteExpense}
        selectedExpense={selectedExpense}
      />
    </div>
  );
};

export default ExpensesManager;
