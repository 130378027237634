import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const TermsAndConditions = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Welcome to LUNAR8!</h5>
        <p>
          These Terms and Conditions ("Terms") govern your use of LUNAR8’s platform, including but not limited to the project management and job creation tools. By signing up or using the platform, you agree to be bound by these Terms. Please read them carefully before using the platform.  
        </p>

        <h5>1. Introduction</h5>
        <p>
          Welcome to **LUNAR8**. These Terms govern your use of our website, tools, and services, including but not limited to the project management dashboard, task and epic management, job creation and quoting features, and collaborative project management tools. By signing up or using our services, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you should discontinue your use of the platform.
        </p>

        <h5>2. Definitions</h5>
        <p>
          "Platform" refers to the LUNAR8 website, services, and all associated features and functionalities.<br/>
          "User", "You", or "Your" refers to the individual or entity using our platform.<br/>
          "Service Providers" refers to users offering trade services or quotes.<br/>
          "Job Posters" refers to users posting jobs and seeking quotes from service providers.
        </p>

        <h5>3. Account Registration</h5>
        <p>
          You must register for an account to access certain functionalities. You agree to keep your login credentials secure and not share them with others. You are responsible for any activity under your account.
        </p>

        <h5>4. Use of the Platform</h5>
        <p>
          You are granted a limited, non-exclusive, non-transferable license to access and use the platform. You must not misuse the platform by knowingly introducing viruses or harmful code. You must not reverse-engineer or exploit the platform for unlawful purposes.
        </p>

        <h5>5. Project Management Features</h5>
        <p>
          Our Project Management Tools include a centralised project dashboard, task and epic management, collaborative tools, Gantt-chart based roadmaps, permission-based role management, and file handling. You are responsible for ensuring that any content you upload is lawful and appropriate.
        </p>

        <h5>6. Jobs Creation & Quoting Features</h5>
        <p>
          You may create jobs, manage quotes, and communicate with service providers through the platform. LUNAR8 is not responsible for contracts or disputes between Job Posters and Service Providers.
        </p>

        <h5>7. Subscription Plans</h5>
        <p>
          Certain functionalities may require a paid subscription. Subscription plans are billed on a recurring basis, and you may cancel at any time, but no refunds will be issued for prepaid fees unless otherwise specified.
        </p>

        <h5>8. Data Use and Privacy</h5>
        <p>
          We collect and store your data for internal purposes, such as improving the platform and tracking platform usage. We do not sell or share your personal data with third parties for marketing purposes without your consent. For more information, please review our Privacy Policy.
        </p>

        <h5>9. Intellectual Property</h5>
        <p>
          All content, trademarks, and intellectual property related to the LUNAR8 platform belong to LUNAR8. You may not modify, copy, or use any content for commercial purposes without prior written consent.
        </p>

        <h5>10. Limitation of Liability</h5>
        <p>
          LUNAR8 provides the platform "as is" without warranties of any kind. We are not responsible for any disputes or damages arising from agreements made through the platform. We are not liable for any indirect, incidental, or consequential damages.
        </p>

        <h5>11. Termination</h5>
        <p>
          LUNAR8 reserves the right to terminate or suspend your account at any time, without notice, for conduct that violates these Terms or is harmful to the platform or other users.
        </p>

        <h5>12. Modifications to the Terms</h5>
        <p>
          We may update these Terms from time to time. You will be notified of significant changes, and continued use of the platform after such changes signifies your acceptance of the updated Terms.
        </p>

        <h5>13. Governing Law</h5>
        <p>
          These Terms are governed by the laws of **[Your Country]**. Any disputes will be subject to the exclusive jurisdiction of the courts located in **[Your Jurisdiction]**.
        </p>

        <h5>14. Contact Information</h5>
        <p>
          If you have any questions about these Terms, please contact us at support@lunar8.com.au.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsAndConditions;
