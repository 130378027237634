// src/components/BillingManagement/SubscriptionList.js

import React, { useState, useContext, useEffect } from 'react';
import { Card, Button, Alert, Container, Row, Col, Badge } from 'react-bootstrap';
import axios from 'axios';
import './SubscriptionStyles.css';
import { AuthContext } from '../../context/auth/AuthContext';

const SubscriptionList = ({ subscriptions: initialSubscriptions, currentUser }) => {
  const [subs, setSubs] = useState(initialSubscriptions || []);
  console.log('subs', subs)
  const [alert, setAlert] = useState({ message: '', type: '', show: false });
  const { fetchSubscriptions } = useContext(AuthContext);

  const API_URL = `${process.env.REACT_APP_API_URL}`;

  useEffect(() => {
    setSubs(initialSubscriptions || []);
  }, [initialSubscriptions]);

  const handleCancelSubscription = async () => {
    try {
      console.log('Cancelling subscription for:', currentUser.email);
      const response = await axios.post(`${API_URL}/cancel-subscription`, { email: currentUser.email });
      console.log('Cancel subscription response:', response.data);
      setAlert({ message: response.data.message, type: 'warning', show: true });
      await fetchSubscriptions(currentUser.email);
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setAlert({ message: 'Failed to cancel subscription.', type: 'danger', show: true });
    }
  };

  const handleUncancelSubscription = async () => {
    try {
      console.log('Uncancelling subscription for:', currentUser.email);
      const response = await axios.post(`${API_URL}/uncancel-subscription`, { email: currentUser.email });
      console.log('Uncancel subscription response:', response.data);
      setAlert({ message: response.data.message, type: 'success', show: true });
      await fetchSubscriptions(currentUser.email);
    } catch (error) {
      console.error('Error uncancelling subscription:', error);
      setAlert({ message: 'Failed to uncancel subscription.', type: 'danger', show: true });
    }
  };

  const renderStatusBadge = (sub) => {
    if (sub.cancel_at_period_end) {
      return <Badge bg="warning">Scheduled for Cancellation</Badge>;
    }
    if (sub.status === 'canceled') {
      return <Badge bg="secondary">Canceled</Badge>;
    }
    return <Badge bg="success">Active</Badge>;
  };

  const renderSubscriptionDetails = (sub) => {
    if (sub.cancel_at_period_end) {
      return (
        <>
          <li>
            <strong>Subscription End Date:</strong>{' '}
            {new Date(sub.current_period_end * 1000).toLocaleDateString()}
          </li>
          <li>
            <strong>Auto Renewal:</strong> No
          </li>
        </>
      );
    }
    return (
      <>
        <li>
          <strong>Next Payment Date:</strong>{' '}
          {sub.next_payment_date
            ? new Date(sub.next_payment_date * 1000).toLocaleDateString()
            : 'N/A'}
        </li>
        <li>
          <strong>Status:</strong> Active
        </li>
        <li>
          <strong>Auto Renewal:</strong> Yes
        </li>
      </>
    );
  };

  return (
    <Container className="subscription-list-container">
      <h2 className="mb-4">Your Subscriptions</h2>
      {alert.show && (
        <Alert
          variant={alert.type}
          onClose={() => setAlert({ ...alert, show: false })}
          dismissible
        >
          {alert.message}
        </Alert>
      )}
      <Row>
        {subs.length === 0 ? (
          <Col>
            <Alert variant="info">You do not have any active subscriptions.</Alert>
          </Col>
        ) : (
          subs.map((sub, index) => (
            <Col md="6" key={index} className="mb-4">
              <Card className="subscription-card">
                <Card.Body>
                  <Card.Title>
                    {sub.product} {renderStatusBadge(sub)}
                  </Card.Title>
                  <hr />
                  <ul className="subscription-details">{renderSubscriptionDetails(sub)}</ul>
                  {sub.cancel_at_period_end ? (
                    <Button
                      variant="success"
                      className="subscription-button"
                      onClick={handleUncancelSubscription}
                    >
                      Resume Subscription
                    </Button>
                  ) : (
                    <Button
                      variant="outline-danger"
                      className="subscription-button"
                      onClick={handleCancelSubscription}
                    >
                      Cancel Subscription
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>
    </Container>
  );
};

export default SubscriptionList;
