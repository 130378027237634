// src/context/auth/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';
import { auth } from '../../firebase';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const API_URL = `${process.env.REACT_APP_API_URL}`;

  const fetchSubscriptions = async (email) => {
    try {
      const response = await axios.get(`${API_URL}/get-subscription-status`, {
        params: { email },
      });
      setSubscriptions(
        Array.isArray(response.data.subscription_status)
          ? response.data.subscription_status
          : []
      );
    } catch (error) {
      console.error('Subscription Status Error:', error);
      setSubscriptions([]);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (isMounted) {
        setCurrentUser(user);
        if (user) {
          await fetchSubscriptions(user.email);
        } else {
          setSubscriptions([]);
        }
        setLoading(false);
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [API_URL]);

  // Log current user and subscriptions whenever they change
  useEffect(() => {
    console.log('Current User:', currentUser);
    console.log('Current Subscriptions:', subscriptions);
  }, [currentUser, subscriptions]);

  const logout = () => {
    signOut(auth)
      .then(() => {
        setCurrentUser(null);
        setSubscriptions([]);
      })
      .catch((error) => {
        console.error('Logout Error:', error);
      });
  };

  // Helper function to check if a subscription is active or scheduled for cancellation
  const isActiveOrScheduled = (sub) => sub.status === 'active' || sub.cancel_at_period_end;

  // Determine if the user has any active or scheduled subscription
  const hasActiveOrScheduledSubscription = subscriptions.some(isActiveOrScheduled);

  // Determine if the user has no active subscription (excluding scheduled for cancellation)
  const hasNoActiveSubscription = !hasActiveOrScheduledSubscription;

  // Determine subscription categories
  const subscribedPlans = subscriptions.filter(isActiveOrScheduled);
  const canceledPlans = subscriptions.filter(sub => sub.status === 'canceled');

  // Determine if the user has an active subscription
  const isSubscribed = hasActiveOrScheduledSubscription;

  // Determine if the user is scheduled for cancellation
  const isScheduledForCancellation = subscriptions.some(sub => sub.cancel_at_period_end);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        subscriptions,
        fetchSubscriptions,
        setCurrentUser,
        logout,
        isSubscribed,
        isScheduledForCancellation,
        hasNoActiveSubscription,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
