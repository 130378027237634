import React from 'react';
import { Card } from 'react-bootstrap';
import '../ManageCompany.css';

const CompanyOverview = ({ selectedCompany }) => {
  if (!selectedCompany) return <p>No company selected.</p>;

  return (
    <div>
      <h3>Overview of {selectedCompany.companyName}</h3>
      <Card>
        <Card.Img className='service-logo' variant="top" src={selectedCompany.companyLogoUrl} />
        <Card.Body>
          <Card.Title>{selectedCompany.companyName}</Card.Title>
          <Card.Text>
            <strong>ABN:</strong> {selectedCompany.companyABN}
          </Card.Text>
          <Card.Text>
            <strong>Industry:</strong> {selectedCompany.companyTradeType}
          </Card.Text>
          <Card.Text>
            <strong>Location:</strong> {selectedCompany.companyAddress}
          </Card.Text>
          <Card.Text>
            <strong>Number of Employees:</strong> {selectedCompany.employees.length}
          </Card.Text>
          <Card.Text>
            <strong>Website:</strong> <a href={selectedCompany.websiteLink} target="_blank" rel="noopener noreferrer">{selectedCompany.websiteLink}</a>
          </Card.Text>
          <Card.Text>
            <strong>Bio:</strong> {selectedCompany.companyBio}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CompanyOverview;
