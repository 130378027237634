// src/components/ProjectDashboard.js

import React, { useState } from 'react';
import ProjectsManager from './ProjectsManager';
import FilesManager from './components/FileManager/FilesManager';
import Roadmap from './components/ProjectRoadmap/Roadmap';
import ProjectSettings from './components/ProjectSettings/ProjectSettings';
import ProjectBacklog from './components/ProjectBacklog/ProjectBacklog';
import ExpensesManager from './components/ExpensesManager/ExpensesManager'; // Import ExpensesManager
import Dashboard from './components/ProjectDashboard/Dashboard'; // Import the Dashboard component
import SelectOrCreateProjectPrompt from './components/SelectOrCreateProjectPrompt'; // Import the new prompt component
import {
  ButtonGroup,
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {
  FaBars,
  FaMinus,
  FaRoad,
  FaClipboardList,
  FaCogs,
  FaArrowLeft,
  FaTachometerAlt, // Dashboard icon
  FaMoneyBillWave, // Expenses icon
} from 'react-icons/fa';
import './ProjectDashboard.css';

// Import permission utilities
import {
  hasAdminAccess,
  hasEditAccess,
  hasReadAccess,
} from './ProjectUtils/permissions';

const ProjectDashboard = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const addProject = (project) => {
    setProjects([...projects, project]);
  };

  const selectProject = (project) => {
    setSelectedProject(project);
    setSelectedComponent('dashboard'); // Set the default component to dashboard
  };

  const deleteProject = () => {
    const updatedProjects = projects.filter((project) => project !== selectedProject);
    setProjects(updatedProjects);
    setSelectedProject(null);
    setSelectedComponent(null);
  };

  const backToProjectsList = () => {
    setSelectedProject(null);
    setSelectedComponent(null);
    setMenuCollapsed(false);
  };

  const renderSelectedComponent = () => {
    if (!selectedProject) {
      return <SelectOrCreateProjectPrompt />; // Render prompt when no project is selected
    }

    // Determine user permissions
    const userPermission = selectedProject.userPermission;

    switch (selectedComponent) {
      case 'dashboard':
        return <Dashboard project={selectedProject} />;
      case 'files':
        // Restrict based on permissions
        if (hasReadAccess(userPermission) || hasEditAccess(userPermission) || hasAdminAccess(userPermission)) {
          return <FilesManager project={selectedProject} />;
        }
        return <div>Access Denied</div>;
      case 'roadmap':
        // Restrict adding/editing/deleting based on permissions
        if (hasReadAccess(userPermission) || hasEditAccess(userPermission) || hasAdminAccess(userPermission)) {
          return <Roadmap project={selectedProject} />;
        }
        return <div>Access Denied</div>;
      case 'backlog':
        // Restrict adding/editing/deleting based on permissions
        if (hasReadAccess(userPermission) || hasEditAccess(userPermission) || hasAdminAccess(userPermission)) {
          return <ProjectBacklog project={selectedProject} />;
        }
        return <div>Access Denied</div>;
      case 'expenses':
        // Restrict based on permissions
        if (
          hasReadAccess(userPermission) ||
          hasEditAccess(userPermission) ||
          hasAdminAccess(userPermission)
        ) {
          return <ExpensesManager project={selectedProject} />;
        }
        return <div>Access Denied</div>;
      case 'projectSettings':
        // Only Admin can access Project Settings
        if (hasAdminAccess(userPermission)) {
          return <ProjectSettings projectId={selectedProject?.id} />;
        }
        return <div>Access Denied</div>;
      default:
        return null;
    }
  };

  // Toggle menu visibility
  const toggleMenu = () => {
    if (selectedProject) {
      setMenuCollapsed(!menuCollapsed);
    }
  };

  return (
    <Row className="project-dashboard-container" aria-label="Project Dashboard">
      {/* Menu Column with dynamic class based on collapse state */}
      <Col
        xs={12}
        md={2} // Ensuring it takes 2 columns on medium screens and larger
        className={`menu-column ${menuCollapsed ? 'collapsed' : ''}`}
      >
        {!selectedProject ? (
          <ProjectsManager
            onAddProject={addProject}
            onSelectProject={selectProject}
            projects={projects}
          />
        ) : (
          <>
            <div
              className="menu-toggle"
              onClick={toggleMenu}
              aria-label="Toggle Menu"
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') toggleMenu();
              }}
            >
              {menuCollapsed ? <FaBars /> : <FaMinus />}
            </div>
            <div className={`menu-content ${menuCollapsed ? 'd-none' : 'd-block'}`}>
              <ButtonGroup vertical className="w-100" aria-label="Project Navigation">
                <Button
                  onClick={backToProjectsList}
                  className="btn btn-secondary mb-2"
                  aria-label="Back to Projects"
                >
                  <FaArrowLeft className="me-2" /> Back to Projects
                </Button>
                <Button
                  onClick={() => setSelectedComponent('dashboard')}
                  aria-pressed={selectedComponent === 'dashboard'}
                  aria-label="Dashboard"
                  className="mb-2"
                >
                  <FaTachometerAlt className="me-2" /> Dashboard
                </Button>
                <Button
                  onClick={() => setSelectedComponent('roadmap')}
                  aria-pressed={selectedComponent === 'roadmap'}
                  aria-label="View Project Roadmap"
                  className="mb-2"
                >
                  <FaRoad className="me-2" /> Project Roadmap
                </Button>
                <Button
                  onClick={() => setSelectedComponent('backlog')}
                  aria-pressed={selectedComponent === 'backlog'}
                  aria-label="View Project Backlog"
                  className="mb-2"
                >
                  <FaClipboardList className="me-2" /> Project Backlog
                </Button>
                {/* <Button
                  onClick={() => setSelectedComponent('expenses')}
                  aria-pressed={selectedComponent === 'expenses'}
                  aria-label="View Project Expenses"
                  className="mb-2"
                >
                  <FaMoneyBillWave className="me-2" /> Project Expenses
                </Button> */}
                {/* Conditionally render Project Settings based on permission */}
                {hasAdminAccess(selectedProject.userPermission) && (
                  <Button
                    onClick={() => setSelectedComponent('projectSettings')}
                    aria-pressed={selectedComponent === 'projectSettings'}
                    aria-label="View Project Settings"
                    className="mb-2"
                  >
                    <FaCogs className="me-2" /> Project Settings
                  </Button>
                )}
              </ButtonGroup>
            </div>
          </>
        )}
      </Col>

      {/* Component Column with dynamic width based on menu collapse state */}
      <Col
        xs={12}
        md={10} // Ensure this takes the remaining 10 columns
        className="component-column"
      >
        {renderSelectedComponent()}
      </Col>
    </Row>
  );
};

export default ProjectDashboard;
