import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Form, ListGroup, Button, Card } from 'react-bootstrap';
import { getDocs, collection, addDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth/AuthContext';
import CreateJobBanner from '../../components/CTAContent/CreateJobBanner'; 
import '../../App.css';
import './FindServices.css';

const FindServices = () => {
  const [servicesData, setServicesData] = useState([]);
  const [tradeFilter, setTradeFilter] = useState('All Trades');
  const [searchQuery, setSearchQuery] = useState('');
  const [trades, setTrades] = useState([]);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'companies'));
        const companies = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setServicesData(companies);

        const uniqueTrades = [...new Set(companies.map(company => company.companyTradeType))];
        setTrades(uniqueTrades);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };
    fetchServices();
  }, []);

  const handleReferralEvent = async (companyId, actionType) => {
    if (!currentUser) return;
    const { email, displayName } = currentUser;
    const [firstName, lastName] = displayName ? displayName.split(' ') : [null, null];

    const referralEvent = {
      timestamp: new Date(),
      userEmail: email,
      firstName,
      lastName,
      companyId,
      actionType,
    };

    try {
      await addDoc(collection(firestore, 'companies', companyId, 'referralEvents'), referralEvent);
      console.log('Referral event saved successfully');
    } catch (error) {
      console.error('Error saving referral event:', error);
    }
  };

  const handleEmailCopy = (email, companyId) => {
    navigator.clipboard.writeText(email).then(() => {
      handleReferralEvent(companyId, 'emailCopy');
    });
  };

  const filteredServices = servicesData.filter(
    (service) =>
      (tradeFilter === 'All Trades' || service.companyTradeType === tradeFilter) &&
      (searchQuery === '' ||
        service.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.companyTradeType.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.companyBio.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <Container className="find-services-container mb-5">
      <CreateJobBanner />
      <Row className="mt-4">
        <Col md={6}>
          <Form>
            <Form.Group controlId="filter">
              <Form.Label>Filter by Trade:</Form.Label>
              <Form.Control
                as="select"
                value={tradeFilter}
                onChange={(e) => setTradeFilter(e.target.value)}
              >
                <option>All Trades</option>
                {trades.map(trade => (
                  <option key={trade} value={trade}>{trade}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
        <Col md={6}>
          <Form>
            <Form.Group controlId="search">
              <Form.Label>Search:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type to search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <ListGroup>
            {filteredServices.map((service) => (
              <ListGroup.Item key={service.id} className="mb-3 service-item">
                <Row>
                  <Col md={2} className="d-flex align-items-center justify-content-center">
                    <img
                      src={service.companyLogoUrl}
                      alt={`Logo for ${service.companyName}`}
                      className="img-fluid list-service-logo"
                    />
                  </Col>
                  <Col md={8}>
                    <Card className="service-card">
                      <Card.Body>
                        <Card.Title>{service.companyName}</Card.Title>
                        <Card.Text>
                          Trade: {service.companyTradeType} <br />
                          Email: 
                          <span 
                            style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                            onClick={() => handleEmailCopy(service.contactDetails?.email, service.id)}
                          >
                            {service.contactDetails?.email}
                          </span>
                          <br />
                          Website: 
                          <a 
                            href={service.websiteLink} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            onClick={() => handleReferralEvent(service.id, 'websiteClick')}
                          >
                            {service.websiteLink}
                          </a>
                          <div className="bio-text">
                            Bio: {service.companyBio}
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={2} className="d-flex align-items-center justify-content-center">
                    <Link to={`/company/${service.id}`}>
                      <Button variant="primary">View Company</Button>
                    </Link>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default FindServices;
