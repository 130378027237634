import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Alert,
  Modal,
  Spinner,
  InputGroup,
  ProgressBar,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { logEvent } from '../analytics';
import './SignupLogin.css';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions'; // Import the component

const T_AND_C_VERSION = '1.0';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTnCModal, setShowTnCModal] = useState(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const navigate = useNavigate();

  const mapFirebaseErrors = (errorCode) => {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return 'This email address is already in use by another account.';
      case 'auth/invalid-email':
        return 'The email address is not valid.';
      case 'auth/operation-not-allowed':
        return 'Email/password accounts are not enabled. Enable email/password accounts in the Firebase Console.';
      case 'auth/weak-password':
        return 'The password is too weak. Please use a stronger password.';
      case 'auth/user-disabled':
        return 'This user has been disabled. Please contact support.';
      case 'auth/too-many-requests':
        return 'Too many attempts. Please try again later.';
      default:
        return 'An unexpected error occurred. Please try again.';
    }
  };

  const calculatePasswordStrength = (pass) => {
    let strength = 0;
    if (pass.length >= 6) strength += 1;
    if (pass.length >= 8) strength += 1;
    if (/[A-Z]/.test(pass)) strength += 1;
    if (/[a-z]/.test(pass)) strength += 1;
    if (/[0-9]/.test(pass)) strength += 1;
    if (/[\W]/.test(pass)) strength += 1;
    setPasswordStrength((strength / 6) * 100);
  };

  const signUpWithEmail = async () => {
    if (!termsAccepted) {
      setError('You must accept the terms and conditions.');
      logEvent('SignUp', 'Error', 'Terms not accepted');
      return;
    }
    if (email && password && firstName && lastName) {
      // Client-side validations
      if (password.length < 6) {
        setError('Password must be at least 6 characters long.');
        return;
      }
      const emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(email)) {
        setError('Please enter a valid email address.');
        return;
      }

      setLoading(true);
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        await sendEmailVerification(user);
        await setDoc(doc(firestore, 'users', user.uid), {
          email,
          firstName,
          lastName,
          role: 'user', // Changed from 'site_admin' to 'user'
          termsAcceptedVersion: T_AND_C_VERSION,
          emailVerified: false,
        });

        setShowEmailVerificationModal(true);
        logEvent('SignUp', 'Success', 'Email SignUp');
      } catch (error) {
        const friendlyError = mapFirebaseErrors(error.code);
        setError(friendlyError);
        logEvent('SignUp', 'Error', friendlyError);
      } finally {
        setLoading(false);
      }
    } else {
      setError('Please fill all the fields.');
      logEvent('SignUp', 'Error', 'Incomplete fields');
    }
  };

  const googleSignIn = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userRef = doc(firestore, 'users', user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        await setDoc(userRef, {
          email: user.email,
          firstName: user.displayName || '',
          lastName: '',
          role: 'user', // Changed from 'site_admin' to 'user'
          termsAcceptedVersion: T_AND_C_VERSION,
          emailVerified: true,
        });
      }

      navigate('/projects-dashboard');
      logEvent('SignUp', 'Success', 'Google SignUp');
    } catch (error) {
      setError('Google Sign-In failed: ' + error.message);
      logEvent('SignUp', 'Error', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} sm={10} md={8} lg={6}>
          <h2 className="text-center mb-4">Sign Up</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="firstName">First Name</Form.Label>
              <Form.Control
                id="firstName"
                type="text"
                placeholder="First Name"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="lastName">Last Name</Form.Label>
              <Form.Control
                id="lastName"
                type="text"
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="email">Email address</Form.Label>
              <Form.Control
                id="email"
                type="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="password">Password</Form.Label>
              <InputGroup>
                <Form.Control
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    calculatePasswordStrength(e.target.value);
                  }}
                />
                <Button
                  className="small-btn"
                  variant="outline-secondary"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </Button>
              </InputGroup>
              <ProgressBar
                className="mt-2"
                now={passwordStrength}
                variant={
                  passwordStrength < 50
                    ? 'danger'
                    : passwordStrength < 75
                    ? 'warning'
                    : 'success'
                }
                label={
                  passwordStrength < 50
                    ? 'Weak'
                    : passwordStrength < 75
                    ? 'Medium'
                    : 'Strong'
                }
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label={
                  <span>
                    I agree to the{' '}
                    <a href="#" onClick={() => setShowTnCModal(true)}>
                      terms and conditions
                    </a>{' '}
                    required to use my details for business operations.
                  </span>
                }
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
            </Form.Group>

            <Button
              variant="primary"
              onClick={signUpWithEmail}
              className="w-100 mb-2"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : 'Sign Up'}
            </Button>
            <Button
              variant="danger"
              onClick={googleSignIn}
              className="w-100 mb-2"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : 'Sign in with Google'}
            </Button>
            <div className="text-center mt-3">
              Already have an account? <Link to="/login">Login here</Link>
            </div>
          </Form>
        </Col>
      </Row>

      {/* Include the Terms and Conditions Modal */}
      <TermsAndConditions show={showTnCModal} onHide={() => setShowTnCModal(false)} />

      {/* Email Verification Modal */}
      <Modal
        show={showEmailVerificationModal}
        onHide={() => {
          setShowEmailVerificationModal(false);
          navigate('/login');
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Almost There! Email Verification Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            A verification email has been sent to your email address {email}. Please verify your
            email before logging in.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowEmailVerificationModal(false);
              navigate('/login');
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SignUp;
