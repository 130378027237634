// src/components/BillingManagement/BillingManagement.js

import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/auth/AuthContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import PaymentDisplay from './PaymentDisplay';
import SuccessDisplay from './SuccessDisplay';
import SubscriptionList from './SubscriptionList';

const stripePromise = loadStripe('pk_live_51PJuZZF1Sd4Db5LgfnHFnQ4pok3sfFtnxtnxNFUDDQDEspeqJUOqRcgaXV9gLV2XtKu82fJuNkF5yIoPpgypCsG800E4Xm5oEQ');

const BillingManagement = () => {
  const { currentUser, subscriptions, fetchSubscriptions } = useContext(AuthContext);
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [plans, setPlans] = useState([]);
  const [loadingPlans, setLoadingPlans] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const fetchPlans = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/get-subscription-plans`;
        console.log('Fetching subscription plans from:', apiUrl);
        const response = await axios.get(apiUrl);
        console.log('Subscription plans response', response);
        if (isMounted) setPlans(response.data);
      } catch (error) {
        console.error('Error fetching plans:', error);
      } finally {
        if (isMounted) setLoadingPlans(false);
      }
    };

    fetchPlans();

    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
      // Refresh subscriptions after successful checkout
      if (currentUser) {
        fetchSubscriptions(currentUser.email);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [currentUser, fetchSubscriptions]);

  return (
    <Elements stripe={stripePromise}>
      {!success ? (
        subscriptions.length === 0 && !loadingPlans ? (
          <PaymentDisplay plans={plans} />
        ) : (
          <SubscriptionList subscriptions={subscriptions} currentUser={currentUser} />
        )
      ) : (
        <SuccessDisplay sessionId={sessionId} />
      )}
    </Elements>
  );
};

export default BillingManagement;
