import React, { useContext, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth/AuthContext';
import { logPageView, logEvent } from '../../analytics';
import Banner from '../../components/CTAContent/CreateJobBanner';
import CookieConsent from '../../components/CookieConsent/CookieConsent';
import './LandingPage.css';

const LandingPage = () => {
  const { currentUser } = useContext(AuthContext);
  const isAuthenticated = !!currentUser;

  useEffect(() => {
    logPageView();
  }, []);

  const handleGetStartedClick = () => {
    logEvent('Button', 'Click', 'Get Started Today');
  };

  return (
    <div className="text-center">
      <CookieConsent />
      <div className="banner-container">
        <img src={`${process.env.PUBLIC_URL}/assets/banner.png`} alt="Banner" className="banner-image height-300" />
        <div className="overlay-full"></div>
        <Container fluid="md" className="pt-4 banner-content">
          <Col>
            <h1>
              Simplify Property Development with <span style={{ color: '#FF3A50' }}>LUNAR8</span>
            </h1>
            <p>
              Plan, track, and manage your property projects with an all-in-one project management tool built by developers, for developers. From site acquisition to completion, we help you get there!
            </p>
            {!isAuthenticated && (
              <Button variant="primary" as={Link} to="/signup" className='get-started-btn' onClick={handleGetStartedClick}>
                Join Today!
              </Button>
            )}
          </Col>
        </Container>
      </div>

      {/* Key Features Section */}
      <Container className="feature-section py-5">
        <Row className="justify-content-center">
          <Col md={4}>
            <Card className="card-custom hover-shadow">
              <Card.Body>
                <Card.Title className='primary-color'><h4>Comprehensive Project Dashboard</h4></Card.Title>
                <Card.Text>
                  Manage roadmaps, backlogs, and project files seamlessly in one centralised dashboard.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="card-custom hover-shadow">
              <Card.Body>
                <Card.Title className='primary-color'><h4>Stage & Task Management</h4></Card.Title>
                <Card.Text>
                  Use visual Gantt charts to manage tasks, timelines, and dependencies with ease.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="card-custom hover-shadow">
              <Card.Body>
                <Card.Title className='primary-color'><h4>Template-Driven Setup</h4></Card.Title>
                <Card.Text>
                  Kickstart your projects with pre-configured templates, ensuring faster delivery.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Additional Feature Row */}
        <Row className="justify-content-center mt-4">
          <Col md={6}>
            <Card className="card-custom hover-shadow">
              <Card.Body>
                <Card.Title className='primary-color'><h4>Collaborative Management</h4></Card.Title>
                <Card.Text>
                  Invite team members, set roles, and work together efficiently.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="card-custom hover-shadow">
              <Card.Body>
                <Card.Title className='primary-color'><h4>Customisable Reporting</h4></Card.Title>
                <Card.Text>
                  Customise task views, prioritise workflows, and allocate resources smartly.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Secondary Offering Section */}
      <Container className="how-it-works-section py-5">
        <Row className="text-center">
          <Col>
            <h2 className='primary-color'>Manage Your Entire Development</h2>
            <p>
              From initial planning to project completion, LUNAR8’s all-in-one solution ensures streamlined project execution.
            </p>
          </Col>
        </Row>
        <Row className="text-left mt-4">
          <Col md={4}>
            <h4>Completion Tracking</h4>
            <p>Manage tasks, timelines, monitor progress, leverage live dashboarding and ensure all project phases remain on track.</p>
          </Col>
          <Col md={4}>
            <h4>Job Creation</h4>
            <p>Post and manage detailed jobs with trade categories, timelines, and budgets.</p>
          </Col>
          <Col md={4}>
            <h4>Budget Management</h4>
            <p>Stay ontop of your project budgets with advanced budget management tooling.</p>
          </Col>
        </Row>
      </Container>

      {/* Final CTA Section */}
      <Row className="mt-5 mb-5">
        <Banner />
      </Row>

      {/* Contact Section */}
      <Row className="mt-4">
        <h2 className='primary-color'>Get In Touch</h2>
        <Col>
          <iframe 
            src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1oId4x0q_TaDy86GQlLiwbCCnLLn6VnhK6Z-VBi-DfeNgYqIVoTJwoPhSw9l-wJbS3PdonLEtR?gv=true" 
            style={{ border: 0, width: '100%', height: '650px' }} 
            frameBorder="0"
            title="Google Calendar Appointment Scheduling"
            allowFullScreen
          ></iframe>
        </Col>
      </Row>
    </div>
  );
};

export default LandingPage;
