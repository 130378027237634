import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import { firestore } from '../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

const FeedbackForm = ({ show, handleClose, page }) => {
  const [feedbackType, setFeedbackType] = useState('');
  const [feedbackText, setFeedbackText] = useState('');
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const currentUser = getAuth().currentUser;
  const storage = getStorage(); // Ensure this line is present

  const handleSubmit = async () => {
    setUploading(true);
    setError(null);
    try {
      let imageUrl = '';
      if (image) {
        const imageRef = ref(storage, `feedback_images/${image.name}_${Date.now()}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      await addDoc(collection(firestore, 'feedback'), {
        user: currentUser?.uid || 'Anonymous',
        type: feedbackType,
        feedback: feedbackText,
        page: page,
        imageUrl,
        timestamp: serverTimestamp(),
      });

      setSubmitted(true);
      setFeedbackType('');
      setFeedbackText('');
      setImage(null);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setError('There was an issue submitting your feedback. Please try again later.');
    } finally {
      setUploading(false);
    }
  };

  if (submitted) {
    return (
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your feedback has been submitted.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Submit Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group controlId="feedbackType">
            <Form.Label>Type</Form.Label>
            <Form.Control
              as="select"
              value={feedbackType}
              onChange={(e) => setFeedbackType(e.target.value)}
            >
              <option value="" disabled>Select type...</option>
              <option value="feedback">Feedback</option>
              <option value="bug">Bug</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="feedbackText" className="mt-3">
            <Form.Label>Feedback</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
            />
          </Form.Group>

          {feedbackType === 'bug' && (
            <Form.Group controlId="bugImage" className="mt-3">
              <Form.Label>Upload Screenshot</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={uploading || !feedbackType || !feedbackText}
        >
          {uploading ? 'Submitting...' : 'Submit'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackForm;
